import AbnormalCompat from "./AbnormalCompat";
import {demoDataBuffer} from "../demo/data";
import getDate from "../RealTimeMonitor/modules/Date";

/**
 * @name: BPMParse
 * @author: Seeker
 * @date: 2022-01-10 14:46
 * @description：BPMParse
 * @update: 2022-01-10 14:46
 */

const demo = function (pointCount){
    let avgBeat = {};
    const temp = demoDataBuffer[this.props.data.mqtt_channel];
    if (temp && temp.length) {
        for (let i = 0; i < pointCount; i++) {
            const ti = temp[i];
            if (ti) {
                if (ti.type) {
                    avgBeat = {
                        type: ti.typeDes
                    };
                    break;
                }
            }
        }
    }
    const extraObj = {
        offline: 0,
        bleState: 1,
        mobileBattery: 100,
        bleBattery: 100
    };
    const beat = {hr: 70, edr: 25, ...avgBeat};
    let abnormalEvent = null;
    if (AbnormalCompat.is_v0(beat,this.props.hrRanger)){
        abnormalEvent = {...beat,date: getDate(1),time: new Date().getTime()}
    }
    const data = {
        extraObj,
        avgBeat: beat,
    };
    if (abnormalEvent){
        return {
            ...data,
            abnormalEvent
        };
    }
    return data;
};

const v0 = function (curBpm,stateAvgBeat,propsHrRanger){
    if (!curBpm){
        return null;
    }
    let avgBeat = null;
    if (curBpm.hr !== -2){
        avgBeat = curBpm;
        if (curBpm.hr === -1 && stateAvgBeat){
            avgBeat.hr = stateAvgBeat.hr;
        }
    }
    let abnormalEvent = null;
    if (AbnormalCompat.is_v0(avgBeat,propsHrRanger)){
        abnormalEvent = {...avgBeat}
    }
    return abnormalEvent?{avgBeat,abnormalEvent}:{avgBeat}
};

const v1 = function (curBpm,occurEvent,stateAvgBeat,stateAbnormalEvent,propsHrRanger,propsAlarmEvent,stateShowTimeLong){
    let avgBeat = stateAvgBeat;
    if (curBpm){
        if (curBpm.hr !== -2){
            avgBeat = curBpm;
            if (curBpm.hr === -1 && stateAvgBeat){
                avgBeat.hr = stateAvgBeat.hr;
            }
        }else {
            avgBeat = null;
        }
    }
    const abnormalEvent = AbnormalCompat.is_v1(occurEvent,avgBeat,propsHrRanger,propsAlarmEvent);
    if (abnormalEvent){
        // 再有其它非心动过缓和心动过速的异常事件时，直接返回当前事件
        if (!stateAbnormalEvent){
            return {avgBeat,abnormalEvent};
        }
        if (AbnormalCompat.isBpmAbnormal(abnormalEvent)){
            if (AbnormalCompat.isBpmAbnormal(stateAbnormalEvent) || (new Date().getTime() - stateAbnormalEvent.time > 5 * 1000)){
                return {avgBeat,abnormalEvent};
            }else {
                return {avgBeat};
            }
        }else {
            return {avgBeat,abnormalEvent};
        }
    }
    if (stateAbnormalEvent){
        const duration = new Date().getTime() - stateAbnormalEvent.time;
        if (duration > stateShowTimeLong * 1000
            || (AbnormalCompat.isNoise(stateAbnormalEvent) && duration >= 3*1000)
            || AbnormalCompat.isUselessExcludeNoise(stateAbnormalEvent)){
            return {avgBeat,abnormalEvent: undefined};
        }
    }
    return {avgBeat};
};

const v2 = function (curBpm,occurEvent,stateAvgBeat,stateAbnormalEvent,propsHrRanger,propsAlarmEvent,stateShowTimeLong){
    if (occurEvent && AbnormalCompat.isUseless(occurEvent)){
        return {avgBeat: null,abnormalEvent:{...occurEvent}};
    }else {
        return this.v1(curBpm,occurEvent,stateAvgBeat,stateAbnormalEvent,propsHrRanger,propsAlarmEvent,stateShowTimeLong);
    }
};

const BPMParseCompat = {
    demo,v0,v1,v2
};

export default BPMParseCompat;
