import {
    FETCH_USERINFO_STARTED,
    FETCH_USERINFO_SUCCESS,
    FETCH_USERINFO_FAILURE,
    SET_SHOW_MODULE,
    SET_HOME_STYLE
} from "./actionTypes";
import axios from '../myAxios';

export const fetchUserInfoStarted = () =>({
    type:FETCH_USERINFO_STARTED
});

export const fetchUserInfoSuccess = (result)=>({
    type:FETCH_USERINFO_SUCCESS,
    payload:{
        result
    }
});

export const fetchUserInfoFailure = (err) =>({
    type:FETCH_USERINFO_FAILURE,
    payload:{
        error:err
    }
});

export const fetchUserInfo = ()=>{
    return(dispatch)=>{
        dispatch(fetchUserInfoStarted());
        return axios.post('/basedata/userInfo.action').then((res)=>{
            const {actionErrors=[], data={}} = res.data;
            if (actionErrors.length) {
                dispatch(fetchUserInfoFailure());
            } else {
                const userInfo = {...data.userInfo, homepage: data.homepage,customSysName:data.customSysNameShorter,mngDept:data.mngDept};
                dispatch(fetchUserInfoSuccess(userInfo));
            }
        }).catch((err) => {
            dispatch(fetchUserInfoFailure(err));
        });
    }
}
export const setShowModule = (checked,venueSetting) =>({
    type:SET_SHOW_MODULE,
    payload:{
        checked,
        venueSetting
    }
})

export const setHomeStyle = (homeStyleCode,venueSetting) =>({
    type:SET_HOME_STYLE,
    payload:{
        homeStyleCode,
        venueSetting
    }
})

