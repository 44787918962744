/**
 * @name: Channel
 * @author: Seeker
 * @date: 2021-12-03 10:18
 * @description：Channel
 * @update: 2021-12-03 10:18
 */

const Channel = {
    deptName: (dept_name) => dept_name,
    // deptName: (dept_name) => dept_name.endsWith('总部')?dept_name.substr(0,dept_name.length-2):dept_name,
    bedNumber:(bed_number) => bed_number.endsWith('床')?bed_number.replace('床',''):`${bed_number}`,
    bedNumber2:(bed_number) => bed_number.endsWith('床')?bed_number:`${bed_number}床`,
    unifySymbols:(bed_number) => bed_number.replace('—','-').replace('＋','+').replace('－','-'),
    badExtraMsg:(extra) => {
        if (extra){
            const {finish,offline,bleState} = extra;
            return finish === 0 || offline === 1 || offline === undefined || bleState === 0 || bleState === undefined;
        }
        return true;
    }
};

export default Channel
