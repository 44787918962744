/**
 * @name: dom
 * @author: Seeker
 * @date: 2021-12-21 09:06
 * @description：dom
 * @update: 2021-12-21 09:06
 */

const computeFontSize = (str,fontSize,padding)=>{
    const spanDom = document.getElementById('text_ruler');
    spanDom.style.fontSize = `${fontSize}px`;
    spanDom.style.textAlign = 'center';
    spanDom.style.fontWeight = 'bolder';
    if (padding){
        spanDom.style.padding = padding;
    }
    spanDom.innerText = str;
    return {
        width: spanDom.offsetWidth,
        height: spanDom.offsetHeight
    };
};
export {computeFontSize}