/**
 * @name: publish
 * @author: Seeker
 * @date: 2021-12-21 16:32
 * @description：publish
 * @update: 2021-12-21 16:32
 */
import moment from "moment";

const version_name = 'CR_V1.0.16';

const build_date = `更新日期：2024/9/9 15:00`;

const product_name = '【产品名称】心电遥测中央监护软件';

const product_mode = '【规格型号】Cardio Remote';

const version_beta = `【软件发布版本】CR_V1`;

const product_company = '【注册人/生产企业名称】山东正心医疗科技有限公司';

const product_abode = '【住所】中国（山东）自由贸易试验区烟台片区烟台开发区深圳大街5号1号楼';

const product_address = '【生产地址】山东省烟台经济技术开发区深圳大街5号1#2楼';

const product_deadline = '【使用期限】10年';

const product_birthday = '【生产日期】2024年1月10日'

const contact = '【联系方式】0535-6936259';

const product_license = '【生产许可证编号】鲁药监械生产许20180036号';

const registration_certificate = '【注册证编号】鲁械注准20222211290';

const version = `${version_beta}    完整版本：${version_name}`;

const pub_date = '';

const copyright = 'Copyright © 山东正心医疗科技有限公司  All Rights Reserved.';

const icp = '【鲁ICP备18008075号-1】';

const cr_build = `发布版本：CR-V${moment(new Date()).format('YYYYMMDD')}`

const other_info = ' (其他内容详见说明书)';

const publish = {
    contact,
    version_beta,
    version,
    version_name,
    pub_date,
    build_date,
    copyright,
    product_mode,
    icp,
    cr_build,
    product_name,
    product_company,
    product_abode,
    product_address,
    product_deadline,
    product_license,
    registration_certificate,
    product_birthday,
    other_info
};

export default publish
