/**
 * @name: ChannelItemBgSvg
 * @author: Seeker
 * @date: 2023-12-18 11:21
 * @description：ChannelItemBgSvg
 * @update: 2023-12-18 11:21
 */

import React from 'react';

function ChannelItemBgSvg(props) {
    let {width, height, titleHeight, circleFillColor, borderLineColor, bgColor} = props;
    if (!circleFillColor){
        circleFillColor = '#EFECFF'
    }
    if (!borderLineColor){
        borderLineColor = '#575E8A'
    }
    if (!bgColor){
        bgColor = '#152A57'
    }
    titleHeight -= 6;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} style={{position:'absolute'}}>
            <polygon
                points={'11,1 15,5 ' + (width - 15) + ',5 ' + (width - 11) + ',1 '
                + (width - 1) + ',11 ' + (width - 5) + ',15 ' + (width - 5) + ',' + (height - 15) + ' ' + (width - 1) + ',' + (height - 11)
                + ' ' + (width - 11) + ',' + (height - 1) + ' ' + (width - 15) + ',' + (height - 5) + ' 15,' + (height - 5) + ' 11,' + (height - 1)
                + ' 1,' + (height - 11) + ' 5,' + (height - 15) + ' 5,15 1,11'}
                style={{fill: bgColor}}/>

            <circle cx={5} cy={5} r={5} style={{fill: circleFillColor}}/>
            <circle cx={width - 5} cy={5} r={5} style={{fill: circleFillColor}}/>
            <circle cx={width - 5} cy={height - 5} r={5} style={{fill: circleFillColor}}/>
            <circle cx={5} cy={height - 5} r={5} style={{fill: circleFillColor}}/>

            <polyline points={'11,1 15,5 ' + (width - 15) + ',5 ' + (width - 11) + ',1'} style={{
                stroke: borderLineColor,
                strokeWidth: 1,
                fill: 'none'
            }}/>

            <polyline
                points={'11,' + (13 + titleHeight) + ' 15,' + (8 + titleHeight) + ' ' + (width - 15) + ',' + (8 + titleHeight) + ' ' + (width - 11) + ',' + (13 + titleHeight)}
                style={{
                    stroke: borderLineColor,
                    strokeWidth: 1,
                    fill: 'none'
                }}/>

            <polyline
                points={'11,' + (height - 1) + ' 15,' + (height - 5) + ' ' + (width - 15) + ',' + (height - 5) + ' ' + (width - 11) + ',' + (height - 1)}
                style={{
                    stroke: borderLineColor,
                    strokeWidth: 1,
                    fill: 'none'
                }}/>

            <polyline points={'1,11 5,15 5,' + (height - 15) + ' 1,' + (height - 11)} style={{
                stroke: borderLineColor,
                strokeWidth: 1,
                fill: 'none'
            }}/>

            <polyline
                points={(width - 1) + ',11 ' + (width - 5) + ',15 ' + (width - 5) + ',' + (height - 15) + ' ' + (width - 1) + ',' + (height - 11)}
                style={{
                    stroke: borderLineColor,
                    strokeWidth: 1,
                    fill: 'none'
                }}/>
        </svg>
    )
}

export default ChannelItemBgSvg;
