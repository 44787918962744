/**
 * @name: SVGChart
 * @author: Seeker
 * @date: 2022-05-23 15:27
 * @description：SVGChart
 * @update: 2022-05-23 15:27
 */

const defaultConfig = {
    yGridCount: 6,
    gridBorderLineW:1,
    gridLineW: 0.5,
    borderColor:'#9B9B9B',
    gridLineColor: '#D2D2D2',
    ecgLineW: 1,
    ecgLineColor: '#333333',
    pointRadius:0.3,
    pointColor:'#D2D2D2'
}

const calculateScreenY = (dataHeight, yValue, ymv) => {
    const offset = (yValue + ymv) * (dataHeight / (ymv * 2))
    return dataHeight - offset
}

export default class SVGChart{

    static init = (svgElement) =>{
        return new SVGChart(svgElement)
    }

    constructor(svgElement) {
        this.svgElement = svgElement;
        this.renderConfig = {...defaultConfig};
        this.plotData = []
        this.plotAnnotation=[]
        this.basicSideValue = 1 // 1: 以宽为准，2：以高为准
        this.chartRect = this._chartRect()
        this.xGridCount = 0
        this.gridWidth = 0
        this.rowCount = 1
        this.singleHeight = 0
    }

    basicSide = (side) => {
        if (side === 1 || side === 2){
            this.basicSideValue = side
        }else {
            throw new Error(`side = 1 || side = 2,no ${side}`)
        }
        return this
    }

    /**
     * @param  options
     // * @param {number} options.pointCountPerRow 每行新手的最大点数
     // * @param {number} options.rowSpace 行间距【number】
     * @see defaultConfig
     * @returns {SVGChart}
     */
    config = (options) => {
        this.renderConfig = {...defaultConfig,...options}
        return this
    }

    /**
     * @param points
     * @param annotation 心搏信息，[{index:0,type:1},{index:0,type:1},...]
     * @returns {SVGChart}
     */
    data = (points,annotation) => {
        this.plotData.length = 0
        this.plotAnnotation.length = 0
        this.plotData.push(...points)
        if (annotation !== undefined){
            this.plotAnnotation.push(...annotation)
        }
        return this
    }

    render = () => {
        this._valid()
        this._confirmSize()
        const {pointCountPerRow,rowSpace} = this.renderConfig
        console.log(`dataLen = ${this.plotData.length},rowCount = ${this.rowCount}`)
        for (let i = 0;i < this.rowCount;i++){
            const yOffset = (this.singleHeight+rowSpace) * i
            this._backGrid(yOffset)
            this._backGridBorder(yOffset)
            // this._backPoint(yOffset)
            this._ecgLine(yOffset,this.plotData.slice(i*pointCountPerRow,(i+1)*pointCountPerRow))
        }
        this._annotation(this.plotAnnotation)
    }

    _chartRect = () => {
        const {left,top,right,bottom,width,height} = this.svgElement.getBoundingClientRect();
        return {left,top,right,bottom,width,height}
    }

    _valid = () =>{
        let {pointCountPerRow,rowSpace} = this.renderConfig
        if (pointCountPerRow === undefined){
            this.renderConfig.pointCountPerRow = this.plotData.length
        }
        if (rowSpace === undefined){
            this.renderConfig.rowSpace = 0
        }
    }

    _confirmSize = () =>{
        let {gridLineW,yGridCount,pointCountPerRow,rowSpace} = this.renderConfig
        this.rowCount = Math.ceil(this.plotData.length/pointCountPerRow)
        this.xGridCount = Math.ceil(pointCountPerRow / 50)
        if (this.basicSideValue === 1){
            this.gridWidth = Math.floor((this.chartRect.width - gridLineW * 2) / this.xGridCount * 100) / 100
            this.singleHeight = Math.ceil(yGridCount*this.gridWidth+gridLineW * 2);
            this.chartRect.height = this.singleHeight * this.rowCount + (this.rowCount-1)*rowSpace
        }else if (this.basicSideValue === 2){
            this.gridWidth = Math.floor((this.chartRect.height - gridLineW * 2) / yGridCount * 100) / 100
            this.chartRect.width = Math.ceil(this.xGridCount * this.gridWidth + gridLineW * 2)
            this.singleHeight = (this.chartRect.height-(this.rowCount-1)*rowSpace)/this.rowCount
        }
        this.svgElement.style.width = `${this.chartRect.width}px`
        this.svgElement.style.height = `${this.chartRect.height}px`
    }

    _backGridBorder = (yOffset) => {
        const borderPath = document.createElementNS('http://www.w3.org/2000/svg','path');
        const {yGridCount,gridBorderLineW,borderColor} = this.renderConfig
        const {width} = this.chartRect
        let dStr = ''

        const y1 = gridBorderLineW + yOffset
        dStr += `M${gridBorderLineW} ${y1} L${width - gridBorderLineW} ${y1}`

        const y2 =  gridBorderLineW + yGridCount * this.gridWidth + yOffset
        dStr += `M${gridBorderLineW} ${y2} L${width - gridBorderLineW} ${y2}`

        const x1 = gridBorderLineW
        dStr += `M${x1} ${gridBorderLineW+yOffset} L${x1} ${yOffset+this.singleHeight - gridBorderLineW}`

        const x2 = gridBorderLineW + this.xGridCount * this.gridWidth
        dStr += `M${x2} ${gridBorderLineW+yOffset} L${x2} ${yOffset+this.singleHeight - gridBorderLineW}`

        borderPath.setAttribute("d",dStr);
        borderPath.setAttribute("style",`stroke-width:${gridBorderLineW};stroke:${borderColor}`);
        this.svgElement.appendChild(borderPath)
    }

    _backGrid = (yOffset) => {
        const backPath = document.createElementNS('http://www.w3.org/2000/svg','path');
        const {yGridCount,gridLineW,gridLineColor} = this.renderConfig
        const {width} = this.chartRect
        let dStr = ''
        // 以下是横线
        for (let i = 1;i < yGridCount;i++){
            const y = gridLineW + i * this.gridWidth + yOffset
            dStr += `M${gridLineW} ${y} L${width - gridLineW} ${y}`
        }

        // 以下是竖线
        for (let i = 1;i < this.xGridCount;i++){
            const x = gridLineW + i * this.gridWidth
            dStr += `M${x} ${gridLineW+yOffset} L${x} ${yOffset+this.singleHeight - gridLineW}`
        }
        backPath.setAttribute("d",dStr);
        backPath.setAttribute("style",`stroke-width:${gridLineW};stroke:${gridLineColor}`);
        this.svgElement.appendChild(backPath)
    }

    _backPoint = () =>{
        const pointPath = document.createElementNS('http://www.w3.org/2000/svg','path');
        let dStr = ''
        const cellWidth = this.gridWidth / 5
        const {yGridCount,pointRadius,gridLineW} = this.renderConfig
        for (let i = 0;i< this.xGridCount*5;i++){
            if (i % 5 !== 0){
                const x = cellWidth * i + gridLineW;
                for (let j = 0;j < yGridCount*5;j++){
                    if (j % 5 !== 0){
                        const y = cellWidth * j +gridLineW;
                        dStr += `M${x-pointRadius},${y} a${pointRadius},${pointRadius} 0 1,0 ${pointRadius*2},0 a${pointRadius},${pointRadius} 0 1,0 ${-pointRadius*2},0`
                    }
                }
            }
        }
        pointPath.setAttribute("d",dStr);
        pointPath.setAttribute("style",`fill:${this.renderConfig.pointColor}`);
        this.svgElement.appendChild(pointPath)
    }

    _ecgLine = (yOffset,data) =>{
        const ecgPath = document.createElementNS('http://www.w3.org/2000/svg','path');
        const {ecgLineW,ecgLineColor,gridLineW,yGridCount} = this.renderConfig
        const {width} = this.chartRect
        const ymv = yGridCount / 2 / 2
        let dStr = ''
        data.forEach(((value, i) => {
            const x = i / this.renderConfig.pointCountPerRow * (width-gridLineW*2)+gridLineW
            const y = calculateScreenY(this.singleHeight-gridLineW*2,value,ymv)+gridLineW + yOffset
            if (i === 0){
                dStr += `M${x} ${y}`
            }else {
                dStr += `L${x} ${y}`
            }
        }))
        ecgPath.setAttribute("d",dStr);
        ecgPath.setAttribute("style",`stroke-width:${ecgLineW};stroke:${ecgLineColor};fill:none`);
        this.svgElement.appendChild(ecgPath)
    }

    _annotation = (data) =>{
        const {pointCountPerRow,rowSpace,gridLineW} = this.renderConfig
        const {width} = this.chartRect
        data.forEach(value => {
            const {index,type} = value
            const svgTextElement = document.createElementNS('http://www.w3.org/2000/svg','text');
            const rowIndex = Math.floor(index/pointCountPerRow)
            const y = (this.singleHeight+rowSpace)*rowIndex + 20
            const x = (index-rowIndex*pointCountPerRow) / this.renderConfig.pointCountPerRow * (width-gridLineW*2)+gridLineW
            let middleX = 5
            if (type.length === 2){
                middleX = 8
            }else if (type.length === 4){
                middleX = 15
            }
            svgTextElement.setAttribute("x",x - middleX)
            svgTextElement.setAttribute("y",y)
            svgTextElement.setAttribute("style",`font-size:14px;`);
            svgTextElement.textContent = type
            this.svgElement.appendChild(svgTextElement)
        })
    }

}
