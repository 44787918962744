/**
 * @name: ThreadWorker
 * @author: Seeker
 * @date: 2022-03-21 13:02
 * @description：ThreadWorker
 * @update: 2022-03-21 13:02
 */

const ThreadWorker = (fun, options) => {
    if (!fun)
        throw new Error('fun not be null!');
    if (Object.prototype.toString.call(fun) !== '[object Function]') throw new Error('fun mu')
    const url = window.URL.createObjectURL(new Blob([`(${fun.toString()})()`], {type: "text/javascript"}));
    return new Worker(url, options || {})
};

export default ThreadWorker
