import React, {Component} from 'react';
import axios from '../myAxios';
import qs from 'qs';
import { Form, Input, Button, message,Card} from 'antd';
import Modal from '../common/Modal';
import {UnlockOutlined} from "@ant-design/icons";

const FormItem = Form.Item;

class ResetPwdForm extends Component {
    constructor() {
        super(...arguments);
        // this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        // this.checkPassword = this.checkPassword.bind(this);
        // this.checkConfirm = this.checkConfirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            confirmDirty: false,
            loading: false
        };
    }

    handleSubmit(values) {
        this.setState({loading: true});
        axios.post('/basedata/modifyPasswordSubmit.action', qs.stringify({
            'struts.enableJSONValidation': true,
            oldPassword: values.oldPassword,
            password: values.password
        })).then((response) => {
            if (response.data.actionErrors.length) {
                message.error(response.data.actionErrors[0]);
            } else {
                message.success(response.data.actionMessages[0] || '操作成功');
                this.setState({loading: true});
                this.props.onOk();
            }
        }).catch((err) => {
            console.log(err);
            this.setState({loading: true});
            message.error('出现错误');
        });
    }

    // handleConfirmBlur(e) {
    //     const value = e.target.value;
    //     this.setState({
    //         confirmDirty: this.state.confirmDirty || !!value
    //     });
    // }
    //
    // checkPassword(rule, value, callback) {
    //     const form = this.props.form;
    //     if (value && value !== form.getFieldValue('password')) {
    //         callback('两次输入的密码不一致');
    //     } else {
    //         callback();
    //     }
    // }

    // checkConfirm(rule, value, callback) {
    //     const form = this.props.form;
    //     if (value && this.state.confirmDirty) {
    //         form.validateFields(['confirm'], {force: true});
    //     }
    //     callback();
    // }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 12,
                    offset: 6,
                },
            },
        };
        return (
            <Card title='重置密码'>
                <Form onFinish={this.handleSubmit}>
                    <FormItem
                        {...formItemLayout}
                        label='原密码'
                        name={'oldPassword'}
                        rules={[{required: true, message: '请输入旧密码'}]}
                    >
                        <Input type='password' />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label='新密码'
                        name={'password'}
                        rules={[{required: true, message: '请输入新密码'}]}
                    >
                        <Input type='password' />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label='确认密码'
                        name={'confirm'}
                        dependencies={['password']}
                        rules={[{
                            required: true, message: '请确认输入的密码'
                        },
                            ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不一致'));
                            },
                        }),]}
                        hasFeedback
                    >
                        <Input type='password'/>
                    </FormItem>
                    <FormItem {...tailFormItemLayout}>
                        <Button loading={this.state.loading} style={{width: '50%',marginRight:20}} type='primary' htmlType='submit'>提交</Button>
                        <Button loading={this.state.loading} style={{width: '50%',marginLeft:20}} onClick={this.props.onCancel}>取消</Button>
                    </FormItem>

                </Form>
            </Card>
        );
    }
}

class ResetPwd extends Component {
    constructor() {
        super(...arguments);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showModal = this.showModal.bind(this);
        this.state = {
            visible: false
        }
    }
    showModal() {
        this.setState({
            visible: true
        });
    }

    handleOk() {
        this.setState({
            visible: false
        });
    }
    handleCancel() {
        this.setState({
            visible: false
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.visible !== this.state.visible;
    }
    render() {
        return (
            <div>
                <div onClick={this.showModal}>
                    <UnlockOutlined style={{marginRight: 8}}/>重置密码
                </div>
                <Modal
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    closable={false}
                    destroyOnClose={true}
                >
                    <ResetPwdForm onOk={this.handleOk} onCancel={this.handleCancel}/>
                </Modal>
            </div>
        )
    }
}

export default ResetPwd;
