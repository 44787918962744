import {createStore, applyMiddleware, compose} from 'redux';

import thunkMiddleware from 'redux-thunk'

// import {reducer as loginReducer} from './login';
// import {reducer as adminReducer} from './app';
import {reducer} from './app/index';



// const reducer = combineReducers({
//     login: loginReducer,
//     admin: adminReducer
// });

const middlewares = [thunkMiddleware];

const storeEnhancers = compose(
    applyMiddleware(...middlewares),
    (f) => f,
);

export default createStore(reducer, {}, storeEnhancers);

