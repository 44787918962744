import React from "react";
import {computeFontSize} from "../../../util/dom";
import Text from "antd/lib/typography/Text";
import ThemeColor from "../ThemeColor";
import {Divider} from "antd";
import Channel from "../../../Common/Channel";
import {CloseCircleOutlined} from "@ant-design/icons";
import HomeStyle from "../../../../HomeStyle";

/**
 * @name: bpmContainer
 * @author: Seeker
 * @date: 2021-08-30 16:42
 * @description：bpmContainer
 * @update: 2021-08-30 16:42
 */

const textStyle = {
    textAlign: 'center',
    fontWeight: 'bolder'
};

const BPM_LIMIT = {
    min:40,
    max:160
};

const widthRecord = {};

const getBpmContainerWidth = (screenWidth, isLarger, hasEdr, bpmTextFontSize,bedShowLeft,homeStyleCode) => {
    // const ratio = bedShowLeft? 0.5:0.382;
    // if (hasEdr) {
    //     return computeFontSize("心率：", bpmTextFontSize * ratio).width
    //         + computeFontSize("9", bpmTextFontSize).width * 3
    //         + 10;
    // }
    // return Math.max(computeFontSize("心率", bpmTextFontSize * ratio).width,
    //     computeFontSize("999", bpmTextFontSize).width);

    const key = `bpmContainerWidth-${bpmTextFontSize}-${hasEdr}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    let w;
    if (hasEdr) {
        if (homeStyleCode === 4){
            w = computeFontSize("9", bpmTextFontSize).width * 3 + 50;
        }else {
            w = computeFontSize("心率：", bpmTextFontSize * 0.382).width
                + computeFontSize("9", bpmTextFontSize).width * 3
                + 10;
        }
    }else {
        w = computeFontSize("999", bpmTextFontSize).width;
    }
    widthRecord[key] = w;
    return w;
};

const getBedContainerWidth = (bedTextFontSize,bedNumLength) => {
    const key = `bedContainerWidth-${bedTextFontSize}-${bedNumLength}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    const w = computeFontSize("9", bedTextFontSize).width * (bedNumLength-1)
        +computeFontSize("+", bedTextFontSize).width;
    widthRecord[key] = w;
    return w;
};

const getChannelCardHeadHeight = (screenWidth, nameAndBedLarger) => {

    let domTitle = document.getElementById('card_title_container');
    if (domTitle){
        return domTitle.clientHeight;
    }

    const key = `channelCardHeadHeight-${screenWidth}-${nameAndBedLarger}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    let w;
    if (nameAndBedLarger) {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            w = 40;
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            w = 45;
        } else {
            w = 55;
        }
    } else {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            w = 35;
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            w = 40;
        } else {
            w = 45;
        }
    }
    widthRecord[key] = w;
    return w;
};

const getChannelFooterHeight = (screenWidth) => {
    if (screenWidth >= 1 && screenWidth <= 1250) {
        return 50;
    } else if (screenWidth > 1250 && screenWidth <= 1700) {
        return 55;
    } else {
        return 10;
    }
};


const getChannelCardStyle = (darkModule, homeStyleCode,cardHeight,venueSetting) => {
    return {
        ...HomeStyle.channelBorder(homeStyleCode,venueSetting),
        background: HomeStyle.channelBackgroundColor(homeStyleCode,venueSetting),
        height: cardHeight
    };
};

const getChannelCardTitleContainerStyle = (screenWidth, nameAndBedLarger, rowCount,venueSetting) => {
    let paddingStyle;
    if (nameAndBedLarger) {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            paddingStyle = {
                padding: '2px 2px 2px 0px'
            };
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            paddingStyle = {
                padding: '3px 3px 3px 0px'
            };
        } else {
            paddingStyle = {
                padding: '4px 4px 4px 0px'
            };
        }
    } else {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            paddingStyle = rowCount === 5 ? {padding: '5px 5px 5px 0px'} : {padding: '3px 3px 3px 0px'};
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            paddingStyle = rowCount === 5 ? {padding: '8px 8px 8px 0px'} : {padding: '4px 4px 4px 0px'};
        } else {
            paddingStyle = rowCount === 5 ? {padding: '10px 10px 10px 0px'} : {padding: '5px 5px 5px 0px'};
        }
    }
    if (venueSetting && venueSetting.venueOpenState){
        return {
            ...paddingStyle,
            borderBottom: '1px solid rgba(74, 85, 102, 0.3)'
        };
    }
    return {
        ...paddingStyle,
        borderBottom: '1px solid rgba(74, 85, 102, 0.6)'
    };
};

const getChannelCardTitle = (contentObj, darkModule, homeStyleCode,item, abnInfo,
                             abnInfoBackColor,flicker, colCount, screenWidth,
                             nameAndBedLarger, onNameClick, nameTextFontSize,
                             departmentShow, bedShowLeft, bedTextFontSize,batteryShowRule,clearEmergencyAlarmEvent,venueSetting) => {
    const linkError = contentObj.finish || !contentObj.mqttConnect || !contentObj.bleConnect;
    const textStyle = HomeStyle.channelHeadTextStyle(homeStyleCode,venueSetting);

    const hospitalNum = contentObj.hospitalNum.value ? <span className={contentObj.hospitalNum.className}
                                                             style={{...textStyle}}>({contentObj.hospitalNum.value})</span> : null;

    const name = <div onClick={onNameClick} className={`${contentObj.name.className} channel-patienter-name`} style={{
        ...textStyle,
        fontSize: `${nameTextFontSize}px`,
        paddingLeft: 5,
    }}>{contentObj.name.value}</div>;

    const bed = !bedShowLeft ? <div className={`${contentObj.bed.className}`} style={{
        ...textStyle,
        fontSize: `${bedTextFontSize}px`
    }}>{Channel.bedNumber2(contentObj.bed.value)}</div> : null;

    const dept = departmentShow ?
        <div className={contentObj.dept.className} style={{...textStyle,}}> {contentObj.dept.value}</div> : <></>;

    // const sn = <span className={contentObj.machSN.className} style={textStyle}>({contentObj.machSN.value})</span>;

    const connectError = <Text className={contentObj.connect.className} style={textStyle}> <img
        src={contentObj.connect.src} title={contentObj.connect.value} alt={contentObj.connect.value}
        className='channel-head-img-size' align="middle"/> {contentObj.connect.value}</Text>;

    // const abn = <Text className={['channel-abnormal-size', flicker ? 'flicker' : ''].join(' ')}
    //                   style={{color: '#FFFFFF', backgroundColor: '#C04040'}}>{abnInfo}</Text>;

    const abn = abnInfo?<Text className={['channel-abnormal-size',flicker ? 'flicker' : ''].join(' ')}
                              onClick={clearEmergencyAlarmEvent}
                              style={{color: '#FFFFFF', backgroundColor: abnInfoBackColor,cursor:'default'}}>
                                        {abnInfo}  {clearEmergencyAlarmEvent?<CloseCircleOutlined/>:null}
                        </Text>:<></>;

    const mobileBattery = <Text className={contentObj.mobileBattery.className}
                                style={{color: contentObj.mobileBattery.textColor}}><img
        src={contentObj.mobileBattery.src} title='手机电量' alt='手机电量' className='channel-head-img-size'
        align="middle"/>{contentObj.mobileBattery.value}</Text>;

    const bleBattery = <Text className={contentObj.bleBattery.className}
                             style={{color: contentObj.bleBattery.textColor}}><img src={contentObj.bleBattery.src}
                                                                                   title='设备电量' alt='设备电量'
                                                                                   className='channel-head-img-size'
                                                                                   align="middle"/>{contentObj.bleBattery.value}</Text>;

    let batteryShow = true;
    if (batteryShowRule === 0){
        batteryShow = true;
    }else if (batteryShowRule === 1){
        batteryShow = false;
    }else if (batteryShowRule === 2){
        if (abnInfo || linkError){
            batteryShow = false;
        }
    }

    return <div style={{display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap'}}>
        <div className={'content-center'}>
            {name}
            {hospitalNum}
            {
                departmentShow ? <>
                    <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: '50%'}}/>
                    {dept}</> : null
            }

            {
                !bedShowLeft ? <>
                    <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: '50%'}}/>
                    {bed}</> : null
            }

            {/*{colCount === 2 && departmentShow? dept : <></>}*/}
            {/*{colCount === 2 && item && item.mach_sn ? sn : <></>}*/}
        </div>
        <div className={'content-center'}>
            {linkError ? connectError : abn}
            {batteryShow?
                <>
                    {mobileBattery}
                    {bleBattery}
                </> : <></>
            }
        </div>
    </div>;
};

const getHr = (avgBeat) => {
    return avgBeat !== undefined && avgBeat && avgBeat.hr > 0 ? avgBeat.hr : '--'
};

const getEdr = (avgBeat) => {
    return avgBeat !== undefined && avgBeat && parseInt(avgBeat.edr) > 0? avgBeat.edr : '--'
};

const getChannelBodyBpm = (darkModule, homeStyleCode,isLarge, hasEdr, avgBeat, height, bpmTextFontSize, contentObj, bedTextFontSize, bpmWidth, bedWidth, bedShowLeft,hrRanger,venueSetting) => {
    const hrColor = HomeStyle.getBpmColor(homeStyleCode,avgBeat,hrRanger,venueSetting);
    const edrColor = HomeStyle.getEdrColor(homeStyleCode,venueSetting);
    if (hasEdr) {
        return (<div style={{
            height: height,
            width: bedShowLeft ? bpmWidth + bedWidth : bpmWidth,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            paddingLeft: HomeStyle.getChannelBodyBpmPaddingLeft(homeStyleCode)
        }}>
            {
                bedShowLeft ? <>
                    <div style={{height: height, width: bedWidth}}>
                        <div className={'content-center'} style={{
                            height: '50%',
                            color: 'white', ...textStyle,
                            fontSize: `${bedTextFontSize * 0.382}px`
                        }}>床号
                        </div>
                        <div className={'content-center'} style={{
                            height: '50%',
                            color: 'white', ...textStyle,
                            fontSize: `${bedTextFontSize}px`,
                        }}>{contentObj.bed.value}</div>
                    </div>
                    <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: height * 0.8}}/>
                </> : null
            }
            <div style={{height: height, width: bpmWidth, padding: '0 5px'}}>
                <div style={{display: 'flex', height: '50%', whiteSpace: 'nowrap'}}>
                    <span className={'channel-bpm-edr'}
                          style={{color: hrColor, fontSize: `${bpmTextFontSize * 0.382}px`}}>心率：</span>
                    <div className={'channel-bpm-edr'} style={{color: hrColor, fontSize: `${bpmTextFontSize}px`}}>
                        {getHr(avgBeat)}</div>
                </div>

                <div style={{display: 'flex', height: '50%', whiteSpace: 'nowrap'}}>
                <span className={'channel-bpm-edr'}
                      style={{color: edrColor, fontSize: `${bpmTextFontSize * 0.382}px`}}>呼吸：
                </span>
                    <span className={'channel-bpm-edr'}
                          style={{color: edrColor, fontSize: `${bpmTextFontSize}px`}}>
                    {getEdr(avgBeat)}</span>
                </div>

            </div>

        </div>);
    }
    return (<div style={{
        height: height,
        width: bedShowLeft ? bpmWidth + bedWidth : bpmWidth,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        paddingLeft: HomeStyle.getChannelBodyBpmPaddingLeft(homeStyleCode)
    }}>
        {
            bedShowLeft ? <>
                <div style={{height: height, width: bedWidth}}>
                    <div className={'content-center'} style={{
                        height: '40%',
                        color: 'white', ...textStyle,
                        fontSize: `${bedTextFontSize * 0.382}px`,
                    }}>床号
                    </div>
                    <div className={'content-center'} style={{
                        height: '60%',
                        color: 'white', ...textStyle,
                        fontSize: `${bedTextFontSize}px`,
                    }}>{contentObj.bed.value}</div>
                </div>

                <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: height * 0.8,marginLeft:0,marginRight:0}}/>
            </> : null
        }

        <div style={{height: height, width: bpmWidth}}>
            <div className={'content-center'}
                 style={{height: '40%', color: hrColor, ...textStyle, fontSize: `${bpmTextFontSize * 0.382}px`}}>心率
            </div>
            <div className={'content-center'} style={{
                height: '60%',
                color: hrColor, ...textStyle,
                fontSize: `${bpmTextFontSize}px`,
            }}>{getHr(avgBeat)}</div>
        </div>

    </div>);
};

export default {
    getBpmContainerWidth,
    getChannelCardTitle,
    getChannelCardStyle,
    getChannelCardHeadHeight,
    getChannelBodyBpm,
    getChannelFooterHeight,
    getChannelCardTitleContainerStyle,
    getBedContainerWidth,
    BPM_LIMIT
};
