import React, {Component} from 'react';
import {Col, Pagination, Row, Drawer, Typography} from 'antd';
import UserCtrl from "../../../../app/UserCtrl";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import BasicSettingWrapper from "./MobileSettings3";
// import getDate from "../Date";
// import DateSvg from "../DateIcon";
import '../size.css'
// import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import MobileMediaScreen from "./MobileMediaScreen";
import ThemeColor from "../ThemeColor";
import publish from "../../../../publish";
import isBeta from "../../../util/is-beta"

class Tools extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            modalVisible: false,
            fullScreenState: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            modalVisible: true
        })
    }

    hideModal() {
        this.setState({
            modalVisible: false
        })
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyListener);
        document.addEventListener("webkitfullscreenchange", this.fullscreenchange);
        this.props.bindRef(this);
        // document.getElementById('fullScreenBtn').click();
        // this.fullScreen();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyListener);
        document.removeEventListener("webkitfullscreenchange", this.fullscreenchange);
        this.props.unBindRef(this);
    }

    refresh = (pointCount) => {
        // this.showDate();
    };

    fullscreenchange = () => {
        if (document.webkitIsFullScreen) {
            //全屏后要执行的代码
            if (!this.state.fullScreenState) {
                this.setState({fullScreenState: true})
            }
        } else {
            //退出全屏后执行的代码
            if (this.state.fullScreenState) {
                this.setState({fullScreenState: false})
            }
        }
    };

    keyListener = (e) => {
        if (e.key === 'F11') {
            e.stopPropagation();
            e.preventDefault();
            this.fullScreen();
        }
    };

    // https://blog.csdn.net/dream19921216/article/details/86596408
    fullScreen = () => {
        if (!this.state.fullScreenState) {
            MobileMediaScreen.enter();
        } else {
            MobileMediaScreen.exit();
        }
    };

    // showDate = () => {
    //     const oDiv = document.getElementById("date");
    //     oDiv.innerHTML = getDate(-1);
    // };

    render() {
        const text_color = {color: ThemeColor.textColor(this.props.darkModule)};
        // let name = isBeta()?"正心心电遥测平台":"心电遥测中央监护软件";
        let name = "心电遥测中央监护软件";
        return (
            <div className={'header-size'}
                 style={{
                     background: ThemeColor.backgroundColor(this.props.darkModule),
                     border: '1px solid #4A5566',
                     position: 'relative',
                     height:100
                 }}>
                <Row gutter={12} type="flex" align="middle" justify="space-between" style={{zIndex: 2}}>
                    <Col flex={"auto"}>
                        <div style={{float: 'left'}}>
                            <div className={'header-zxyl-size'} style={text_color}
                                 title={publish.build_date}>{name}
                            </div>
                        </div>
                    </Col>

                    <Col>
                                <span className={'header-setting-size'}
                                      title='设置'
                                      style={{
                                          cursor: 'pointer',
                                          border: '1px solid #FFFFFF'
                                      }} onClick={this.showModal}>设置</span>
                    </Col>
                    <Col style={{marginRight: 5}}>
                        <UserCtrl userInfo={this.props.userInfo}/>
                    </Col>
                </Row>

                <div className={'header-date-text-size'}
                     style={{
                         ...text_color,
                         whiteSpace: 'nowrap',
                         pointerEvents: 'none',
                         textAlign:'center'
                     }}>
                    {this.props.companyName}</div>

                {
                    this.props.list.length ?
                        <Pagination
                            className={'header-pagination-size'}
                            size={'small'}
                            style={{color: '#FFFFFF',marginTop:10,textAlign:'center'}}
                            current={this.props.page}
                            hideOnSinglePage={false}
                            pageSize={this.props.grid[0] * this.props.grid[1]}
                            defaultCurrent={1}
                            total={this.props.total}
                            onChange={this.props.onPageChange}
                            showSizeChanger={false}
                            onShowSizeChange={this.props.onPageChange}
                            showQuickJumper={false}
                            showTotal={(total, range) => this.props.mobileScreen ? `${range[1]} / ${total}` : `${total} 条记录中的第 ${range[0]} 至 ${range[1]} 条`}
                        /> : null
                }

                <Drawer
                    width={this.props.clientWidth}
                    title={<Typography.Text>参数设置</Typography.Text>}
                    headerStyle={{padding: '10px 10px'}}
                    bodyStyle={{paddingTop: '10px'}}
                    placement="right"
                    closable={false}
                    destroyOnClose={true}
                    visible={this.state.modalVisible}
                >
                    <BasicSettingWrapper
                        mngDept={this.props.mngDept}
                        setParams={this.props.setParams}
                        {...this.props}
                        onOK={this.hideModal}/>
                </Drawer>
            </div>
        )
    }


}

const mapStateToProps = (state) => {
    let userInfo = state.admin.userInfo;
    return {
        userInfo,
    }
}
export default withRouter(connect(mapStateToProps, null)(Tools));
