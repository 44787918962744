import {dataBuffer} from "./main/Common/MQTT";

/**
 * @name: leadName
 * @author: Seeker
 * @date: 2023-03-17 13:37
 * @description：leadName
 * @update: 2023-03-17 13:37
 */

const LEAD_I = 1;
const LEAD_II = 2;
const LEAD_III = 3;
const LEAD_aVR = 4;
const LEAD_aVL = 5;
const LEAD_aVF = 6;

const derivedLead = [
    {
        title:'Ⅰ',
        key:`${LEAD_I}`,
        value:LEAD_I,
        disabled:false
    },
    {
        title:'Ⅱ',
        key:`${LEAD_II}`,
        value:LEAD_II,
        disabled:false
    },
    {
        title:'Ⅲ',
        key:`${LEAD_III}`,
        value:LEAD_III,
        disabled:false
    },
    {
        title:'aVR',
        key:`${LEAD_aVR}`,
        value:LEAD_aVR,
        disabled:false
    },
    {
        title:'aVL',
        key:`${LEAD_aVL}`,
        value:LEAD_aVL,
        disabled:false
    },
    {
        title:'aVF',
        key:`${LEAD_aVF}`,
        value:LEAD_aVF,
        disabled:false
    },
];

const lead_I = (values) => values[0];
const lead_II = (values) => values[1];
const lead_III = (values) => values[1] - values[0];
const lead_aVR = (values) => -(values[1] + values[0])/2;
const lead_aVL = (values) => values[0] - values[1]/2;
const lead_aVF = (values) => values[1] - values[0]/2;
const getCalculationMethodByLead = (lead) =>{
    switch (lead){
        case LeadCompat.LEAD_I: return LeadCompat.lead_I
        case LeadCompat.LEAD_II: return LeadCompat.lead_II
        case LeadCompat.LEAD_III: return LeadCompat.lead_III
        case LeadCompat.LEAD_aVR: return LeadCompat.lead_aVR
        case LeadCompat.LEAD_aVL: return LeadCompat.lead_aVL
        case LeadCompat.LEAD_aVF: return LeadCompat.lead_aVF
        default:return LeadCompat.lead_I
    }
}
const isMultiDevice = (model) => model === 'M2';
const getMajorIndexByDeviceModel = (model) => model === 'M2'?1:0;
const getLeadTitle = (value) =>{
    const lead = derivedLead.find(event => event.value === value);
    if (lead){
        return lead.title;
    }
    return value;
};
const checkDerivedLead = (derivedLead,mqttChannel) =>{
    let tempLeadNames = [LeadCompat.LEAD_I];
    if (mqttChannel){
        const temps = mqttChannel.split('-');
        if (temps.length >= 4){
            const deviceModel = temps[2].substring(0,2);
            if (LeadCompat.isMultiDevice(deviceModel)){
                tempLeadNames = derivedLead === undefined || derivedLead === null || derivedLead.length === 0?[LeadCompat.LEAD_II]:derivedLead;
            }
        }
    }
    return tempLeadNames;
};

const LeadCompat = {
    LEAD_I,LEAD_II,LEAD_III,LEAD_aVR,LEAD_aVL,LEAD_aVF,derivedLead,
    lead_I,lead_II,lead_III,lead_aVR,lead_aVF,lead_aVL,
    isMultiDevice,getMajorIndexByDeviceModel,getLeadTitle,checkDerivedLead,getCalculationMethodByLead
};

export default LeadCompat
