import React,{Component} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchExternLogin} from "./actions";
import {message, Spin} from 'antd';

class ExternAccess extends Component{

    constructor() {
        super(...arguments);
        this.accessToken = '';
    }
    componentDidMount(){
        this.accessToken = new URLSearchParams(window.location.search).get('token');
        console.log(`[MqttTest] componentDidMount...`,this.accessToken)
        this.props.externLogin(this.accessToken);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(`[MqttTest] componentWillReceiveProps...`,nextProps.externLoginResult)
        if (nextProps.error){
            message.error(nextProps.error).then()
        }
    }

    render(){
        if (this.props.externLoginResult){
            return (
                <Redirect from="/externAccess" to="/admin" />
            )
        }
        return(
            <div style={{height:'100vh',textAlign:'center',paddingTop:'100px'}}>
                <Spin size={"large"} tip={'正在校验'}/>
            </div>
        )
    }
}

const mapStateToProps =(state,props)=>{
    const data = state.main.externAccess;
    console.log('data:',data)
    return {
        externLoginResult:data.result,
        error:data.error
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        externLogin:(token)=>{
            dispatch(fetchExternLogin(token))
        }
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ExternAccess))

