import React, {Component} from 'react';
import {Col, Icon,Pagination, Row, Drawer, Typography} from 'antd';
import UserCtrl from "../../../app/UserCtrl";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import BasicSettingWrapper from "./v2/Settings2";
import getDate from "./Date";
import DateSvg from "./DateIcon";
import './size.css'
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import MediaScreen from "./v2/MediaScreen";
import publish from "../../../publish";
import HomeStyle from "../../../HomeStyle";

class Tools extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            modalVisible: false,
            fullScreenState: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            modalVisible: true
        })
    }

    hideModal() {
        this.setState({
            modalVisible: false
        })
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keyListener);
        document.addEventListener("webkitfullscreenchange", this.fullscreenchange);
        this.props.bindRef(this);
        document.getElementById('fullScreenBtn').click();
        // this.fullScreen();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyListener);
        document.removeEventListener("webkitfullscreenchange", this.fullscreenchange);
        this.props.unBindRef(this);
    }

    refresh = (pointCount) => {
        this.showDate();
    };

    fullscreenchange = () => {
        if (document.webkitIsFullScreen) {
            //全屏后要执行的代码
            if (!this.state.fullScreenState) {
                this.setState({fullScreenState: true})
            }
        } else {
            //退出全屏后执行的代码
            if (this.state.fullScreenState) {
                this.setState({fullScreenState: false})
            }
        }
    };

    keyListener = (e) => {
        if (e.key === 'F11') {
            e.stopPropagation();
            e.preventDefault();
            this.fullScreen();
        }
    };

    // https://blog.csdn.net/dream19921216/article/details/86596408
    fullScreen = () => {
        if (!this.state.fullScreenState) {
            MediaScreen.enter();
        } else {
            MediaScreen.exit();
        }
    };

    showDate = () => {
        const oDiv = document.getElementById("date");
        oDiv.innerHTML = getDate(0);
    };

    render() {
        const text_color = {color: HomeStyle.toolsTextColor(this.props.homeStyleCode,this.props.venueSetting)};
        // let name = isBeta()?"正心心电遥测平台":"心电遥测中央监护软件";
        let name = "心电遥测中央监护软件";
        return (
            <div className={'header-size'}
                 style={{
                     ...HomeStyle.toolsBackgroundStyle(this.props.homeStyleCode,this.props.venueSetting),
                     position: 'relative'
                 }}>

                <div className={'header-date-text-size'}
                     style={{
                         ...text_color,
                         position: 'absolute',
                         left: '50%',
                         top: '50%',
                         transform: 'translate(-50%,-50%)',
                         whiteSpace: 'nowrap',
                         pointerEvents: 'none'
                     }}>
                    {this.props.companyName}</div>

                <Row gutter={12} type="flex" align="middle" justify="space-between" style={{zIndex: 2}}>
                    <Col>
                        <div style={{float: 'left'}}>
                            <span className={'header-zxyl-size'} style={text_color}
                                  title={publish.build_date}>{name}</span>
                            <span title={'当前日期'}><Icon className={'header-date-icon-size'} component={DateSvg}
                                                       style={text_color}/></span>
                            <span id={'date'} className={'header-date-text-size'}
                                  style={{textAlign: 'center', ...text_color}}>
                        </span>
                        </div>
                    </Col>
                    <Col>
                        <div style={{float: 'right', display: 'flex'}}>
                            <Row gutter={12} type="flex" align="middle" justify={"end"}>
                                <Col>
                                    {
                                        this.props.list.length ?
                                            <Pagination
                                                className={'header-pagination-size'}
                                                size={'small'}
                                                style={{color: '#FFFFFF'}}
                                                current={this.props.page}
                                                hideOnSinglePage={false}
                                                pageSize={this.props.grid[0] * this.props.grid[1]}
                                                defaultCurrent={1}
                                                total={this.props.total}
                                                onChange={this.props.onPageChange}
                                                showSizeChanger={false}
                                                onShowSizeChange={this.props.onPageChange}
                                                showQuickJumper={false}
                                                showTotal={(total, range) => `${total} 条记录中的第 ${range[0]} 至 ${range[1]} 条`}
                                            /> : null
                                    }
                                </Col>
                                <Col>
                                <span className={'header-setting-size'}
                                      title='刷新'
                                      style={{
                                          cursor: 'pointer',
                                          border: '1px solid #FFFFFF'
                                      }} onClick={() => {
                                    window.location.reload(true);
                                }}>刷新</span>
                                </Col>
                                <Col>
                                <span className={'header-setting-size'}
                                      title='设置'
                                      style={{
                                          cursor: 'pointer',
                                          border: '1px solid #FFFFFF'
                                      }} onClick={this.showModal}>设置</span>
                                </Col>
                                <Col>
                                 <span id={'fullScreenBtn'} title='全屏设置，若点击无效,请按键盘F11键开启/关闭全屏显示'
                                       style={{cursor: 'pointer'}} onClick={this.fullScreen}>
                                     {
                                         this.state.fullScreenState ?
                                             <FullscreenExitOutlined className={'header-fullscreen-icon-size'}/> :
                                             <FullscreenOutlined className={'header-fullscreen-icon-size'}/>
                                     }
                                 </span>
                                </Col>
                                <Col>
                                    <UserCtrl userInfo={this.props.userInfo}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Drawer
                    width={Math.max(this.props.clientWidth * 0.382,650)}
                    title={<Typography.Text>参数设置</Typography.Text>}
                    headerStyle={{padding: '10px 10px'}}
                    bodyStyle={{paddingTop: '10px'}}
                    placement="right"
                    closable={false}
                    destroyOnClose={true}
                    visible={this.state.modalVisible}
                >
                    <BasicSettingWrapper
                        mngDept={this.props.mngDept}
                        setParams={this.props.setParams}
                        {...this.props}
                        onOK={this.hideModal}/>
                </Drawer>
            </div>
        )
    }


}

const mapStateToProps = (state) => {
    let userInfo = state.admin.userInfo;
    return {
        userInfo,
    }
}
export default withRouter(connect(mapStateToProps, null)(Tools));
