import React, {Component} from "react";
import settingStorage from "../util/setting-storage";
import ConstantSetting from "../RealTimeMonitor/modules/v2/ConstantSetting";
import {Button, Col, Form, Input, message, Row, Switch} from "antd";
import FormItem from "antd/es/form/FormItem";

/**
 * @name: venueSetting
 * @author: Seeker
 * @date: 2022-10-31 11:08
 * @description：venueSetting
 * @update: 2022-10-31 11:08
 */

export default class VenueSetting extends Component{

    constructor(props) {
        super(props);
    }

    save = (values) => {
        const {venuePassword,venueOpenState} = values
        if (venuePassword === 'zxyl2016'){
            settingStorage.setVenueSetting({venueOpenState})
            this.props.hideFun();
        }else {
            message.error('授权密码输入错误').then(r => {})
        }
    }

    render() {
        const settings = settingStorage.getVenueSetting();
        const labelFontSize = ConstantSetting.labelFontSize(this.props.clientWidth);
        const sizeType = ConstantSetting.sizeType(this.props.clientWidth);
        return (
            <Form initialValues={{...settings}} onFinish={this.save}>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label='授权密码'
                            name={'venuePassword'}
                            rules={[{required: true, message: '请输入授权密码'}]}
                        >
                            <Input type={'password'} placeholder={'请输入授权密码'} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, '会场展览模式')}
                            name={'venueOpenState'}
                            valuePropName={'checked'}
                        >
                            <Switch size={sizeType} checkedChildren="开" unCheckedChildren="关"/>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem style={{textAlign: 'right', margin: '20px 20px 10px 0px'}}>
                    <Button onClick={this.props.hideFun} style={{marginRight: '20px'}}>取消</Button>
                    <Button type="primary" htmlType={'submit'}>确定</Button>
                </FormItem>

            </Form>
        )
    }

}
