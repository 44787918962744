/**
 * @name: ChartRenderCompat
 * @author: Seeker
 * @date: 2023-03-17 14:25
 * @description：ChartRenderCompat
 * @update: 2023-03-17 14:25
 */
import {ctrlYmv, DrawGrid, drawImpl} from "./DrawGrid";
import {data, dataBuffer} from "./MQTT";
import LeadCompat from "../../leadCompat";

const drawGrid = (ctx,width,height,darkModule,homeStyleCode,cellWidth,pacemaker,ratioRuleShow,gainValue,venueSetting,derivedLead,mqttChannel) => DrawGrid(ctx,width,height,darkModule,homeStyleCode,cellWidth,pacemaker,ratioRuleShow,gainValue,venueSetting,derivedLead,mqttChannel);

const drawChart2 = (lineCtx,drawWidth,drawHeight,mqttChannel,darkModule,homeStyleCode,cellWidth,chartHeight,gainValue,chartMaxDataLength,preHeadIndex,headIndex,baseline,yGridCount,venueSetting,derivedLead) => {
    let channelPlotData = data[mqttChannel];
    if(channelPlotData !== undefined && channelPlotData && channelPlotData.length){
        const tempPreHeadIndex = Math.max(0,preHeadIndex-1);
        const tempHeadIndex = Math.max(0,headIndex-1);
        const ymv = ctrlYmv(gainValue,yGridCount);
        const preHeadX = tempPreHeadIndex/chartMaxDataLength * drawWidth;
        let wipeIndex = (tempHeadIndex + 100) % chartMaxDataLength;
        const wipeX = wipeIndex/chartMaxDataLength * drawWidth;
        if (wipeIndex >= tempPreHeadIndex){
            lineCtx.clearRect(preHeadX,0,wipeX-preHeadX,chartHeight);
        }else {
            lineCtx.clearRect(preHeadX,0,drawWidth-preHeadX+5,chartHeight);
            lineCtx.clearRect(0,0,wipeX,chartHeight);
        }
        const tempLeadNames = LeadCompat.checkDerivedLead(derivedLead,mqttChannel);
        if (tempLeadNames.length === 1){
            drawImpl(lineCtx,drawWidth,drawHeight,darkModule,homeStyleCode,venueSetting,tempHeadIndex,tempPreHeadIndex,channelPlotData,chartMaxDataLength,baseline,LeadCompat.getCalculationMethodByLead(tempLeadNames[0]),ymv)
        }else {
            drawImpl(lineCtx,drawWidth,drawHeight,darkModule,homeStyleCode,venueSetting,tempHeadIndex,tempPreHeadIndex,channelPlotData,chartMaxDataLength,baseline-ymv/2,LeadCompat.getCalculationMethodByLead(tempLeadNames[0]),ymv)
            drawImpl(lineCtx,drawWidth,drawHeight,darkModule,homeStyleCode,venueSetting,tempHeadIndex,tempPreHeadIndex,channelPlotData,chartMaxDataLength,baseline+ymv/2,LeadCompat.getCalculationMethodByLead(tempLeadNames[1]),ymv)
        }
    }else {
        lineCtx.clearRect(0,0,drawWidth+3,chartHeight);
    }
}

const drawChart = (lineCtx,drawWidth,drawHeight,mqttChannel,darkModule,homeStyleCode,cellWidth,chartHeight,gainValue,chartMaxDataLength,headIndex,baseline,yGridCount,venueSetting,derivedLead)=>{
    drawChart2(lineCtx,drawWidth,drawHeight,mqttChannel,darkModule,homeStyleCode,cellWidth,chartHeight,gainValue,chartMaxDataLength,0,headIndex,baseline,yGridCount,venueSetting,derivedLead);
}

const ChartRenderCompat = {
    drawGrid,drawChart,drawChart2
};

export default ChartRenderCompat;
