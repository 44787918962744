import React,{Component} from "react";

import {Button} from "antd";
import html2canvas from "html2canvas";
import Printer from "../../../util/printer";
import ChartFactory, {DefaultParam} from "../../../Common/ChartFactory";
import {extra,isCalculateChannelPrintDataBaseline} from "../../../Common/MQTT";
import Spinner from "../Spinner";
import moment from "moment";
import Channel from "../../../Common/Channel";
import ThemeColor from "../ThemeColor";
import jsPDF from "jspdf";
import TimeLong, {LONG_TEST_TIME_LONG} from "../../../Common/TimeLong";
import SVGChart from "../../../Common/SVGChart";

/**
 * @name: ChannelPrinter
 * @author: Seeker
 * @date: 2021-11-23 10:34
 * @description：ChannelPrinter
 * @update: 2021-11-23 10:34
 */

class ChannelPrinter extends Component{

    constructor(props) {
        super(props);
        this.state = {
            printButtonDisabled:true
        };
        this.showData = [];
        this.svgData = [];
        const layout = TimeLong.chartLayout(props.timeLong)
        this.initPrintData(layout.rowCount);
        this.showChart = new ChartFactory({
            ...DefaultParam,
            ...layout,
            lineWidth:1.5,
            gridWidth:18,
            borderColor:ThemeColor.chartGridLineColor(this.props.darkMode),
            gridColor:ThemeColor.chartGridLineColor(this.props.darkMode),
            pointColor:'#D2D2D2',
            ecgLineColor:ThemeColor.ecgLineColor(this.props.darkMode),
        });
        this.printChart = new ChartFactory({
            ...DefaultParam,
            ...layout,
            borderColor:'#9B9B9B',
            gridColor:'#D2D2D2',
            ecgLineColor:'#333333'
        });
        this.finishDate = null;
    }

    initPrintData = (rowCount)=>{
        this.showData.length = 0;
        this.svgData.length = 0;
        for (let i = 0;i < rowCount;i++){
            this.showData.push([]);
        }
    };

    html2canvasResponse = (canvas)=>{
        const dataURL = canvas.toDataURL("image/JPEG");
        Printer.printCurWindow(dataURL);
    };

    pdfHtml2canvasResponse = (canvas)=>{
        const dataURL = canvas.toDataURL("image/JPEG");
        let a4Width = 595.28
        let a4Height = 841.89
        // 生成的画布元素宽高（需要收缩回原比例大小）
        let canvasWidth = canvas.width / 5;
        let canvasHeight = canvas.height / 5;
        // 页面等比例缩放后宽高
        let pageWidth = a4Height - 60;
        let pageHeight = (a4Height / canvasWidth) * canvasHeight;
        const jspdf = new jsPDF('l','pt','a4');
        jspdf.addImage(dataURL, 'JPEG', 30, 20, pageWidth,pageHeight)
        jspdf.save(`${moment(this.finishDate-LONG_TEST_TIME_LONG*1000).format('HH-mm-ss')}.pdf`)
        this.props.hideFun && this.props.hideFun();
    };

    print = (pdf)=>{
        const ecgchart_print = document.getElementById('ecgchart_print');
        const context_print = this.printChart.setCanvas(ecgchart_print);
        this.printChart.drawBackGrid(context_print,true);
        this.printChart.drawPrintChartLine(context_print,this.showData);
        // SVGChart.init(ecgchart_print)
        //     .data(this.svgData.slice(0,TimeLong.dataPointLength(this.props.timeLong)))
        //     .config(TimeLong.svgConfig(this.props.timeLong))
        //     .render();
        html2canvas(document.getElementById('printContainer'),{
            allowTaint: true,
            dpi: window.devicePixelRatio,
            scale:5,
            useCORS:true,
        })
            .then(pdf?this.pdfHtml2canvasResponse:this.html2canvasResponse)
    };

    componentDidMount() {
        this.loadingContainer = document.getElementById('loadingContainer');
        this.loadingText = document.getElementById('loadingText');
        this.printDate = document.getElementById('print_date');
        // 显示canvas
        this.ecgchart_show = document.getElementById('ecgchart_show');
        this.context_show = this.showChart.setCanvas(this.ecgchart_show);
        this.showChart.drawBackGrid(this.context_show);
        this.chartShowBackGrid = this.context_show.getImageData(0,0,this.ecgchart_show.width,this.ecgchart_show.height);
        this.drawChartLine(this.context_show,this.chartShowBackGrid,17,4);
    }

    showLoading = ()=>{
        const extraObj = extra[this.props.channel.mqtt_channel];
        const {finish,offline,bleState} = extraObj;
        if (finish === 0 || offline === 1 || offline === undefined || bleState === 0 || bleState === undefined){
            this.loadingContainer.style.display = 'inline-block';
            if (finish === 0){
                this.loadingText.innerText = '该通道已结束';
            }else if (offline === 1 || offline === undefined){
                this.loadingText.innerText = '手机未接入网络';
            }else if (bleState === 0 || bleState === undefined){
                this.loadingText.innerText = '心电仪未连接手机';
            }
            // this.initPrintData();
        }else if (isCalculateChannelPrintDataBaseline){
            this.loadingContainer.style.display = 'inline-block';
            this.loadingText.innerText = '正在校准基线';
        }else {
            this.loadingContainer.style.display = 'none';
        }
    };

    drawChartLine = (context,chartBackGrid,timeOut,addPointCount)=>{
        this.renderTimeout = setTimeout(()=>{
            const start = new Date().getTime();
            this.showLoading();
            const result = this.showChart.drawChartLine(context,this.showData,this.svgData,chartBackGrid,timeOut,addPointCount);
            if (result){
                const end = new Date().getTime();
                const interval = end - start;
                if (interval > 17){
                    let nextCount = Math.ceil((interval-17)/17 * 4)+4;
                    this.drawChartLine(context,chartBackGrid,0,nextCount);
                }else {
                    this.drawChartLine(context,chartBackGrid,17-interval,4);
                }
            }else {
                this.finishDate = new Date().getTime();
                this.setState({
                    printButtonDisabled:false
                },() =>{
                    if (this.props.timeLong === LONG_TEST_TIME_LONG){
                        this.print(true)
                    }
                });
            }

        },timeOut);
    };


    componentWillUnmount() {
        clearTimeout(this.renderTimeout);
    }

    render() {
        const {channel,hideFun,width,height} = this.props;
        const {user_name, dept_name, bed_number, mach_sn, mqtt_channel} = channel;
        const deptName = Channel.deptName(dept_name);
        const duration = this.showChart.getDuration();
        const commonStyle = {marginBottom:'5px',textAlign:'center',position:'absolute',padding:'5px 20px'};
        const timeText = this.finishDate?`${moment(this.finishDate-duration*1000).format('HH:mm:ss')} ~ ${moment(this.finishDate).format('HH:mm:ss')}`:'';
        const dateText = this.finishDate? moment(this.finishDate-duration*1000).format('yyyy年MM月DD日'):'';
        return (
            <div style={{position:'relative'}}>

                <div id={'printContainer'} style={{backgroundColor:'transparent',...commonStyle,padding:0,marginTop:0}} >
                    <div style={{marginBottom:'15px'}}>
                        <span style={{fontSize:'18px',color:'#000'}}>姓名：{user_name}</span>
                        <span style={{fontSize:'18px',marginLeft:'40px',color:'#000'}}>科室：{deptName}</span>
                        <span style={{fontSize:'18px',marginLeft:'40px',color:'#000'}}>床位号：{bed_number}</span>
                    </div>
                    <canvas id={'ecgchart_print'}/>
                    {/*<svg id={'ecgchart_print'} xmlns="http://www.w3.org/2000/svg" version="1.1" style={{width:1000,height:200}}/>*/}
                    <div style={{marginTop:'5px',justifyContent:'space-between',display:'flex'}}>
                        <span style={{fontSize:'14px',color:'#000'}}>10mm/mv 25mm/s</span>
                        <span id={'print_date'} style={{fontSize:'14px',color:'#000'}}>时间：{dateText} {timeText}</span>
                    </div>
                </div>

                <div style={{backgroundColor:ThemeColor.backgroundColor(this.props.darkMode),...commonStyle,border:'2px solid #484F5F',borderRadius:'5px'}} >
                    <div style={{marginBottom:'10px'}}>
                        <span style={{fontSize:'18px'}}>姓名：{user_name}</span>
                        <span style={{fontSize:'18px',marginLeft:'20px'}}>科室：{deptName}</span>
                        <span style={{fontSize:'18px',marginLeft:'20px'}}>床位号：{bed_number}</span>
                    </div>
                    <canvas id={'ecgchart_show'}/>
                    {
                        this.props.timeLong !== LONG_TEST_TIME_LONG ? <div style={{textAlign:'right',marginTop:'5px'}}>
                            <span style={{color:'#EB4025',marginRight:'20px',fontSize:'16px'}}>等待{duration}秒心电图采集完成后可进行打印</span>
                            <Button style={this.state.printButtonDisabled?{backgroundColor:'#555555',marginRight:'20px'}:{marginRight:'20px'}} disabled={this.state.printButtonDisabled} type="primary" onClick={()=>{
                                this.print();
                                hideFun();
                            }}>打印</Button>
                            <Button onClick={hideFun}>取消</Button>
                        </div>:<div style={{textAlign:'right',marginTop:'5px'}}>
                            <span style={{color:'#EB4025',marginRight:'20px',fontSize:'16px'}}>24小时测试心电图采集，完成之后自动保存pdf文件</span></div>
                    }
                </div>

                <div id={'loadingContainer'} style={{...commonStyle,...TimeLong.calLoadingMargin(this.props.timeLong)}}>
                    <Spinner darkModule={true}/>
                    <div id={'loadingText'} style={{fontSize:'20px',marginLeft:'-35px'}}/>
                </div>
            </div>
        );

    }
}

export {LONG_TEST_TIME_LONG}

export default ChannelPrinter
