/**
 * @name: Saver
 * @author: Seeker
 * @date: 2022-01-26 13:46
 * @description：Saver
 * @update: 2022-01-26 13:46
 */

const saveToFile = (data,fileName='save.txt') =>{
    const urlObj = window.URL || window.webkitURL || window;
    const export_blob = new Blob([data.join(',')]);
    const save_link = document.createElementNS("http://www.w3.org/1999/xhtml","a")
    save_link.href = urlObj.createObjectURL(export_blob);
    save_link.download = fileName;
    save_link.click();
};

const Saver = {
    saveToFile,
};

export default Saver

