import React, {useState} from "react";
import {Select, Switch, Button, Form, Row, Col, InputNumber} from 'antd';
import audioManagerImpl from "../../../audioManager";
import alarmEvent, {emergencyResidentCode} from "./AlarmEvent";
import ConstantSetting from "./ConstantSetting";

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * @name: MobileAbnormalEventSetting
 * @author: Seeker
 * @date: 2022-03-10 14:48
 * @description：MobileAbnormalEventSetting
 * @update: 2022-03-10 14:48
 */

const AbnormalEventSetting = (props) => {

    const sizeType = ConstantSetting.sizeType(props.clientWidth);
    const labelFontSize = ConstantSetting.labelFontSize(props.clientWidth);
    const [abnForm] = Form.useForm();
    const [selectedEventItems,setSelectedEventItems] = useState({
        emergency: props.emergencyAlarmEvent || [],
        nonEmergency:props.nonEmergencyAlarmEvent || [],
    });
    const emergencyFilteredOptions = alarmEvent.filter(o => (selectedEventItems.emergency.findIndex(value => value === o.value) < 0));
    const nonEmergencyFilteredOptions = alarmEvent.filter(o => (selectedEventItems.nonEmergency.findIndex(value => value === o.value) < 0
        && emergencyResidentCode.findIndex(value => value === o.value) < 0));

    const setSelected = (nonEmergency,emergency) =>{
        abnForm.setFieldsValue({nonEmergencyAlarmEvent: nonEmergency,emergencyAlarmEvent:emergency})
        setSelectedEventItems({nonEmergency:nonEmergency,emergency:emergency})
    };

    return (
        <>
            <Form
                form={abnForm}
                name={'abnEventSettingForm'}
                initialValues={{
                    ...props,
                    // emergencySound: 0,
                    // emergencySoundState:true,
                    // emergencySoundInterval:0,
                    // emergencyAlarmEvent:[],
                    //
                    // nonEmergencySound: 0,
                    // nonEmergencySoundState:true,
                    // nonEmergencySoundInterval:0,
                    // nonEmergencyAlarmEvent:[],
                }}>

                <div style={{fontSize:18,color:'#FF4D4F',marginBottom:15}}>紧急异常事件:</div>

                <Row>
                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.formItemLayout1}
                            label={ConstantSetting.label(labelFontSize, '异常提示音')}
                            name={'emergencySoundState'}
                            valuePropName={'checked'}
                        >
                            <Switch size={sizeType} disabled checkedChildren="开" unCheckedChildren="关"/>
                        </FormItem>
                    </Col>

                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2}
                            label={ConstantSetting.labelPopover(labelFontSize, '文字提示时长', '出现紧急异常提示事件时文字显示的时间长度,需手动点击关闭')}
                            name={'emergencyTextShowTimeLong'}
                        >
                            <Select className={'setting-select-size'} size={sizeType} disabled>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>一直显示</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2_left}
                            label={ConstantSetting.label(labelFontSize, '提示音音效')}
                            name={'emergencySound'}
                        >
                            <Select className={'setting-select-size'} size={sizeType} disabled={true}>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>滴滴滴声</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2}
                            label={ConstantSetting.labelPopover(labelFontSize, '提示音间隔', '同一个患者出现紧急异常提示事件时提示音的间隔')}
                            name={'emergencySoundInterval'}
                        >
                            <Select className={'setting-select-size'} size={sizeType} disabled>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>无间隔</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2_left}
                            label={ConstantSetting.label(labelFontSize, '长间歇最低时长')}
                            name={'rrLongPause'} >
                            {/*<InputNumber className={`setting-select-size`} size={sizeType} min={2} max={4} addonAfter={ConstantSetting.label(labelFontSize, '秒')} defaultValue={2}/>*/}
                            <Select className={'setting-select-size'} size={sizeType}>
                                <Option className={'setting-select-size'} key={'2s'} value={2}>2秒</Option>
                                <Option className={'setting-select-size'} key={'3s'} value={3}>3秒</Option>
                                <Option className={'setting-select-size'} key={'4s'} value={4}>4秒</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout}
                            label={ConstantSetting.label(labelFontSize, '异常提示事件')}
                            name={'emergencyAlarmEvent'}>
                            <Select
                                tagRender={ConstantSetting.tagRender}
                                size={sizeType}
                                showSearch={false}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                mode="multiple"
                                placeholder='请选择异常提示事件'
                                value={selectedEventItems.emergency}
                                onDeselect={(value) =>{
                                    const nonEmergency = selectedEventItems.nonEmergency;
                                    const emergency = selectedEventItems.emergency.filter(o => o !== value);
                                    setSelected(nonEmergency,emergency);
                                }}
                                onSelect={(value)=>{
                                    const nonEmergency = selectedEventItems.nonEmergency.filter(o => o !== value);
                                    const emergency = [...selectedEventItems.emergency,value];
                                    setSelected(nonEmergency,emergency);
                                }}
                            >
                                {emergencyFilteredOptions.map(item => (
                                    <Select.Option key={item.key} value={item.value}>
                                        {item.title}
                                    </Select.Option>
                                ))}
                            </Select>

                        </FormItem>
                    </Col>
                </Row>

                <div style={{fontSize:18,color:'#FF8000',marginBottom:15,marginTop:50}}>非紧急异常事件:</div>

                <Row>
                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.formItemLayout1}
                            label={ConstantSetting.label(labelFontSize, '异常提示音')}
                            name={'nonEmergencySoundState'}
                            valuePropName={'checked'}
                        >
                            <Switch size={sizeType} checkedChildren="开" unCheckedChildren="关"/>
                        </FormItem>
                    </Col>

                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2}
                            label={ConstantSetting.labelPopover(labelFontSize, '文字提示时长', '出现非紧急异常提示事件时文字显示的时间长度,自动关闭')}
                            name={'nonEmergencyTextShowTimeLong'}
                        >
                            <Select className={'setting-select-size'} size={sizeType}>
                                <Option className={'setting-select-size'} key={'15'} value={15}>15秒</Option>
                                <Option className={'setting-select-size'} key={'30'} value={30}>30秒</Option>
                                <Option className={'setting-select-size'} key={'60'} value={60}>1分钟</Option>
                                <Option className={'setting-select-size'} key={'120'} value={120}>2分钟</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2_left}
                            label={ConstantSetting.label(labelFontSize, '提示音音效')}
                            name={'nonEmergencySound'}
                        >
                            <Select className={'setting-select-size'} size={sizeType}
                                    onBlur={() => {
                                        audioManagerImpl.testPlay(undefined, false);
                                    }}
                                    onSelect={(value, option) => {
                                        audioManagerImpl.testPlay(value, true);
                                    }}>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>滴滴滴声</Option>
                                <Option className={'setting-select-size'} key={'minute1'} value={1}>Indian Summer</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={12} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout2}
                            label={ConstantSetting.labelPopover(labelFontSize, '提示音间隔', '同一个患者出现非紧急异常提示事件时提示音的间隔')}
                            name={'nonEmergencySoundInterval'}
                        >
                            <Select className={'setting-select-size'} size={sizeType}>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>无间隔</Option>
                                <Option className={'setting-select-size'} key={'minute1'} value={60}>1分钟</Option>
                                <Option className={'setting-select-size'} key={'minute5'} value={300}>5分钟</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout}
                            label={ConstantSetting.label(labelFontSize, '异常提示事件')}
                            name={'nonEmergencyAlarmEvent'}>
                            <Select
                                tagRender={ConstantSetting.tagRender}
                                size={sizeType}
                                allowClear={true}
                                showSearch={false}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                mode="multiple"
                                placeholder='请选择异常提示事件'
                                value={selectedEventItems.nonEmergency}
                                onDeselect={(value) =>{
                                    const nonEmergency = selectedEventItems.nonEmergency.filter(o => o !== value);
                                    const emergency = selectedEventItems.emergency;
                                    setSelected(nonEmergency,emergency);
                                }}
                                onSelect={(value)=>{
                                    const nonEmergency = [...selectedEventItems.nonEmergency,value];
                                    const emergency = selectedEventItems.emergency.filter(o => o !== value);
                                    setSelected(nonEmergency,emergency);
                                }}
                            >
                                {nonEmergencyFilteredOptions.map(item => (
                                    <Select.Option key={item.key} value={item.value}>
                                        {item.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem {...ConstantSetting.tailFormItemLayout} style={{marginTop: '8%'}}>
                    <Button style={{width: '20%', marginLeft: '20%'}} onClick={props.hideChildrenDrawer}>取消</Button>
                    <Button style={{width: '20%', marginLeft: '30%'}} type='primary' onClick={() => {
                        abnForm.submit();
                        props.hideChildrenDrawer();
                    }}>确定</Button>
                </FormItem>
            </Form>
        </>
    )
};


export default AbnormalEventSetting
