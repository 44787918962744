import {
    FETCH_USER_HISTORY_LIST_STARTED,
    FETCH_USER_HISTORY_LIST_SUCCESS,
    FETCH_USER_HISTORY_LIST_FAILURE,
    SET_USER_HISTORY_FILTER
} from "./actionTypes";
const initState={
    loading:false,
    list:[],
    filters:{}
}
export default (state=initState,action)=>{
    switch (action.type) {
        case FETCH_USER_HISTORY_LIST_STARTED:
            return{
                ...state,
                loading:true
            };
        case FETCH_USER_HISTORY_LIST_SUCCESS:
            return{
                ...state,
                pagination: action.payload.pagination,
                sort: action.payload.sort,
                list: action.payload.list,
                loading: false
            };
        case FETCH_USER_HISTORY_LIST_FAILURE:
            return{
                ...state,
                loading:false
            };
        case SET_USER_HISTORY_FILTER:
            return{
                ...state,
                filters:action.payload.filter
            }
        default:
            return state
    }
}
