import React,{Component} from 'react';
import {Row,Col,Input,DatePicker,Button} from "antd";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchUserHistoryList, setUserHistoryFilter} from "../actions";
import {ExportOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import ExportJsonExcel from "js-export-excel";
import axios from "../../../myAxios";
import qs from "qs";
import moment from "moment";
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const {RangePicker} = DatePicker;
class FilterPanel extends Component{
    constructor() {
        super(...arguments);
        this.state = {
            filters:{
                userName: null,
                companyName: null,
                deptName: null,
                prescriberName: null,
                startTime: null,
                endTime: null,
                rangeDate:[undefined,undefined]
            },
            endOpen: false,
            loading: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.disabledEndDate = this.disabledEndDate.bind(this);
        this.disabledStartDate = this.disabledStartDate.bind(this);
        this.handleStartOpenChange = this.handleStartOpenChange.bind(this);
        this.handleEndOpenChange = this.handleEndOpenChange.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }
    componentDidMount() {
        this.setState({
            filters: {...this.props.filter}
        })
    }
    disabledDate(current){
        return current && current > moment().endOf('day');
    }
    handleDateChange(dates){
        if(dates){
            const startTime = dates[0];
            const endTime = dates[1];
            this.setState({
                filters:{
                    ...this.state.filters,
                    startTime,
                    endTime,
                    rangeDate: dates
                }
            })
        }else{
            this.setState({
                filters:{
                    ...this.state.filters,
                    startTime: null,
                    endTime: null,
                    rangeDate: [undefined,undefined]
                }
            })
        }

    }
    handleInputChange(value,key){
        this.setState({
            filters: {
                ...this.state.filters,
                [key]: value
            }
        });
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onFilter();
        }
    }
    onFilter() {
        this.props.setFilter(this.state.filters);
        this.props.queryData(this.state.filters, {current: 1, pageSize: this.props.pagination.pageSize}, this.props.sort);
    }
    resetFilter() {
        const filter = {
            userName: null,
            companyName: null,
            deptName: null,
            prescriberName: null,
            startTime: null,
            endTime: null,
            dateRange:[undefined,undefined]
        }
        this.setState({ filters: { ...filter } });
        this.props.setFilter(filter);
        this.props.queryData();
    }
    disabledStartDate(startValue) {
        const endValue = this.state.filters['endTime'];
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.startOf('day').valueOf() > endValue.startOf('day').valueOf();
    }

    disabledEndDate(endValue) {
        const startValue = this.state.filters['startTime'];
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.startOf('day').valueOf() < startValue.startOf('day').valueOf();
    }

    handleStartOpenChange(open) {
        if (!open) {
            this.setState({ endOpen: true  });
        }
    }

    handleEndOpenChange(open) {
        this.setState({ endOpen: open  });
    }
    onRefresh() {
        this.props.queryData(this.state.filters, this.props.pagination, this.props.sort);
    }
    exportExcel(){
        this.setState({
            loading: true
        })
        const dateStart =  this.state.filters.startTime ? moment(this.state.filters.startTime).format('YYYY-MM-DD') : null;
        const dateEnd = this.state.filters.endTime ?  moment(this.state.filters.endTime).format('YYYY-MM-DD') : null;
        const params = {
            page: 1,
            rows: 2147483647, //java中能够取到的int类型的最大值
            sort: this.props.sort.field,
            order: this.props.sort.order,
            'filter.user_name': this.state.filters.userName ,
            'filter.company_name': this.state.filters.companyName,
            'filter.prescriber_name': this.state.filters.prescriberName,
            'filter.dept_name': this.state.filters.deptName,
            'filter.start_time': dateStart,
            'filter.end_time': dateEnd
        };
        return axios.post('/cust/rt/rtHisRecListByCheckOrderExcel.action',
            qs.stringify(params),{
            responseType: 'blob',
            headers:{
              'Content-Type': 'application/x-www-form-urlencoded',
            }}).then(res=>{
                this.setState({
                    loading:false
                })
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download',  `遥测历史记录-${moment().format('YYYY-MM-DD')}.xls`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(url); //释放掉blob对象
        }).catch((err)=>{
            this.setState({
                loading:false
            })
        })
    }
    render(){
        const dateFormat = 'YYYY-MM-DD';
        return (
            <>
                <Row gutter={24} onKeyPress={this.handleKeyPress}>
                    <Col sm={24} lg={4}>
                        <div style={{display:'flex'}}>
                            <div style={{marginRight:5,marginTop:5}}>用户姓名:</div>
                            <Input
                                placeholder='请输入'
                                style={{width:'70%'}}
                                value={this.state.filters.userName}
                                allowClear
                                onChange={(e)=>this.handleInputChange(e.target.value,'userName')}
                            />
                        </div>

                    </Col>
                    <Col sm={24} lg={4}>
                        <div style={{display:'flex'}}>
                            <div style={{marginRight:5,marginTop:5}}>机构:</div>
                            <Input
                                placeholder='请输入'
                                style={{width:'70%'}}
                                value={this.state.filters.companyName}
                                allowClear
                                onChange={(e)=>this.handleInputChange(e.target.value,'companyName')}
                            />
                        </div>
                    </Col>
                    <Col sm={24} lg={4}>
                        <div style={{display:'flex'}}>
                            <div style={{marginRight:5,marginTop:5}}>科室:</div>
                            <Input
                                placeholder='请输入'
                                style={{width:'70%'}}
                                value={this.state.filters.deptName}
                                allowClear
                                onChange={(e)=>this.handleInputChange(e.target.value,'deptName')}
                            />
                        </div>
                    </Col>
                    <Col sm={24} lg={4}>
                        <div style={{display:'flex'}}>
                            <div style={{marginRight:5,marginTop:5}}>申请医生:</div>
                            <Input
                                placeholder='请输入'
                                style={{width:'70%'}}
                                value={this.state.filters.prescriberName}
                                allowClear
                                onChange={(e)=>this.handleInputChange(e.target.value,'prescriberName')}
                            />
                        </div>

                    </Col>
                    <Col sm={24} lg={8}>
                        <div style={{display:'flex'}}>
                            <div style={{marginRight:5,marginTop:5}}>日期范围:</div>
                            <RangePicker
                                style={{width:'70%'}}
                                disabledDate={this.disabledDate}
                                value={this.state.filters.rangeDate}
                                onChange={(dates, dateString) => {this.handleDateChange(dates)}}
                            />
                        </div>

                    </Col>
                    <Col sm={24} lg={24}>
                        <div style={{display:'flex',justifyContent:'flex-end',margin:'10px 20px'}}>
                            <Button.Group style={{marginRight:5}}>
                                <Button onClick={this.onFilter} icon={<SearchOutlined />} type='primary' htmlType='button'>筛选</Button>
                                <Button onClick={this.resetFilter} htmlType='button'>重置</Button>
                            </Button.Group>
                            <Button
                                type='primary'
                                htmlType='button'
                                icon={<ExportOutlined />}
                                style={{marginRight:5}}
                                onClick={this.exportExcel}
                                loading={this.state.loading}
                            >
                                导出
                            </Button>
                            <Button htmlType='button' icon={<ReloadOutlined />} style={{marginRight:5}} onClick={this.props.onRefresh}>刷新</Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}
const mapStateToProps = (state) =>{
    const data = state.main.history;
    return{
        filter:data.filters,
        pagination:data.pagination,
        sort:data.sort
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        queryData:(filter,pagination,sort)=>{
            dispatch(fetchUserHistoryList(filter,pagination,sort));
        },
        setFilter:(filter)=>{
            dispatch(setUserHistoryFilter(filter));
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterPanel));
