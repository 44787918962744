import {FETCH_USERINFO_SUCCESS, SET_HOME_STYLE, SET_SHOW_MODULE} from "./actionTypes";
export default (state={showDarkModule:false,homeStyleCode:1},action)=>{
    switch (action.type) {
        case FETCH_USERINFO_SUCCESS:
            return{
                ...state,
                userInfo:action.payload.result
            }
        case SET_SHOW_MODULE:
            return{
                ...state,
                showDarkModule:action.payload.checked,
                venueSetting:action.payload.venueSetting
            }
        case SET_HOME_STYLE:
            return{
                ...state,
                homeStyleCode:action.payload.homeStyleCode,
                venueSetting:action.payload.venueSetting
            }
        default:
            return state

    }
}
