import React from 'react';

/**
 * @name: DateIcon
 * @author: Seeker
 * @date: 2021-08-18 09:29
 * @description：DateIcon
 * @update: 2021-08-18 09:29
 */

const DateSvg = () => {
    return <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
        <path
            d="M511.945 0C229.155 0.028 0 229.265 0 512c0 282.762 229.238 512 512 512s512-229.238 512-512v0.055c0-282.762-229.238-512-512-512L511.945 0z m0 955.807C266.794 951.49 71.625 749.291 75.942 504.195 80.122 265.161 272.882 72.4 511.945 68.193c245.095 4.317 440.32 206.516 436.002 451.612C943.741 758.839 750.98 951.6 511.945 955.807z m221.93-409.71H511.918V324.22a17.159 17.159 0 0 0-16.993-17.076h-34.18a17.159 17.159 0 0 0-17.02 17.076v273.048a17.159 17.159 0 0 0 17.02 17.103h273.049a17.159 17.159 0 0 0 17.159-17.103V563.2a17.02 17.02 0 0 0-17.16-17.076h0.084z"
            />
    </svg>
};

export default DateSvg
