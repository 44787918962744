import {combineReducers} from 'redux';
import LoginReducer from '../login/reducer';
import Reducer from './reducer';
import {reducer as MainReducer} from "../main";

const reducer = combineReducers({
    login: LoginReducer,
    admin:Reducer,
    main:MainReducer
});

export {reducer};
