import {view as RealTimeMonitor,reducer as RealTimeMonitorReducer} from './RealTimeMonitor';
import {view as HistoryRecord,reducer as HistoryReducer} from './HistoryRecord';
import {view as MqttTest,reducer as MqttTestReducer} from './MqttTest';
import {view as  ExternAccess,reducer as ExternAccessReducer} from './externAccess';
import {view as ElementTest} from './elementTest';
import {combineReducers} from 'redux';
const reducer = combineReducers({
    monitor:RealTimeMonitorReducer,
    history: HistoryReducer,
    mqttTest: MqttTestReducer,
    externAccess:ExternAccessReducer,
})
export{reducer,RealTimeMonitor,HistoryRecord,MqttTest,ExternAccess,ElementTest}
