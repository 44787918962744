/**
 * @name: audioManager
 * @author: Seeker
 * @date: 2021-05-24 09:46
 * @description：audioManager
 * @update: 2021-05-24 09:46
 */

import didi from "../audio/warn.mp3";
import IndianSummer from "../audio/IndianSummer.mp3";

class AudioManager {

    constructor() {
        this.playAudioTimeoutId = null;
        this.settings = {
            emergencySound: 0,
            emergencySoundState:true,
            nonEmergencySound: 1,
            nonEmergencySoundState:true,
        };
        this.preEventType = -1;
    }
    // 0: 紧急，1：非紧急
    play = (eventType) => {
        const audio = document.getElementById('audio_warn');
        if (audio){
            if (eventType === 0){
                if (this.preEventType !== 0){
                    this.stop(audio);
                }
                if (!this.playAudioTimeoutId) {
                    audio.currentTime = 0;
                    audio.muted = false;
                    audio.src = didi;
                    audio.play();
                    this.playAudioTimeoutId = setTimeout(()=>{
                        audio.pause();
                        this.playAudioTimeoutId = null;
                    },3000)
                }
            }else if (eventType === 1){
                if (this.settings.nonEmergencySoundState){
                    if (!this.playAudioTimeoutId) {
                        audio.currentTime = 0;
                        audio.muted = false;
                        audio.src = this.settings.nonEmergencySound === 0?didi:IndianSummer;
                        audio.play();
                        this.playAudioTimeoutId = setTimeout(()=>{
                            audio.pause();
                            this.playAudioTimeoutId = null;
                        },this.settings.nonEmergencySound === 0?3000:6000)
                    }
                }else {
                    if (!this.playAudioTimeoutId){
                        return;
                    }
                    this.stop(audio);
                }
            }
        }
        this.preEventType = eventType;
    }

    stop = (audio) =>{
        if (this.playAudioTimeoutId){
            clearTimeout(this.playAudioTimeoutId);
        }
        this.playAudioTimeoutId = null;
        audio.pause();
    };

    testPlay = (value,play) => {
        const audio = document.getElementById('audio_warn');
        if (audio){
            if (play){
                if (value === 0){
                    audio.src = didi;
                }else if (value === 1){
                    audio.src = IndianSummer;
                }
                audio.currentTime = 0;
                audio.muted = false;
                audio.play();
            }else {
                audio.pause();
            }
        }
    }

    setWarnSetting = (newSettings) =>{
        this.settings = newSettings;
    };

}

const AudioManagerSingleton = (function () {
    let instance;
    return function () {
        if (instance){
            return instance;
        }
        return instance = new AudioManager();
    }
})();

const audioManagerImpl = new AudioManagerSingleton();

export default audioManagerImpl;
