import React, {Component} from 'react';
import {dataBuffer, data, enableDraw, extra} from "./MQTT";
import './style.css';
import {demoDataBuffer} from "../demo/data";
import _isFunction from "../util/is-Function"
import Spinner from "../RealTimeMonitor/modules/Spinner";
import AbnormalCompat from "../compat/AbnormalCompat";
import {SettingFilled} from "@ant-design/icons";
import signalIcon from "../../img/wifi_weak.png"
import ChartRenderCompat from "./ChartRenderCompat";
import moment from "moment";
import HomeStyle from "../../HomeStyle";

let bufferEmptyTimes = 0;
// let bufferSizeCheckPoint = 0;
// const DEFAULT_CHART_PADDING = 10;

const getPixelRatio = () => {
    const pr = window ? window.devicePixelRatio : 1;
    return Math.ceil(Math.max(pr, 1));
};

class EcgChart extends Component {

    constructor(props) {
        super(...arguments);
        this.background = null;
        this.chart = null;
        this.notice = null;
        this.backCtx = null;
        this.lineCtx = null;
        this.index = 0;//计数器
        this.refreshCount = 0;
        // this.showNotice = this.showNotice.bind(this);
        this.calculate = this.calculate.bind(this);
        const {
            vPadding,
            hPadding,
            chartHeight,
            cellWidth,
            chartWidth,
            chartWidthInt,
            chartHeightInt
        } = this.calculate(this.props);
        this.state = {
            width: chartWidthInt,
            height: chartHeight,
            drawWidth: chartWidthInt,
            drawHeight: chartHeightInt,
            cellWidth,
            vPadding,
            hPadding,
            signalWeak: false,
        };

        // 刷新率相关
        this.fps = props.fps;
        // this.refreshTimestamp = 0;   // 前一次刷新时间戳
        // this.refreshTotalInterval = 0;  // 两次计算FPS之间的时间间隔
        this.refreshCounts = 0; // 两次计算FPS之间的刷新次数
        // 走速调整阈值
        // this.Speed_Up_Ratio = 1.1;    // 缓冲区超过标准值*Speed_Up_Ratio，则加速绘图
        // this.Speed_Down_Ratio = 0.9;  // 缓冲区少于标准值*Speed_Down_Ratio，则减速绘图
        this.channelCount = props.channelCount;
        this.minDelay = props.minDelay;
        // this.checker = new Checker();
        this.minusCount = 0
    }

    componentDidMount() {
        this.props.bindRef(this);
        this.backCtx = this.background.getContext('2d', {alpha: true});
        this.lineCtx = this.chart.getContext('2d', {alpha: true});
        this.lineCtx.strokeStyle = '#000000';
        this.doDrawGrid(this.props);
        // this.startRefreshTimer();
    }

    componentWillUnmount() {
        this.props.unBindRef(this);
        // this.stopRefreshTimer();
    }

    _scaleSet(canvas, context, chartWidthInt, chartHeightInt) {
        const pixelRatio = getPixelRatio();
        canvas.style.width = `${chartWidthInt}px`;
        canvas.style.height = `${chartHeightInt}px`;
        canvas.width = pixelRatio * chartWidthInt;
        canvas.height = pixelRatio * chartHeightInt;
        context.scale(pixelRatio, pixelRatio);
    }

    scaleSet(chartWidthInt, chartHeightInt) {
        this._scaleSet(this.background, this.backCtx, chartWidthInt, chartHeightInt);
        this._scaleSet(this.chart, this.lineCtx, chartWidthInt, chartHeightInt);
    }

    calculate(props) {
        const chartGridLineWidth = HomeStyle.chartGridLineWidth(props.homeStyleCode)
        let chartPadding = props.chartPadding;
        if(chartPadding === undefined){
            chartPadding = HomeStyle.chartPadding(props.homeStyleCode);
        }
        let chartHeight = props.height - chartPadding;
        let cellWidth = (chartHeight - chartGridLineWidth) / props.yGridCount;
        let chartWidth = props.width - props.leftWidth;
        let xCount = Math.floor((chartWidth - chartGridLineWidth) / cellWidth) - 1;
        let chartWidthInt = cellWidth * xCount + chartGridLineWidth;
        let chartHeightInt = cellWidth * props.yGridCount + chartGridLineWidth;
        let vPadding = chartPadding / 2;
        let hPadding = (chartWidth - chartWidthInt) / 8;
        return {vPadding, hPadding, chartHeight, cellWidth, chartWidth, chartWidthInt, chartHeightInt};
    }

    doDrawGrid(props) {
        const {
            vPadding,
            hPadding,
            chartHeight,
            cellWidth,
            chartWidth,
            chartWidthInt,
            chartHeightInt
        } = this.calculate(props);
        this.scaleSet(chartWidthInt, chartHeightInt);
        this.setState({
            width: chartWidth,
            height: chartHeight,
            drawWidth: chartWidthInt,
            drawHeight: chartHeightInt,
            cellWidth,
            vPadding,
            hPadding
        }, () => {
            ChartRenderCompat.drawGrid(this.backCtx,
                this.state.drawWidth,
                this.state.drawHeight,
                props.darkModule,
                props.homeStyleCode,
                this.state.cellWidth,
                props.isPacemaker,
                props.ratioRuleShow,
                props.channelSetting.gain || props.gain,
                props.venueSetting,
                props.derivedLead,
                props.mqttChannel);
            // this.showChannelSetIcon();
        })
    }

    refresh = (pointCount) => {
        let chartMaxDataLength = Math.floor(this.state.drawWidth / this.state.cellWidth) * 50 - 2;
        const preHeadIndex = this.index;
        if (this.props.demoShow) {
            this.transDemoData(pointCount, chartMaxDataLength);
        } else {
            this.transData(pointCount, chartMaxDataLength);
        }
        this.showLoading();
        this.showLeadOffTip(this.props.leadOff);
        this.showEcgWeakSignal(this.props.ecgWeakSignal);
        this.showOrderEndTime(this.props.data.order_end_time);
        if (this.minusCount > 3 * 60) {
            if (!this.state.signalWeak) {
                this.setState({
                    signalWeak: true
                })
            }
        } else {
            if (this.state.signalWeak) {
                this.setState({
                    signalWeak: false
                })
            }
        }
        const chartGridLineWidth = HomeStyle.chartGridLineWidth(this.props.homeStyleCode)
        ChartRenderCompat.drawChart2(this.lineCtx,
            this.state.drawWidth - chartGridLineWidth,
            this.state.drawHeight - chartGridLineWidth,
            this.props.mqttChannel,
            this.props.darkModule,
            this.props.homeStyleCode,
            this.state.cellWidth,
            this.chart.height,
            this.props.channelSetting.gain || this.props.gain,
            chartMaxDataLength,
            preHeadIndex,
            this.index,
            this.props.baseline,
            this.props.yGridCount,
            this.props.venueSetting,
            this.props.derivedLead);
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let update = false;
        if (this.state.signalWeak !== nextState.signalWeak) {
            update = true;
        }
        if (!update) {
            for (const key in nextProps) {
                if (nextProps.hasOwnProperty(key)) {
                    if (!_isFunction(nextProps[key])) {
                        if (nextProps[key] !== this.props[key]) {
                            update = true;
                            break;
                        }
                    }
                }
            }
        }
        return update;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.channelCount = nextProps.channelCount;
        this.minDelay = nextProps.minDelay;
        this.fps = nextProps.fps;
        let isDataChanged = -1;
        for (const key in nextProps) {
            if (nextProps.hasOwnProperty(key)) {
                if (!_isFunction(nextProps[key])) {
                    if (nextProps[key] !== this.props[key]) {
                        if (key === 'data' || key === 'leadOff') {
                            isDataChanged = 1;
                        } else {
                            isDataChanged = 2;
                            break;
                        }
                    }
                }
            }
        }
        if (isDataChanged > 0) {
            // 解决2列变3列时，心电图折线绘制超出未清除的问题,现在清除绘图区域缓冲数据，重新绘制
            if (isDataChanged === 2) {
                if (this.props.forceClearPlotData) {
                    data[this.props.mqttChannel] = [];
                }
            }
            this.doDrawGrid(nextProps);
        }
        this.showLeadOffTip(nextProps.leadOff);
        this.showEcgWeakSignal(nextProps.ecgWeakSignal);
        this.refreshCount = 0;
        this.showOrderEndTime(nextProps.data.order_end_time);
    }

    transDemoData(pointCount, chartMaxDataLength) {
        let channelPlotData = data[this.props.mqttChannel];
        if (!channelPlotData) {
            channelPlotData = [];
        }
        const demo = demoDataBuffer[this.props.mqttChannel];
        if (demo && demo.length) {
            for (let i = 0; i < pointCount; i++) {
                let item = demo.shift();
                if (item) {
                    if (channelPlotData.length < chartMaxDataLength) {
                        channelPlotData.push(item.data);
                        this.index = channelPlotData.length - 1;
                    } else {
                        channelPlotData.splice(this.index, 1, item.data);
                        this.index++;
                        if (this.index >= chartMaxDataLength) {
                            this.index = 0;
                        }
                    }
                }
            }
            data[this.props.mqttChannel] = channelPlotData;
        }
    }

    transData = (pointCount, chartMaxDataLength) => {
        let channelPlotData = data[this.props.mqttChannel];
        if (!channelPlotData) {
            channelPlotData = [];
            data[this.props.mqttChannel] = channelPlotData;
        }
        let pointPerAdd = pointCount;
        // let chartMaxDataLength = Math.floor(this.state.width/this.state.cellWidth)*50-2;//根据ECG容器宽度计算满容器点数
        // let chartMaxDataLength = Math.floor(this.state.width * COUNT_PER_PIXEL);
        let Min_Realtime_Delay_Point = this.minDelay * 250;
        let Max_Realtime_Delay_Point = (this.minDelay + 5) * 250; // 缓冲区上限为当前下限+5秒
        const channelDataBuffer = dataBuffer[this.props.mqttChannel];
        if (!enableDraw[this.props.mqttChannel]) {
            if (channelDataBuffer && channelDataBuffer.data && channelDataBuffer.data.length >= Min_Realtime_Delay_Point) {
                enableDraw[this.props.mqttChannel] = true;
            }
        }
        if (enableDraw[this.props.mqttChannel] && channelDataBuffer && channelDataBuffer.data) {
            if (channelDataBuffer.data.length > Max_Realtime_Delay_Point) {
                // 绘图性能低于MQTT通道数据接收速度，攒点太多没有绘制导致实时效果有2秒以上延迟，要么通过提高走速补上，要么废弃掉这些数据重绘，现在采用后者。
                // this.showNotice('同步到实时状态');
                // if (DEBUG) {
                //     if (DEBUG_allchannel || (!DEBUG_allchannel && this.props.mqttChannel.indexOf(DEBUG_channel_name) > 0)) {
                //         console.log(this.props.mqttChannel + '通道延迟过大重绘', channelData.data.length, new Date().getTime());
                //     }
                // }
                // 从头重绘心电图区域。提示信息：延迟过大，心电图重绘。
                enableDraw[this.props.mqttChannel] = false;
                dataBuffer[this.props.mqttChannel] = {};
                // bpm[this.props.mqttChannel] = null;
                data[this.props.mqttChannel] = [];
                this.minusCount = 0;
                return;
            }
            const temp = channelDataBuffer.data.length / Min_Realtime_Delay_Point;
            const adjust = (temp >= 1 ? Math.ceil(temp * 10) : Math.floor(temp * 10)) - 10;
            if (adjust >= 0 || channelDataBuffer.data.length < 200) {
                pointPerAdd += adjust;
            }
            pointPerAdd = Math.max(pointPerAdd, 2);
            pointPerAdd = Math.min(pointPerAdd, 25);
            if (pointPerAdd < pointCount) {
                this.minusCount++;
            } else {
                this.minusCount--;
            }
            this.minusCount = Math.max(this.minusCount, 0);
            // 通道无法接收到新的采样点，有可能是MQTT通道暂时阻塞(这种一般过一会儿会快速补偿回来，导致延迟过大重绘)，
            // 也可能是Web端网络差，导致无法接到足量的数据，这种会先触发频繁的缓冲区空重绘，当网络恢复后追加补偿前面的数据导致延迟过大重绘。
            // 也有可能是采集端蓝牙断开，导致手机端没有新发数据，Web端并无法识别是哪一种情况。
            if (channelDataBuffer.data.length < pointPerAdd) {
                // this.showNotice('网络传输延迟');
                // if (DEBUG) {
                //     if (DEBUG_allchannel || (!DEBUG_allchannel && this.props.mqttChannel.indexOf(DEBUG_channel_name) > 0)) {
                //         console.log(this.props.mqttChannel + '通道缓冲区空，停止往绘图区添加新的点，为防止后续波形失真进行重绘', new Date().getTime());
                //     }
                // }
                // 计数缓冲区满的次数
                bufferEmptyTimes++;

                enableDraw[this.props.mqttChannel] = false;
                dataBuffer[this.props.mqttChannel] = {};
                // bpm[this.props.mqttChannel] = null;
                data[this.props.mqttChannel] = [];
                this.minusCount = 0;
                return;
            }

            let bpm = undefined;
            let alarmEvent = undefined;
            for (let i = 0; i < pointPerAdd; i++) {
                let item = channelDataBuffer.data.shift();
                const value = item ? item.value : Number.NaN;
                // this.checker.checkBreak(value);
                if (channelPlotData.length < chartMaxDataLength) {
                    channelPlotData.push(value);
                    this.index = channelPlotData.length - 1;
                } else {
                    channelPlotData.splice(this.index, 1, value);
                    this.index++;
                    if (this.index >= chartMaxDataLength) {
                        this.index = 0;
                    }
                }
                if (channelDataBuffer.version === 0) {
                    if (item && item.bpm) {
                        bpm = item.bpm;
                    }
                } else if (channelDataBuffer.version === 1 || channelDataBuffer.version === 2 || channelDataBuffer.version === 3 || channelDataBuffer.version === 4) {
                    if (item && item.bpm) {
                        bpm = item.bpm;
                    }
                    if (item && item.alarmEvent) {
                        if (alarmEvent) {
                            alarmEvent = AbnormalCompat.compareAlarmEvent(alarmEvent, item.alarmEvent);
                        } else {
                            alarmEvent = item.alarmEvent;
                        }
                    }
                }
            }
            // data[this.props.mqttChannel] = channelPlotData;
            this.props.onBpmCallback && this.props.onBpmCallback(channelDataBuffer.version, bpm, alarmEvent);
        }
    };

    // showNotice(message) {
    //     let ele = document.getElementById(`ecg-notice-back-${this.props.mqttChannel}`);
    //     if (this.notice) {
    //         ele.style.display = 'flex';
    //         this.notice.innerHTML = message;
    //         setTimeout(() => {
    //             if (ele) {
    //                 ele.style.display = 'none';
    //             }
    //         }, 1000)
    //     }
    // }

    extraState = (mqtt_channel) => {
        const extraObj = extra[mqtt_channel];
        let error = false;
        if (extraObj) {
            const {finish, offline, bleState} = extraObj;
            if (finish === 0 || offline === 1 || offline === undefined || bleState === 0 || bleState === undefined) {
                error = true;
            }
        }
        return error;
    };

    showLoading = () => {
        const loading = document.getElementById(`ecg-loading-${this.props.mqttChannel}`);
        if (loading) {
            if (this.props.demoShow) {
                loading.style.display = 'none';
            } else {
                let error = this.extraState(this.props.data.mqtt_channel);
                let display = !enableDraw[this.props.mqttChannel] ? 'flex' : 'none';
                if (error) {
                    display = 'none';
                }
                if (loading.style.display !== display) {
                    loading.style.display = display;
                    if (display === 'flex') {
                        this.showLeadOffTip(false);
                        this.showEcgWeakSignal(false);
                    }
                }
            }
        }
    }

    showLeadOffTip = (leadOff) => {
        const noticeBack = document.getElementById(`ecg-notice-back-${this.props.mqttChannel}`);
        if (noticeBack) {
            this.showExceptionTip(noticeBack,leadOff)
        }
    }

    showEcgWeakSignal = (weakSignal) => {
        const weakSignalDiv = document.getElementById(`ecg-weakSignal-${this.props.mqttChannel}`);
        if (weakSignalDiv) {
            this.showExceptionTip(weakSignalDiv,weakSignal)
        }
    }

    exceptionExtra = () => this.extraState(this.props.data.mqtt_channel) || !enableDraw[this.props.mqttChannel];

    showExceptionTip = (expDiv,expResult) =>{
        let display;
        if (this.props.demoShow) {
            display = 'none';
        } else {
            if (this.exceptionExtra()) {
                display = 'none';
            } else {
                display = expResult ? 'flex' : 'none';
            }
        }
        if (expDiv.style.display !== display) {
            expDiv.style.display = display;
        }
    }

    showOrderEndTime = (order_end_time) => {
        if (this.refreshCount % (60 * 60 * 5) === 0 && order_end_time) {
            const orderEndTimeDiv = document.getElementById(`ecg-orderEndTime-${this.props.mqttChannel}`);
            if (orderEndTimeDiv) {
                const orderEndTime = moment(order_end_time).format('MM-DD HH:mm');
                const nowDate = moment(new Date()).format('yyyy-MM-DD HH:mm');
                // console.log(`showOrderEndTime()called: channedlId = ${this.props.mqttChannel},order_end_time = ${order_end_time},nowDate = ${nowDate}`)
                if (moment(order_end_time).isBefore(nowDate)) {
                    orderEndTimeDiv.style.display = 'flex';
                    orderEndTimeDiv.innerText = `已到达摘机时间：${orderEndTime}`
                } else {
                    orderEndTimeDiv.style.display = 'none';
                }
            }
            this.refreshCount = 0;
        }
        this.refreshCount++;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const chartGridLineWidth = HomeStyle.chartGridLineWidth(this.props.homeStyleCode)
        ChartRenderCompat.drawChart(this.lineCtx,
            this.state.drawWidth - chartGridLineWidth,
            this.state.drawHeight - chartGridLineWidth,
            this.props.mqttChannel,
            this.props.darkModule,
            this.props.homeStyleCode,
            this.state.cellWidth,
            this.chart.height,
            this.props.channelSetting.gain || this.props.gain,
            Math.floor(this.state.drawWidth / this.state.cellWidth) * 50 - 2,
            Math.max(this.index - 1, 0),
            this.props.baseline,
            this.props.yGridCount,
            this.props.venueSetting,
            this.props.derivedLead);
    }

    render() {
        return (
            <div style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                paddingTop: `${this.state.vPadding}px`,
                paddingBottom: `${this.state.vPadding}px`,
                paddingLeft: `${this.state.hPadding}px`
            }} ref={(ele) => {
                this.ele = ele
            }}>
                <div className='boack'>
                    <canvas id={`ecg-background-${this.props.mqttChannel}`}
                            ref={(r) => this.background = r}/>
                </div>
                <div className='boack'>
                    <canvas id={`ecg-line-${this.props.mqttChannel}`} ref={(r) => this.chart = r}/>
                </div>
                <div className='boack' id={`ecg-loading-${this.props.mqttChannel}`} style={{
                    justifyContent: 'center',
                    width: '100%',
                    height: this.state.drawHeight,
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Spinner venueSetting={this.props.venueSetting} homeStyleCode={this.props.homeStyleCode}/>
                </div>
                <div className='boack' id={`ecg-notice-back-${this.props.mqttChannel}`}
                     style={{
                         backgroundColor: HomeStyle.leadWireOffBackgroundColor(this.props.homeStyleCode, this.props.venueSetting),
                         justifyContent: 'center',
                         width: this.state.drawWidth,
                         height: this.state.drawHeight,
                         alignItems: 'center',
                         display: 'none'
                     }}>
                    <div id={`ecg-notice-${this.props.id}`} style={{
                        fontSize: '20px',
                        border: '1px',
                        borderRadius: '10px',
                        padding: '5px 10px',
                        backgroundColor: HomeStyle.leadWireOffTextBackgroundColor(this.props.homeStyleCode, this.props.venueSetting),
                        color: HomeStyle.leadWireOffTextColor(this.props.homeStyleCode, this.props.venueSetting)
                    }} ref={(r) => this.notice = r}>
                        导联线脱落
                    </div>
                </div>
                <div className='boack'
                     id={`ecg-set-back-${this.props.mqttChannel}`}
                     style={{
                         textAlign: 'right',
                         width: this.state.drawWidth,
                         height: this.state.drawHeight,
                         paddingTop: 5,
                         paddingRight: 25,
                         display: this.props.hasChannelSettingKey ? '' : 'none'
                     }}>
                    <SettingFilled
                        title={'您已对该通道部分信息单独设置，可点击右键进行修改。'}
                        style={{color: '#1890FF', fontSize: '16px'}}/>
                </div>
                <div className='boack'
                     id={`ecg-wifi-signalWeak-back-${this.props.mqttChannel}`}
                     style={{
                         textAlign: 'right',
                         width: this.state.drawWidth,
                         height: this.state.drawHeight,
                         paddingTop: 3,
                         paddingRight: 5,
                         display: !this.exceptionExtra() && this.state.signalWeak ? '' : 'none'
                     }}>
                    <img
                        src={signalIcon}
                        title={'该通道网络信号不畅，可能引起心电图波形失真。'}
                        style={{width: 20, height: 20}}
                        alt={'该通道网络信号不畅，可能引起心电图波形失真。'}/>
                </div>

                <div
                    id={`ecg-orderEndTime-${this.props.mqttChannel}`}
                    style={{
                        position: "absolute",
                        top: 15,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '10px',
                        color:'darkorange',
                        display: 'none'
                    }}>
                    摘机时间：{this.props.data.order_end_time}
                </div>

                <div
                    id={`ecg-weakSignal-${this.props.mqttChannel}`}
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '10px',
                        color:'yellow',
                        display: 'none'
                    }}>
                    低振幅
                </div>

            </div>
        )
    }
}

export default EcgChart;
