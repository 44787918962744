import React,{Component} from 'react';
import {Card, Table} from 'antd';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchUserHistoryList} from "./actions";
import FilterPanel from "./component/FilterPanel";
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';
import axios from '../../myAxios';
class HistoryRecord extends Component{
    constructor() {
        super(...arguments);
        this.columns=[{
            title:'用户姓名',
            dataIndex:'user_name',
            key:'user_name',

            sorter: (a, b) => a.user_name > b.user_name
        },{
            title:'住院号',
            dataIndex:'hospital_number',
            key:'hospital_number',
            sorter: (a, b) => a.hospital_number > b.hospital_number
        },{
            title:'床位号',
            dataIndex:'bed_number',
            key:'bed_number',

            sorter: (a, b) => a.bed_number > b.bed_number
        },{
            title:'开始时间',
            dataIndex:'start_time',
            key:'start_time',

            sorter: (a, b) => moment(a.start_time) - moment(b.start_time)
        },{
            title:'计划摘机时间',
            dataIndex:'end_time',
            key:'end_time',
            sorter: (a, b) => moment(a.end_time) - moment(b.end_time)
        },{
            title:'结束时间',
            dataIndex:'act_end_time',
            key:'act_end_time',
            sorter: (a, b) => moment(a.act_end_time) - moment(b.act_end_time)
        },{
            title:'总时长',
            dataIndex:'time_duration',
            key:'time_duration',

            // sorter: (a, b) => a.time_duration > b.time_duration
        },{
            title:'设备编号',
            dataIndex:'mach_sn',
            key:'mach_sn',

            sorter: (a, b) => a.mach_sn > b.mach_sn
        },{
            title:'机构',
            dataIndex:'company_name',
            key:'company_name',

            sorter: (a, b) => a.company_name > b.company_name
        },{
            title:'科室',
            dataIndex:'dept_name',
            key:'dept_name',

            sorter: (a, b) => a.dept_name > b.dept_name
        },{
            title:'申请医生',
            dataIndex:'prescriber_name',
            key:'prescriber_name',
            sorter: (a, b) => a.prescriber_name > b.prescriber_name
        }]
        this.onRefresh = this.onRefresh.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
    }
    componentDidMount(){
        this.props.queryData(this.props.filter,this.props.pagination,this.props.sort)

    }
    onRefresh(){
        this.props.queryData(this.props.filter,this.props.pagination,this.props.sort);
    }
    handleTableChange(pagination, filters, sorter){
        let sort = {...this.props.sort};
        if (sorter.field) sort.field = sorter.field;
        if (sorter.order) sort.order = (sorter.order === 'descend') ? 'desc' : 'asc';
        this.props.queryData(this.props.filter, pagination, sort);
    }


    render(){
        let pagination = {
            ...this.props.pagination,
            showSizeChanger: true,
            hideOnSinglePage: true,
            showQuickJumper:true,
            showTotal: (total, range) => `${total} 条记录中的第 ${range[0]} 至 ${range[1]} 条`
        };
        console.log(this.props.list)
        if (this.props.list !== null){
            this.props.list.forEach(v =>{
               if (v.duration_seconds !== null){
                   // let secondDuration = Math.trunc((moment(v.end_time) - moment(v.start_time)) / 1000);
                   let secondDuration = v.duration_seconds;
                   let second = secondDuration % 60;
                   let hour = Math.trunc(secondDuration / 60 / 60)
                   let minute = Math.trunc(secondDuration / 60) - hour * 60;
                   let durationText = '';
                   if (hour === 0){
                       if (minute === 0){
                           if (second !== 0){
                               durationText += `${second}秒`
                           }
                       }else {
                           durationText += `${minute}分钟`
                           if (second !== 0){
                               durationText += `${second}秒`
                           }
                       }
                   }else {
                       durationText += `${hour}小时`
                       if (minute === 0){
                           if (second !== 0){
                               durationText += `${second}秒`
                           }
                       }else {
                           durationText += `${minute}分钟`
                           if (second !== 0){
                               durationText += `${second}秒`
                           }
                       }
                   }
                   v.time_duration = durationText
               }
            });
        }
        return(
            <Card>
                <FilterPanel
                    onRefresh={this.onRefresh}
                />
                <Table
                    scroll={{y:600}}
                    bordered={false}
                    pagination={pagination}
                    columns={this.columns}
                    dataSource={this.props.list}
                    loading={this.props.loading}
                    onChange={this.handleTableChange}
                    rowKey={record=>record.rec_id}
                />
            </Card>
        )
    }
}
const mapStateToProps =(state,props)=>{
    let list = [];
    const data = state.main.history;
    list = data.list.map(item=>({...item,key:item.rec_id}));
    return {
        list,
        filter:data.filters,
        loading:data.loading,
        pagination:data.pagination,
        sort:data.sort,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        queryData:(filter,pagination,sort)=>{
            dispatch(fetchUserHistoryList(filter,pagination,sort))
        }
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HistoryRecord))
