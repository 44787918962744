import {FETCH_USER_RECORD_LIST_STARTED,FETCH_USER_RECORD_LIST_SUCCESS,FETCH_USER_RECORD_LIST_FAILURE} from "./actionTypes";


export default (state={loading:false,list : [],total:9},action)=>{
    switch (action.type) {
        case FETCH_USER_RECORD_LIST_STARTED:
            return{
                ...state,
                loading:true
            };
        case FETCH_USER_RECORD_LIST_SUCCESS:
            return{
                ...state,
                loading:false,
                list:action.payload.result,
                total:action.payload.total,
                page:action.payload.page,
                totalCount:action.payload.totalCount
            };
        case FETCH_USER_RECORD_LIST_FAILURE:
            return{
                ...state,
                loading:false
            };
        default:
            return state
    }
}
