import React,{Component} from "react";
import ChannelItemBgSvg from "../svgElement/ChannelItemBgSvg";
import { withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

/**
 * @name: ElementTest
 * @author: Seeker
 * @date: 2023-12-18 13:14
 * @description：ElementTest
 * @update: 2023-12-18 13:14
 */

class ElementTest extends Component{

    constructor() {
        super(...arguments);
    }

    render() {
        return(
            <div style={{backgroundColor:'#162246',width:'100em',height:'100em',padding:'2em'}}>
                <ChannelItemBgSvg
                    width={900}
                    height={300}
                    titleHeight={60}
                />
            </div>
        )
    }
}

const mapStateToProps =(state,props)=>{
    return {}
}

const mapDispatchToProps = (dispatch) =>{
    return {}
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ElementTest))
