/**
 * @name: MQTTClientFactory
 * @author: Seeker
 * @date: 2022-03-22 09:02
 * @description：MQTTClientFactory
 * @update: 2022-03-22 09:02
 */
import AsyncMqttClient from "./AsyncMqttClient";
import SyncMqttClient from "./SyncMqttClient";

const impl = (refreshChannle) =>{
    if (typeof (Worker) !== 'undefined'){
        return new AsyncMqttClient(refreshChannle);
    }
    return new SyncMqttClient(refreshChannle);
};

export default {
    impl
}
