import settingStorage from "../../main/util/setting-storage";
import JSEncrypt from 'jsencrypt';
import axios from "axios";

/**
 * @name: autoLogin
 * @author: Seeker
 * @date: 2021-12-09 14:56
 * @description：autoLogin
 * @update: 2021-12-09 14:56
 */

const autoLogin = function (){
    const {name,pwd,state} = settingStorage.getAccount();
    if (name && pwd && (state === undefined || state === '1')){
        return axios.get('/loginRdsPk.action').then((res) => {
            if (res.data && res.data.hasOwnProperty('rdspk')){
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(res.data.rdspk);
                const enUsername = encrypt.encrypt(name);
                const enPassword = encrypt.encrypt(pwd);
                if (enPassword < 20) {
                    return Promise.reject("自动登录密码信息加密有误!");
                }else {
                    let dataBody = new FormData();
                    dataBody.append('userNo',enUsername);
                    dataBody.append('pwd',enPassword);
                    return axios({url: '/loginJson.action',data: dataBody, maxRedirects: 0, method: 'post'})
                        .then(res=>{
                            const {actionErrors} = res.data;
                            if (actionErrors.length){
                                return Promise.reject("自动登录失败!");
                            }else {
                                return Promise.resolve("自动登录成功!");
                            }
                        });
                }
            }else {
                return Promise.reject("自动登录未获得公共秘钥信息!");
            }
        });
    }else {
        return Promise.reject("自动登录未获得账号信息!");
    }
};

export default autoLogin
