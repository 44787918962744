/**
 * @name: AbnormalCompat
 * @author: Seeker
 * @date: 2021-11-17 15:57
 * @description：AbnormalCompat
 * @update: 2021-11-17 15:57
 */
import Moment from "moment";

const AlarmEventCode = {
    VFIB:202, // 室颤
    Vgeminy:1001,//室性联律
    Vbigeminy:100, //室性二联律
    Vtrigeminy:101,//室性三联律
    Vcouple:102,//室性成对早搏
    VTachycardia:103,//室性心动过速
    AQVTachycardia:1031,//加速性室性心律
    Vtriplet:104,//室性三连发

    AFIB: 200,// 房颤
    APCgeminy:1501,// 房早联律
    APCbigeminy:150,//房早二联律
    APCtrigeminy:151,//房早三联律
    APCcouple:152, //房性成对早搏
    APCTachycardia:153,// 房性心动过速
    APCtriplet:154,//房性三连发

    R_RlongPulse:42, //长间歇

    Bradycardia:50,// 心动过缓
    Tachycardia:51,//心动过速

    NOISE: -1, // 噪音
    LEAD_OFF: -2 , // 导联线脱落
    WEAK_SIGNAL: -940, // 低振幅
};

const AbnormalCompat = {
    getAlarmEventPriority:function (event){
        const code = Number.parseInt(event.typeCode);
        if (code === AlarmEventCode.VFIB){
            return 1;
        }
        if (code === AlarmEventCode.R_RlongPulse){
            return 2;
        }
        if (code === AlarmEventCode.AFIB){
            return 3;
        }
        return 4;
    },
    compareAlarmEvent:function (event1,event2){
        const priority1 = this.getAlarmEventPriority(event1);
        const priority2 = this.getAlarmEventPriority(event2);
        if (priority1 < priority2){
            return event1;
        }
        return event2;
    },
    is_v0: function(avgBeat,hrRanger){
        return avgBeat
            && (avgBeat.type === 'V' || avgBeat.type === 'S' || avgBeat.type === 'Pause' || avgBeat.hr < hrRanger.min || avgBeat.hr > hrRanger.max);
    },
    is_v1: function(alarmEvent,avgBeat,hrRanger,selectedEvents){
        if (alarmEvent){
            const typeCode = Number.parseInt(alarmEvent.typeCode);
            if (typeCode !== -1
                && typeCode !== AlarmEventCode.Bradycardia
                && typeCode !== AlarmEventCode.Tachycardia
                && this.containAbnormal(alarmEvent,selectedEvents)){
                return {...alarmEvent};
            }
        }
        if (avgBeat && avgBeat.hr > 0){
            if (avgBeat.hr < hrRanger.min){
                return {
                    time: new Date().getTime(),
                    typeCode:AlarmEventCode.Bradycardia
                };
            }else if (avgBeat.hr > hrRanger.max){
                return {
                    time: new Date().getTime(),
                    typeCode:AlarmEventCode.Tachycardia
                };
            }
        }
    },
    isBpmAbnormal: function (abnormalEvent){
        if (abnormalEvent){
            const code = Number.parseInt(abnormalEvent.typeCode);
            return code === AlarmEventCode.Bradycardia || code === AlarmEventCode.Tachycardia;
        }
        return false;
    },
    getAbnTypeDes_v0:function(abnormalEvent,hrRanger){
        const {hr, type} = abnormalEvent;
        if (type === 'V') {
            return `室早  ${abnormalEvent.date}`;
        } else if (type === 'S') {
            return `室上早  ${abnormalEvent.date}`;
        } else if (type === 'Pause') {
            return `长间歇  ${abnormalEvent.date}`;
        } else if (hr < hrRanger.min && hr > 0) {
            return `心率过慢  ${abnormalEvent.date}`;
        } else if (hr > hrRanger.max && hr > 0) {
            return `心率过快  ${abnormalEvent.date}`
        }
    },

    containAbnormal:function (abnormalEvent,selectedEvents){
        if (!abnormalEvent){
            return false;
        }
        const code =  Number.parseInt(abnormalEvent.typeCode);
        const selected = selectedEvents.find(v => v === code);
        return !!selected;

    },

    typeCode: function (typeCode,hr){
        let code = Number.parseInt(typeCode);
        if (code === AlarmEventCode.Vbigeminy || code === AlarmEventCode.Vtrigeminy){
            code = AlarmEventCode.Vgeminy;
        }else if (code === AlarmEventCode.APCbigeminy || code === AlarmEventCode.APCtrigeminy){
            code = AlarmEventCode.APCgeminy;
        }else if (code === AlarmEventCode.VTachycardia){
            if(hr < 100){
                code = AlarmEventCode.AQVTachycardia;
            }
        }
        return code;
    },

    getAbnTypeDes_v1:function (abnormalEvent,selectedEvents,hrRanger){
        // const {hr, typeCode} = abnormalEvent;
        // let code = Number.parseInt(typeCode);
        // if (code === AlarmEventCode.Vbigeminy || code === AlarmEventCode.Vtrigeminy){
        //     code = AlarmEventCode.Vgeminy;
        // }else if (code === AlarmEventCode.APCbigeminy || code === AlarmEventCode.APCtrigeminy){
        //     code = AlarmEventCode.APCgeminy;
        // }
        let code = this.typeCode(abnormalEvent.typeCode);
        const selected = selectedEvents.find(v => v === code);
        if (!selected){
            console.log(`getAbnTypeDes_v1()called:code = ${code} 未找到！`)
            return undefined;
        }
        let date = Moment(abnormalEvent.time).format('HH:mm:ss');
        if (date){
            date  = ' '+date
        }else {
            date = ''
        }
        switch (code){
            case AlarmEventCode.VFIB:
                return `室颤${date}`;
            case AlarmEventCode.Vbigeminy:
            case AlarmEventCode.Vtrigeminy:
            case AlarmEventCode.Vgeminy:
                return `室性联律${date}`;
            case AlarmEventCode.Vcouple:
                return `室性成对${date}`;
            case AlarmEventCode.VTachycardia:
                return `室速${date}`;
            case AlarmEventCode.AFIB:
                return `房颤${date}`;
            case AlarmEventCode.APCbigeminy:
            case AlarmEventCode.APCtrigeminy:
            case AlarmEventCode.APCgeminy:
                return `房性联律${date}`;
            case AlarmEventCode.APCTachycardia:
                return `房速${date}`;
            case AlarmEventCode.APCcouple:
                return `房性成对${date}`;
            case AlarmEventCode.R_RlongPulse:
                return `长间歇${date}`;
            case AlarmEventCode.Bradycardia:
                return `心动过缓${date}`;
            case AlarmEventCode.Tachycardia:
                return `心动过速${date}`;
            case AlarmEventCode.AQVTachycardia:
                return `加速性室性心律${date}`;
            default:
                console.log(`getAbnTypeDes_v1()called:code = ${code} 未匹配到，进入default分支！`)
                return undefined;
        }
    },
    getUselessTypeDes: function (abnormalEvent){
        let code = Number.parseInt(abnormalEvent.typeCode);
        switch (code){
            case AlarmEventCode.NOISE:
                return '心电信号无效';
            case AlarmEventCode.LEAD_OFF:
                return '导联线脱落';
            case AlarmEventCode.WEAK_SIGNAL:
                return '心电幅值低';
            default:return undefined;
        }
    },
    getAbnTypeDes:function (version,abnormalEvent,hrRanger,selectedEvents){
        if (!abnormalEvent) {
            return undefined;
        }
        if (version === 0){
            return this.getAbnTypeDes_v0(abnormalEvent,hrRanger);
        }else if (version === 1){
            return this.getAbnTypeDes_v1(abnormalEvent,selectedEvents,hrRanger);
        }else if (version === 2 || version === 3 || version === 4){
            if (this.isUseless(abnormalEvent)){
                // return this.getUselessTypeDes(abnormalEvent);
                return undefined;
            }
            return this.getAbnTypeDes_v1(abnormalEvent,selectedEvents,hrRanger);
        }
    },
    needFlicker_v0:function (abnormalEvent,emergencyAlarmEvent,minDelay){
        if (!abnormalEvent || !this.isEmergencyEvent(abnormalEvent,emergencyAlarmEvent)){
            return false;
        }
        const now = new Date().getTime();
        const {time,showTime} = abnormalEvent;
        if (showTime){
            return now - showTime <= 2500;
        }

        return now - time - minDelay*1000 <= 2500;
    },
    isNoise: function (event){
        if (!event){
            return false;
        }
        return Number.parseInt(event.typeCode) === AlarmEventCode.NOISE;
    },
    isLeadOff: function (event){
        if (!event){
            return false;
        }
        return Number.parseInt(event.typeCode) === AlarmEventCode.LEAD_OFF;
    },
    isWeakSignal: function (event){
        if (!event){
            return false;
        }
        return Number.parseInt(event.typeCode) === AlarmEventCode.WEAK_SIGNAL;
    },
    isUselessExcludeNoise:function (event){
        if (!event){
            return false;
        }
        let code = Number.parseInt(event.typeCode);
        return code === AlarmEventCode.LEAD_OFF || code === AlarmEventCode.WEAK_SIGNAL;
    },
    isUseless: function (event){
        if (!event){
            return false;
        }
        let code = Number.parseInt(event.typeCode);
        return code === AlarmEventCode.LEAD_OFF || code === AlarmEventCode.WEAK_SIGNAL || code === AlarmEventCode.NOISE;
    },

    isEmergencyEvent:function (event,emergencyAlarmEvent){
        return this.containAbnormal(event,emergencyAlarmEvent);
    },

    isNonEmergencyEvent:function (event,nonEmergencyAlarmEvent){
        return this.containAbnormal(event,nonEmergencyAlarmEvent);
    },

    abnInfoBackColor:function (event,emergencyAlarmEvent){
        if (this.isUseless(event)){
            return 'grey';
        }
        if (this.isEmergencyEvent(event,emergencyAlarmEvent)){
            return '#FF4D4F';
        }
        return '#FF8000';
    },

};

export {AlarmEventCode}

export default AbnormalCompat
