import React from 'react';
import {Card, Spin} from 'antd';

export default (props) => {
    return (
        <Spin spinning={true} size='large'>
            <Card style={{minHeight: '100vh'}}>
            </Card>
        </Spin>
    )
};
