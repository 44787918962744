/**
 * @name: is-array
 * @author: Seeker
 * @date: 2021-05-23 16:33
 * @description：is-array
 * @update: 2021-05-23 16:33
 */
import isType from './is-type';

export default (value)=> {
    return Array.isArray ?
        Array.isArray(value) :
        isType(value, 'Array');
}
