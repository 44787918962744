import {
    FETCH_MQTT_CHANNEL_LIST_STARTED,
    FETCH_MQTT_CHANNEL_LIST_SUCCESS,
    FETCH_MQTT_CHANNEL_LIST_FAILURE
} from "./actionTypes";
import axios from "../../myAxios";
import qs from "qs";

export const fetchMqttChannelListStarted = () =>({
    type:FETCH_MQTT_CHANNEL_LIST_STARTED
})
export const fetchMqttChannelListSuccess = (result,total) =>({
    type:FETCH_MQTT_CHANNEL_LIST_SUCCESS,
    payload:{
        result,
        total,
    }
});
export const fetchMqttChannelListFailure = (err)=>({
    type:FETCH_MQTT_CHANNEL_LIST_FAILURE,
    error:err
});
export const fetchMqttChannelList = () =>{
    return(dispatch)=>{
        dispatch(fetchMqttChannelListStarted());
        return axios.post('/cust/rt/rtUserRecordListJson.action',qs.stringify({
            page:1,
            rows:10000,
            sort: 'rec_id',
            order: 'asc'
        })).then(res=>{
            const {actionErrors} = res.data;
            if(actionErrors.length){
                dispatch(fetchMqttChannelListFailure(actionErrors[0]||'操作失败'))
            }else{
                dispatch(fetchMqttChannelListSuccess(res.data.rows,res.data.total))
            }
        }).catch(err =>{
            dispatch(fetchMqttChannelListFailure(err))
        })
    }
}
