import React, {Component} from 'react';
import {Select, Switch, Button, TreeSelect, Form, Row, Col, Slider, Drawer, Typography, message} from 'antd';
import Text from "antd/es/typography/Text";
import ViewAdapt2 from "./ViewAdapt2";
import ConstantSetting from "./ConstantSetting";
import {DoubleRightOutlined} from "@ant-design/icons";
import AbnormalEventSetting from "./AbnormalEventSetting";
import publish from "../../../../publish";
import isBeta from "../../../util/is-beta"
import ModalFactory from "../../../../useContextMenu/modal/modalFactory";
import {baseUrl, processBeta} from "../../../../UrlConfig";
import LeadCompat from "../../../../leadCompat";

const Option = Select.Option;
const FormItem = Form.Item;

export default class BasicSettingWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childrenDrawer: false,
            venueModalState:false,
            viewMqttConnectState:false,
            ...props
        };
    }

    showChildrenDrawer = () => {
        this.setState({
            childrenDrawer: true
        });
    };

    hideChildrenDrawer = () => {
        this.setState({
            childrenDrawer: false
        });
    };

    showVenueModalState = () => {
        this.setState({
            venueModalState: true
        });
    };

    hideVenueModalState = () => {
        this.setState({
            venueModalState: false
        });
    };

    showViewMqttConnectState = () => {
        this.setState({
            viewMqttConnectState: true
        });
    };

    hideViewMqttConnectState = () => {
        this.setState({
            viewMqttConnectState: false
        });
    };

    handleSubmit = (values) => {
        this.props.setParams(ConstantSetting.filter(values));
        this.props.onOK();
    }

    render() {

        const sizeType = ConstantSetting.sizeType(this.props.clientWidth);
        const labelFontSize = ConstantSetting.labelFontSize(this.props.clientWidth);
        const isVenueMode = (this.props.venueSetting && this.props.venueSetting.venueOpenState) || (this.props.homeStyleCode === 4);

        return (
            <Form.Provider onFormFinish={(name, {values, forms}) => {
                if (name === 'basicForm') {
                    let hrRanger = values.hrRanger;
                    this.handleSubmit({
                        ...this.state,
                        ...values,
                        hrRanger: {
                            min: hrRanger[0],
                            max: hrRanger[1]
                        },
                        deptName: ConstantSetting.formatDeptName(this.props.mngDept, values.deptId)
                    })
                } else if (name === 'abnEventSettingForm') {
                    this.setState({
                        ...values
                    })
                }
            }}>

                <Form
                    name={'basicForm'}
                    initialValues={{
                        ...this.state,
                        hrRanger: [this.props.hrRanger.min, this.props.hrRanger.max],
                    }}>
                    <Row>
                        {/*<Col span={12} className={'setting-row-size2'} style={{visibility:'hidden'}}>*/}
                        {/*    <FormItem*/}
                        {/*        {...ConstantSetting.formItemLayout1}*/}
                        {/*        label={ConstantSetting.label(labelFontSize, '纯黑背景模式')}*/}
                        {/*        name={'darkModule'}*/}
                        {/*        valuePropName={'checked'}*/}
                        {/*    >*/}
                        {/*        <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>*/}
                        {/*    </FormItem>*/}
                        {/*</Col>*/}

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.label(labelFontSize, '主页风格样式')}
                                name={'homeStyleCode'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={1} value={1}>黑色主题</Option>
                                    <Option className={'setting-select-size'} key={2} value={2}>灰色主题</Option>
                                    <Option className={'setting-select-size'} key={3} value={3}>蓝色主题</Option>
                                    <Option className={'setting-select-size'} key={4} value={4}>绿色主题</Option>
                                    <Option className={'setting-select-size'} key={5} value={5}>白色主题</Option>
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.formItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '显示每秒分割线')}
                                name={'ratioRuleShow'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>

                        <Col span={12} className={'setting-row-size2'} style={{visibility:(isBeta() || isVenueMode)?'visible':'hidden'}}>
                            <FormItem
                                {...ConstantSetting.formItemLayout1}
                                label={ConstantSetting.label(labelFontSize, '显示呼吸率')}
                                name={'edrState'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.formItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '床号左侧独立显示')}
                                name={'bedShowLeft'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.formItemLayout1}
                                label={ConstantSetting.label(labelFontSize, '显示完整姓名')}
                                name={'nameComplete'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>
                        </Col>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.formItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '显示科室名称')}
                                name={'departmentShow'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className={'setting-row-size2-slider'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={'姓名文字大小'}
                                name={'nameTextFontSize'}
                            >
                                <Slider
                                    min={ConstantSetting.TextSize.min}
                                    max={ConstantSetting.TextSize.max}
                                    step={1}
                                    marks={ConstantSetting.createTextFontSizeMarks(this.props.nameTextFontSize)}
                                    tipFormatter={(value) => {
                                        return <Text style={{fontSize: `${value}px`}}>{value}</Text>
                                    }}
                                />
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={24} className={'setting-row-size2-slider'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={(isBeta() || isVenueMode)?'心率/呼吸文字大小':'心率文字大小'}
                                name={'bpmTextFontSize'}
                            >
                                <Slider
                                    min={ConstantSetting.TextSize.min}
                                    max={ConstantSetting.TextSize.max}
                                    step={1}
                                    marks={ConstantSetting.createTextFontSizeMarks(this.props.bpmTextFontSize)}
                                    tipFormatter={(value) => {
                                        return <Text style={{fontSize: `${value}px`}}>{value}</Text>
                                    }}
                                />
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={24} className={'setting-row-size2-slider'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={'床号文字大小'}
                                name={'bedTextFontSize'}
                            >
                                <Slider
                                    min={ConstantSetting.TextSize.min}
                                    max={ConstantSetting.TextSize.max}
                                    step={1}
                                    marks={ConstantSetting.createTextFontSizeMarks(this.props.bedTextFontSize)}
                                    tipFormatter={(value) => {
                                        return <Text style={{fontSize: `${value}px`}}>{value}</Text>
                                    }}
                                />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className={'setting-row-size2-slider'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={ConstantSetting.labelPopover(labelFontSize, '正常心率区间', '判断是否出现了心动过缓和心动过速的依据')}
                                name={'hrRanger'}
                            >
                                <Slider
                                    range={true}
                                    min={ViewAdapt2.BPM_LIMIT.min}
                                    max={ViewAdapt2.BPM_LIMIT.max}
                                    marks={ConstantSetting.sliderMarks(this.props.hrRanger)}
                                />
                            </FormItem>
                        </Col>

                    </Row>


                    <Row>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.label(labelFontSize, '床号文字长度')}
                                name={'bedNumLength'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={2} value={2}>2个</Option>
                                    <Option className={'setting-select-size'} key={3} value={3}>3个</Option>
                                    <Option className={'setting-select-size'} key={4} value={4}>4个</Option>
                                    <Option className={'setting-select-size'} key={5} value={5}>5个</Option>
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '电量显示规则')}
                                name={'batteryShowRule'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'always_show'}
                                            value={0}>总是显示</Option>
                                    <Option className={'setting-select-size'} key={'never_show'} value={1}>从不显示</Option>
                                    <Option className={'setting-select-size'} key={'abn_no_show'}
                                            value={2}>有异常时不显示</Option>
                                </Select>
                            </FormItem>
                        </Col>


                    </Row>

                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.label(labelFontSize, '通道显示数量')}
                                name={'colCount'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={51} value={51}>5个 (5 x 1)</Option>
                                    <Option className={'setting-select-size'} key={32} value={32}>6个 (3 x 2)</Option>
                                    <Option className={'setting-select-size'} key={33} value={33}>9个 (3 x 3)</Option>
                                    <Option className={'setting-select-size'} key={2} value={2}>10个 (5 x 2)</Option>
                                    <Option className={'setting-select-size'} key={4} value={4}>12个 (6 x 2)</Option>
                                    <Option className={'setting-select-size'} key={43} value={43}>12个 (4 x 3)</Option>
                                    <Option className={'setting-select-size'} key={3} value={3}>15个 (5 x 3)</Option>
                                    <Option className={'setting-select-size'} key={44} value={44}>16个 (4 x 4)</Option>
                                    <Option className={'setting-select-size'} key={5} value={5}>18个 (6 x 3)</Option>
                                    <Option className={'setting-select-size'} key={54} value={54}>20个 (5 x 4)</Option>
                                    <Option className={'setting-select-size'} key={64} value={64}>24个 (6 x 4)</Option>
                                    {
                                        isBeta()?null:<Option className={'setting-select-size'} key={11} value={11}>通道检测</Option>
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '通道显示排序')}
                                name={'sortWith'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'defaultNet'} value={0}>默认</Option>
                                    <Option className={'setting-select-size'} key={'bedNum'} value={1}>床位号</Option>
                                    <Option className={'setting-select-size'} key={'name'} value={2}>患者姓名</Option>
                                    <Option className={'setting-select-size'} key={'dptName'} value={3}>科室名称</Option>
                                </Select>
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.labelPopover(labelFontSize, '数据显示延迟', '遥测通道连接之后心电图延时多少秒绘制显示')}
                                name={'minDelay'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'second1'} value={1}>1秒</Option>
                                    <Option className={'setting-select-size'} key={'second3'} value={3}>3秒</Option>
                                    <Option className={'setting-select-size'} key={'second5'} value={5}>5秒</Option>
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                className={'setting-row-size2'}
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '自动翻页周期')}
                                name={'pagerFlipDuration'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'close'} value={-1}>关闭</Option>
                                    <Option className={'setting-select-size'} key={'30'} value={30}>30秒</Option>
                                    <Option className={'setting-select-size'} key={'60'} value={60}>1分钟</Option>
                                    <Option className={'setting-select-size'} key={'180'} value={180}>3分钟</Option>
                                    <Option className={'setting-select-size'} key={'300'} value={300}>5分钟</Option>
                                    <Option className={'setting-select-size'} key={'600'} value={600}>10分钟</Option>
                                </Select>
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.label(labelFontSize, '多导联通道选择')}
                                name={'derivedLead'}
                            >
                                <Select
                                    tagRender={ConstantSetting.tagRender2}
                                    size={sizeType}
                                    showSearch={false}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    mode="multiple"
                                    placeholder='请选择多导联通道'
                                    onChange={(value) =>{
                                        if (value.length > 2){
                                            value.splice(0,1);
                                            message.warn('最多可选择2个通道')
                                        }
                                    }}
                                >
                                    {LeadCompat.derivedLead.map(item => (
                                        <Select.Option key={item.key} value={item.value}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '增益调整')}
                                name={'gain'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'3'} value={3}>5mm/mv</Option>
                                    <Option className={'setting-select-size'} key={'0'} value={0}>10mm/mv</Option>
                                    <Option className={'setting-select-size'} key={'1'} value={1}>20mm/mv</Option>
                                    <Option className={'setting-select-size'} key={'2'} value={2}>30mm/mv</Option>
                                </Select>
                            </FormItem>
                        </Col>

                    </Row>


                    <Row>
                        <Col span={24} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={ConstantSetting.label(labelFontSize, '科室信息选择')}
                                name={'deptId'}>
                                <TreeSelect
                                    tagRender={ConstantSetting.treeTagRender}
                                    maxTagCount={'responsive'}
                                    size={sizeType}
                                    showSearch
                                    treeData={ConstantSetting.treeData(this.props.mngDept)}
                                    allowClear
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    multiple
                                    // onChange={this.handleDeptChange}
                                    treeCheckable
                                    treeNodeFilterProp={'title'}
                                    placeholder='请选择科室'
                                />
                            </FormItem>
                        </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*    <Col span={24} className={'setting-row-size2'}>*/}
                    {/*        <FormItem*/}
                    {/*            {...ConstantSetting.selectFormItemLayout}*/}
                    {/*            label={ConstantSetting.label(labelFontSize, '多导联通道选择')}*/}
                    {/*            name={'derivedLead'}>*/}

                    {/*            <Select*/}
                    {/*                tagRender={ConstantSetting.tagRender2}*/}
                    {/*                size={sizeType}*/}
                    {/*                showSearch={false}*/}
                    {/*                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}*/}
                    {/*                mode="multiple"*/}
                    {/*                placeholder='请选择多导联通道'*/}
                    {/*                onChange={(value) =>{*/}
                    {/*                    if (value.length > 2){*/}
                    {/*                        value.splice(0,1);*/}
                    {/*                        message.warn('最多可选择2个通道')*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                {LeadCompat.derivedLead.map(item => (*/}
                    {/*                    <Select.Option key={item.key} value={item.value}>*/}
                    {/*                        {item.title}*/}
                    {/*                    </Select.Option>*/}
                    {/*                ))}*/}
                    {/*            </Select>*/}
                    {/*        </FormItem>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}


                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2_left}
                                label={ConstantSetting.label(labelFontSize, '异常提示事件')}>
                                <Button onClick={this.showChildrenDrawer} style={{color: '#FF8000'}}>
                                    前往设置 <DoubleRightOutlined/>
                                </Button>
                            </FormItem>

                        </Col>


                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '遥测历史记录')}>
                                <Button onClick={()=>{
                                    window.open(`${baseUrl}/admin/main/history/record`,'historyRecord')
                                }} style={{color: '#40A9FF'}}>
                                    前往查看 <DoubleRightOutlined/>
                                </Button>
                            </FormItem>

                        </Col>

                    </Row>

                    <Row>
                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout}
                                label={ConstantSetting.label(labelFontSize, '当前版本')}>
                                <Text style={{color: 'white',cursor: 'pointer',userSelect: 'none'}}
                                //       onDoubleClick={() =>{
                                //     this.showVenueModalState();
                                // }}
                                //       onClick={()=>{
                                //           if (!processBeta){
                                //               window.open(`${baseUrl}/admin/main/elementTest`,'elementTest')
                                //           }
                                //       }}
                                >
                                    {publish.version_name}
                                </Text>
                            </FormItem>
                        </Col>

                        <Col span={12} className={'setting-row-size2'}>
                            <FormItem
                                {...ConstantSetting.selectFormItemLayout2}
                                label={ConstantSetting.label(labelFontSize, '遥测连接性评估')}>
                                <Button onClick={()=>{
                                    this.showViewMqttConnectState()
                                }} style={{color: '#FF4500'}}>
                                    开始评估 <DoubleRightOutlined/>
                                </Button>
                            </FormItem>

                        </Col>

                    </Row>

                    <FormItem {...ConstantSetting.tailFormItemLayout} style={{marginTop: '4%'}}>
                        <Button size={sizeType} style={{width: '20%', marginLeft: '20%'}}
                                onClick={this.props.onOK}>取消</Button>
                        <Button size={sizeType} style={{width: '20%', marginLeft: '30%'}} type='primary'
                                htmlType={'submit'}>确定</Button>
                    </FormItem>
                </Form>

                <Drawer
                    width={Math.max(this.props.clientWidth * 0.3,550)}
                    title={<Typography.Text>异常提示事件设置</Typography.Text>}
                    headerStyle={{padding: '10px 10px'}}
                    bodyStyle={{paddingTop: '10px'}}
                    closable={false}
                    destroyOnClose={true}
                    visible={this.state.childrenDrawer}>
                    <AbnormalEventSetting
                        hideChildrenDrawer={this.hideChildrenDrawer}
                        {...this.state}
                    />
                </Drawer>

                {
                    ModalFactory.venueModal(this.hideVenueModalState,this.state.venueModalState)
                }

                {
                    ModalFactory.viewMqttConnectStateModal(this.hideViewMqttConnectState,this.state.viewMqttConnectState)
                }

            </Form.Provider>
        )
    }
}
