import {Col, Row} from "antd";
import React from "react";

/**
 * @name: MobileFormItem
 * @author: Seeker
 * @date: 2022-04-26 13:23
 * @description：MobileFormItem
 * @update: 2022-04-26 13:23
 */

export default function MobileFormItem(props){

    return (
        <Row>
            <Col span={10} style={{height: '45px', paddingTop: '6px'}}>
                <div>{props.label}</div>
            </Col>
            <Col span={14} style={{height: '45px'}}>
                {props.item}
            </Col>

        </Row>
    );
}
