import React from 'react';
import {Modal} from 'antd';
import './modal.css';

export default (props) => {
    const {children, smallClose, ...allProps} = props;
    if (smallClose) {
        return (
            <Modal {...allProps} className='small-close-modal' wrapClassName="vertical-center-modal">
                {children}
            </Modal>
        );
    } else {
        return (
            <Modal {...allProps} wrapClassName="vertical-center-modal">
                {children}
            </Modal>
        );
    }
};
