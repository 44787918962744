import React from "react";
import {computeFontSize} from "../../../util/dom";
import Text from "antd/lib/typography/Text";
import ThemeColor from "../ThemeColor";
import {Row,Col, Divider} from "antd";

import {CloseCircleOutlined} from "@ant-design/icons";

/**
 * @name: bpmContainer
 * @author: Seeker
 * @date: 2021-08-30 16:42
 * @description：bpmContainer
 * @update: 2021-08-30 16:42
 */

const textStyle = {
    textAlign: 'center',
    fontWeight: 'bolder'
};

const BPM_LIMIT = {
    min:40,
    max:160
};

const widthRecord = {};

const getBpmContainerWidth = (screenWidth, isLarger, hasEdr, bpmTextFontSize,bedShowLeft) => {
    // const ratio = bedShowLeft? 0.5:0.382;
    // if (hasEdr) {
    //     return computeFontSize("心率：", bpmTextFontSize * ratio).width
    //         + computeFontSize("9", bpmTextFontSize).width * 3
    //         + 10;
    // }
    // return Math.max(computeFontSize("心率", bpmTextFontSize * ratio).width,
    //     computeFontSize("999", bpmTextFontSize).width);

    const key = `bpmContainerWidth-${bpmTextFontSize}-${hasEdr}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    let w;
    if (hasEdr) {
        w = computeFontSize("心率：", bpmTextFontSize * 0.382).width
            + computeFontSize("9", bpmTextFontSize).width * 3
            + 10;
    }else {
        w = computeFontSize("999", bpmTextFontSize).width;
    }
    widthRecord[key] = w;
    return w;
};

const getBedContainerWidth = (bedTextFontSize,bedNumLength) => {
    const key = `bedContainerWidth-${bedTextFontSize}-${bedNumLength}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    const w = computeFontSize("9", bedTextFontSize).width * (bedNumLength-1)
        +computeFontSize("+", bedTextFontSize).width;
    widthRecord[key] = w;
    return w;
};

const getChannelCardHeadHeight = (screenWidth, nameAndBedLarger) => {

    let domTitle = document.getElementById('card_title_container');
    if (domTitle){
       return  domTitle.clientHeight;
    }

    const key = `channelCardHeadHeight-${screenWidth}-${nameAndBedLarger}`;
    if (widthRecord.hasOwnProperty(key)){
        return widthRecord[key];
    }
    let w;
    if (nameAndBedLarger) {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            w = 40;
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            w = 45;
        } else {
            w = 55;
        }
    } else {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            w = 35;
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            w = 40;
        } else {
            w = 45;
        }
    }
    widthRecord[key] = w;
    return w;
};

const getChannelFooterHeight = (screenWidth) => {
    if (screenWidth >= 1 && screenWidth <= 1250) {
        return 50;
    } else if (screenWidth > 1250 && screenWidth <= 1700) {
        return 55;
    } else {
        return 10;
    }
};


const getChannelCardStyle = (darkModule, cardHeight) => {
    return {
        background: ThemeColor.backgroundColor(darkModule),
        border: '1px solid rgba(74, 85, 102, 0.8)',
        height: cardHeight
    };
};

const getChannelCardTitleContainerStyle = (screenWidth, nameAndBedLarger, rowCount) => {
    let paddingStyle;
    if (nameAndBedLarger) {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            paddingStyle = {
                padding: '2px 2px 2px 0px'
            };
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            paddingStyle = {
                padding: '3px 3px 3px 0px'
            };
        } else {
            paddingStyle = {
                padding: '4px 4px 4px 0px'
            };
        }
    } else {
        if (screenWidth >= 1 && screenWidth <= 1250) {
            paddingStyle = rowCount === 5 ? {padding: '5px 5px 5px 0px'} : {padding: '3px 3px 3px 0px'};
        } else if (screenWidth > 1250 && screenWidth <= 1700) {
            paddingStyle = rowCount === 5 ? {padding: '8px 8px 8px 0px'} : {padding: '4px 4px 4px 0px'};
        } else {
            paddingStyle = rowCount === 5 ? {padding: '10px 10px 10px 0px'} : {padding: '5px 5px 5px 0px'};
        }
    }
    return {
        ...paddingStyle,
        borderBottom: '1px solid rgba(74, 85, 102, 0.6)'
    };
};

const getChannelCardTitle = (contentObj, darkModule, item, abnInfo,
                             abnInfoBackColor,flicker, colCount, screenWidth,
                             nameAndBedLarger, onNameClick, nameTextFontSize,
                             departmentShow, bedShowLeft, bedTextFontSize,batteryShowRule,clearEmergencyAlarmEvent,avgBeat,hrRanger) => {
    const text_color = {color: ThemeColor.textColor(darkModule)};
    const hrColor = getBpmColor(avgBeat,hrRanger);
    const linkError = contentObj.finish || !contentObj.mqttConnect || !contentObj.bleConnect;
    const connectError = <Text className={contentObj.connect.className} style={text_color}> <img
        src={contentObj.connect.src} title={contentObj.connect.value} alt={contentObj.connect.value}
        className='channel-head-img-size' align="middle"/> {contentObj.connect.value}</Text>;

    const abn = abnInfo?<Text className={['channel-abnormal-size',flicker ? 'flicker' : ''].join(' ')}
                              onClick={clearEmergencyAlarmEvent}
                              style={{color: '#FFFFFF', backgroundColor: abnInfoBackColor,cursor:'default'}}>
                                        {abnInfo}  {clearEmergencyAlarmEvent?<CloseCircleOutlined/>:null}
                        </Text>:<></>;

    return <div>
        <Row justify="space-between" style={{paddingLeft:10,paddingRight:10}}>
            <Col>
                <Text>姓名：{contentObj.name.value}</Text>
            </Col>
            <Col>
                <Text>心电仪：{contentObj.machSN.value}</Text>
            </Col>
        </Row>
        <Row justify="space-between" style={{paddingLeft:10,paddingRight:10}}>
            <Col>
                <Text>手机电量：{contentObj.mobileBattery.value}</Text>
            </Col>
            <Col>
                <Text>心电仪电量：{contentObj.bleBattery.value}</Text>
            </Col>
        </Row>
        <Row justify="space-between" style={{paddingLeft:10,paddingRight:10}}>
            <Col>
                <Text style={{color:hrColor}}>心率：{getHr(avgBeat)}</Text>
            </Col>
            <Col>
                {linkError ? connectError : abn}
            </Col>
        </Row>
    </div>;
};

const getBpmColor = (avgBeat,hrRanger) => {
    let color = '#00FF00';
    const {min,max} = hrRanger;
    if (avgBeat) {
        const hr = avgBeat.hr;
        if (hr > 0) {
            if (hr >= min && hr <= max) {
                color = '#00FF00';
            } else if (hr < BPM_LIMIT.min || hr > BPM_LIMIT.max) {
                color = '#ff3838';
            }else {
                color = '#F8EA1D';
            }
            // else if ((hr >= 40 && hr < 60) || (hr >= 100 && hr < 160)) {
            //     color = '#F8EA1D';
            // }
        }
    }
    return color;
};

const getHr = (avgBeat) => {
    return avgBeat !== undefined && avgBeat && avgBeat.hr > 0 ? avgBeat.hr : '--'
};

const getEdr = (avgBeat) => {
    return avgBeat !== undefined && avgBeat && parseInt(avgBeat.edr) !== 0? avgBeat.edr : '--'
};

const getChannelBodyBpm = (darkModule, isLarge, hasEdr, avgBeat, height, bpmTextFontSize, contentObj, bedTextFontSize, bpmWidth, bedWidth, bedShowLeft,hrRanger) => {
    const hrColor = getBpmColor(avgBeat,hrRanger);
    if (hasEdr) {
        return (<div style={{
            height: height,
            width: bedShowLeft ? bpmWidth + bedWidth : bpmWidth,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap'
        }}>
            {
                bedShowLeft ? <>
                    <div style={{height: height, width: bedWidth}}>
                        <div className={'content-center'} style={{
                            height: '50%',
                            color: 'white', ...textStyle,
                            fontSize: `${bedTextFontSize * 0.382}px`
                        }}>床号
                        </div>
                        <div className={'content-center'} style={{
                            height: '50%',
                            color: 'white', ...textStyle,
                            fontSize: `${bedTextFontSize}px`,
                        }}>{contentObj.bed.value}</div>
                    </div>
                    <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: height * 0.8}}/>
                </> : null
            }
            <div style={{height: height, width: bpmWidth, padding: '0 5px'}}>
                <div style={{display: 'flex', height: '50%', whiteSpace: 'nowrap'}}>
                    <span className={'channel-bpm-edr'}
                          style={{color: hrColor, fontSize: `${bpmTextFontSize * 0.382}px`}}>心率：</span>
                    <div className={'channel-bpm-edr'} style={{color: hrColor, fontSize: `${bpmTextFontSize}px`}}>
                        {getHr(avgBeat)}</div>
                </div>

                <div style={{display: 'flex', height: '50%', whiteSpace: 'nowrap'}}>
                <span className={'channel-bpm-edr'}
                      style={{color: '#12e9ea', fontSize: `${bpmTextFontSize * 0.382}px`}}>呼吸：
                </span>
                    <span className={'channel-bpm-edr'}
                          style={{color: '#12e9ea', fontSize: `${bpmTextFontSize}px`}}>
                    {getEdr(avgBeat)}</span>
                </div>

            </div>

        </div>);
    }
    return (<div style={{
        height: height,
        width: bedShowLeft ? bpmWidth + bedWidth : bpmWidth,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    }}>
        {
            bedShowLeft ? <>
                <div style={{height: height, width: bedWidth}}>
                    <div className={'content-center'} style={{
                        height: '40%',
                        color: 'white', ...textStyle,
                        fontSize: `${bedTextFontSize * 0.382}px`,
                    }}>床号
                    </div>
                    <div className={'content-center'} style={{
                        height: '60%',
                        color: 'white', ...textStyle,
                        fontSize: `${bedTextFontSize}px`,
                    }}>{contentObj.bed.value}</div>
                </div>

                <Divider type={'vertical'} style={{backgroundColor: '#484F5F', height: height * 0.8,marginLeft:0,marginRight:0}}/>
            </> : null
        }

        <div style={{height: height, width: bpmWidth}}>
            <div className={'content-center'}
                 style={{height: '40%', color: hrColor, ...textStyle, fontSize: `${bpmTextFontSize * 0.382}px`}}>心率
            </div>
            <div className={'content-center'} style={{
                height: '60%',
                color: hrColor, ...textStyle,
                fontSize: `${bpmTextFontSize}px`,
            }}>{getHr(avgBeat)}</div>
        </div>

    </div>);
};

export default {
    getBpmContainerWidth,
    getChannelCardTitle,
    getChannelCardStyle,
    getChannelCardHeadHeight,
    getChannelBodyBpm,
    getChannelFooterHeight,
    getChannelCardTitleContainerStyle,
    getBedContainerWidth,
    BPM_LIMIT
};
