/**
 * @name: EventEmitter
 * @author: Seeker
 * @date: 2021-11-22 15:55
 * @description：EventEmitter
 * @update: 2021-11-22 15:55
 */
import {EventEmitter} from "events";

export default new EventEmitter();
