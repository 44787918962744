/**
 * @name: location-checker
 * @author: Seeker
 * @date: 2022-07-27 15:14
 * @description：location-checker
 * @update: 2022-07-27 15:14
 */
import {processBeta} from "../../UrlConfig";

export default () => {
    return window.location.href.includes('rt.zxthealth') || window.location.href.includes('beta') || processBeta;
}
