/**
 * @name: dataTask
 * @author: Seeker
 * @date: 2022-03-21 13:51
 * @description：dataTask
 * @update: 2022-03-21 13:51
 */

function task() {

    /* eslint-disable-next-line no-restricted-globals */
    const workerSelf = self;
    workerSelf._Digit = {
        chars: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        str62to10: function (value){
            let radix = this.chars.length;
            let valueStr = value.toString();
            let negative = false;
            if (valueStr.startsWith("-")){
                negative = true;
                valueStr = valueStr.substring(1,valueStr.length);
            }
            let len = valueStr.length;
            let decimal = 0;
            for (let i = 0; i < len;i++){
                decimal += this.chars.indexOf(valueStr.charAt(i)) * Math.pow(radix, len - i - 1);
            }
            return negative? "-"+decimal:""+decimal;
        },
    };
    workerSelf._AbnormalCompat = {
        VFIB:202, // 室颤
        Vgeminy:1001,//室性联律
        Vbigeminy:100, //室性二联律
        Vtrigeminy:101,//室性三联律
        Vcouple:102,//室性成对早搏
        VTachycardia:103,//室性心动过速
        AQVTachycardia:1031,//加速性室性心律
        Vtriplet:104,//室性三连发
        AFIB: 200,// 房颤
        APCgeminy:1501,// 房早联律
        APCbigeminy:150,//房早二联律
        APCtrigeminy:151,//房早三联律
        APCcouple:152, //房性成对早搏
        APCTachycardia:153,// 房性心动过速
        APCtriplet:154,//房性三连发
        R_RlongPulse:42, //长间歇
        Bradycardia:50,// 心动过缓
        Tachycardia:51,//心动过速
        NOISE: -1, // 噪音
        LEAD_OFF: -2 , // 导联线脱落
        WEAK_SIGNAL: -940, // 低振幅

        typeCode: function (typeCode,hr){
            let code = Number.parseInt(typeCode);
            if (code === this.Vbigeminy || code === this.Vtrigeminy){
                code = this.Vgeminy;
            }else if (code === this.APCbigeminy || code === this.APCtrigeminy){
                code = this.APCgeminy;
            }else if (code === this.VTachycardia){
                if(hr < 100){
                    code = this.AQVTachycardia;
                }
            }
            return code;
        },
    };
    workerSelf._date = {
        fnW: (str)=>{
            let num;
            str >= 10? num = str:num="0"+str;
            return num;
        },
        getDate: (code = 0) =>{
            const date=new Date();
            const year=date.getFullYear();//当前年份
            const month=date.getMonth();//当前月份
            const data=date.getDate();//天
            const hours=date.getHours();//小时
            const minute=date.getMinutes();//分
            const second=date.getSeconds();//秒
            if (code === 0){
                return year + "年" + this.fnW((month + 1)) + "月" + this.fnW(data) + "日 " + this.fnW(hours) + ":" + this.fnW(minute) + ":" + this.fnW(second);
            }
            return this.fnW(hours) + ":" + this.fnW(minute) + ":" + this.fnW(second);
        }
    };
    workerSelf.v0 = (dataArray,edr,result) =>{
        for (let i = 0,len = dataArray.length; i < len; i++) {
            let items = dataArray[i].split('#');
            const len = items.length;
            if (len === 0){
                return undefined;
            }
            let bpm = undefined;
            if (items.length >= 2){
                bpm = {
                    hr: parseInt(items[1]),
                    type: items.length === 3?items[2]:'N',
                    edr,
                    date: this._date.getDate(1),
                    time: new Date().getTime()
                };
            }
            result.push({
                value: [parseFloat(items[0])],
                bpm,
            });
        }
    };
    workerSelf.v1 = (dataArray,edr,result) =>{
        for (let i = 0,len = dataArray.length; i < len; i++) {
            let items = dataArray[i].split('#');
            const len = items.length;
            if (len === 0){
                return undefined;
            }
            const data = {
                value: [parseFloat(items[0])],
            };
            if (len >= 2){
                const hr = parseInt(items[1]);
                if (hr !== -1){
                    data.bpm = {
                        hr: parseInt(items[1]),
                        edr,
                    };
                }
                if (len >= 3){
                    data.alarmEvent = {
                        typeCode:this._AbnormalCompat.typeCode(items[2],hr),
                        time: new Date().getTime(),
                    };
                }
            }
            result.push(data);
        }
    };
    workerSelf._decode = (value,version) =>{
        if (version === 4){
            let temp = this._Digit.str62to10(value);
            return parseFloat(temp) / 100;
        }
        return parseFloat(value);
    };

    workerSelf.v2 = (dataArray,edr,result,version) =>{
        for (let i = 0,len = dataArray.length; i < len; i++) {
            const items = dataArray[i].split(/[#&]/);
            const len = items.length;
            if (len === 0){
                return undefined;
            }

            const temp = items[0].split('+');
            if (temp.length <= 0){
                return undefined;
            }
            const values = [];
            for (let i = 0,length = temp.length;i < length;i++){
                values.push(this._decode(temp[i],version));
            }
            const data = {
                value: values,
            };
            if (len >= 2){
                const item2Child = items[1];
                if (item2Child === 'Z'){
                    data.alarmEvent = {
                        typeCode:this._AbnormalCompat.NOISE,
                        time: new Date().getTime(),
                    };
                }else if (item2Child === 'L'){
                    data.alarmEvent = {
                        typeCode:this._AbnormalCompat.LEAD_OFF,
                        time: new Date().getTime(),
                    };
                }else if (item2Child === 'W'){
                    data.alarmEvent = {
                        typeCode:this._AbnormalCompat.WEAK_SIGNAL,
                        time: new Date().getTime(),
                    };
                }else {
                    const hr = parseInt(items[1]);
                    if (hr !== -1){
                        data.bpm = {
                            hr: parseInt(items[1]),
                            edr,
                        };
                    }
                    if (len >= 3){
                        data.alarmEvent = {
                            typeCode:this._AbnormalCompat.typeCode(items[2],hr),
                            time: new Date().getTime(),
                        };
                    }
                }
            }
            result.push(data);
        }
    };

    workerSelf.extra = () =>{

    };

    workerSelf.onmessage = function (ev) {
        const data = ev.data;
        switch (data.cmd){
            case 'ecgData':
                const dataArray = data.value.split(',');
                const result = [];
                switch (data.version){
                    case 0:
                        this.v0(dataArray,data.edr,result);
                        break;
                    case 1:
                        this.v1(dataArray,data.edr,result);
                        break;
                    case 2:
                    case 3:
                    case 4:
                        this.v2(dataArray,data.edr,result,data.version);
                        break;
                }
                this.postMessage({
                    cmd:'ecgData',
                    value:result,
                    destinationName:data.destinationName,
                    version:data.version,
                    needCacheChannelPrintData:data.needCacheChannelPrintData,
                    deviceModel:data.deviceModel
                })
                break;
            case 'extraData':
                this.extra();
                break;

        }
    };
}

export default task

