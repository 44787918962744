/**
 * @name: is-integer
 * @author: Seeker
 * @date: 2021-05-23 16:57
 * @description：is-integer
 * @update: 2021-05-23 16:57
 */

import isNumber from './is-number';

const isInteger = Number.isInteger ? Number.isInteger : function(num) {
    return isNumber(num) && num % 1 === 0;
};

export default isInteger;
