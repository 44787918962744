/**
 * @name: MqttWorker
 * @author: Seeker
 * @date: 2022-03-21 09:07
 * @description：MqttWorker
 * @update: 2022-03-21 09:07
 */
import ThreadWorker from "./ThreadWorker";
import task from "./Task";
import resultEmitter from './WorkerEvent'

export default class MQTTWorker{

    constructor() {
        this.worker = ThreadWorker(task,{name:'MQTT-Worker'});
        this.worker.onmessage = function (ev){
            const data = ev.data;
            switch (data.cmd){
                case 'ecgData':
                    resultEmitter.emit('ecgData',data);
                    break;
                case 'extraData':
                    resultEmitter.emit('extraData',data);
                    break;
            }
        };
    }

    postEcgData = (destinationName,decomposeData,needCacheChannelPrintData) =>{
        const {edr,valueStr,version,deviceModel} = decomposeData;
        this.worker.postMessage({
            cmd:'ecgData',
            destinationName:destinationName,
            version:version,
            value:valueStr,
            edr:edr,
            deviceModel:deviceModel,
            needCacheChannelPrintData:needCacheChannelPrintData
        });
    };

    postExtraData = (value) =>{
        this.worker.postMessage({
            cmd:'extraData',
            value:value
        });
    };

    registerCallback = (ecgCallback,extraCallback) =>{
        resultEmitter.on('ecgData',result => {
            ecgCallback && ecgCallback(result);
        });
        resultEmitter.on('extraData',result => {
            extraCallback && extraCallback(result);
        });
        return this;
    };
}
