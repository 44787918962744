/**
 * @name: ThemeColor
 * @author: Seeker
 * @date: 2021-12-22 15:24
 * @description：ThemeColor
 * @update: 2021-12-22 15:24
 */

import ViewAdapt2 from "./v2/ViewAdapt2";

const textColor = (black, venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#000000"
        }
    }
    return '#FFFFFF'
};

const textStyle = (black, venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return {color:"#000000",fontWeight:'bold'}
        }
    }
    return {color:"#FFFFFF"}
}

const toolsTextColor = (black,venueSetting) =>{
    return '#FFFFFF'
};

const batteryTextColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#000000"
        }
    }
    return '#FFFFFF'
};

const copyRightTextColor = (black,venueSetting) =>{
    return '#FFFFFF'
};

const noDataTextColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#000000"
        }
    }
    return '#FFFFFF'
};

const backgroundColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#636974"
        }
    }
    if (black){
        return '#000000';
    }
    return '#273142';
};

const channelBackgroundColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#FFFFFF"
        }
    }
    if (black){
        return '#000000';
    }
    return '#273142';
};

const paginationItemBgBackgroundColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#24858c"
        }
    }
    if (black){
        return '#000000';
    }
    return '#273142';
};

const toolsBackgroundColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#24858c"
        }
    }
    if (black){
        return '#000000';
    }
    return '#273142';
};

const chartGridLineColor = (black,highlight,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            if (highlight){
                return '#595959';
            }
            return '#BFBFBF';
        }
    }

    if (black){
        if (highlight){
            return '#06561c';
        }
        return '#062e1c';
    }
    if (highlight){
        return 'rgba(38,120,82,0.6)';
    }
    return 'rgba(38,85,82,0.5)';
};

const ecgLineColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return '#45b854';
        }
    }
    if (black){
        return '#00FF00';
    }
    return '#1FEE8E';
};

const spinnerBackgroundColor = (black,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#5A5A5A"
        }
    }
    return 'white';
};

const getBpmColor = (avgBeat,hrRanger,venueSetting) =>{
    const vs = venueSetting && venueSetting.venueOpenState;
    let color = vs?'#45B854':'#00FF00';
    const {min,max} = hrRanger;
    if (avgBeat) {
        const hr = avgBeat.hr;
        if (hr > 0) {
            if (hr >= min && hr <= max) {
                color = vs?'#45B854':'#00FF00';
            } else if (hr < ViewAdapt2.BPM_LIMIT.min || hr > ViewAdapt2.BPM_LIMIT.max) {
                color = '#ff3838';
            }else {
                color = '#F8EA1D';
            }
        }
    }
    return color;
};

const getEdrColor = (venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#06D5E7"
        }
    }
    return '#12e9ea';
};

const leadWireOffBackgroundColor = (darkModule,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return 'rgba(21,27,36,0.2)'
        }
    }
    if (darkModule){
        return 'rgba(21,27,36,0.8)'
    }
    return 'rgba(39,49,66,0.7)'
}

const leadWireOffTextColor = (darkModule,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return '#333333'
        }
    }
    return '#FFFFFF'
}

const gainTextColor = (darkModule,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return '#000000'
        }
    }
    return '#FFFFFF'
}

const getEcgLineWidth = (darkModule,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return 1.5
        }
    }
    return 1
}

const ThemeColor = {
    textColor,
    textStyle,
    toolsTextColor,
    copyRightTextColor,
    batteryTextColor,
    noDataTextColor,
    backgroundColor,
    toolsBackgroundColor,
    paginationItemBgBackgroundColor,
    channelBackgroundColor,
    chartGridLineColor,
    ecgLineColor,
    spinnerBackgroundColor,
    getBpmColor,
    getEdrColor,
    leadWireOffBackgroundColor,
    leadWireOffTextColor,
    gainTextColor,
    getEcgLineWidth
};

export default ThemeColor
