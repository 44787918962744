import {
    FETCH_EXTERN_LOGIN_STARTED,
    FETCH_EXTERN_LOGIN_SUCCESS,
    FETCH_EXTERN_LOGIN_FAILURE
} from "./actionTypes";
const initState={
    loading:false,
    list:[],
    filters:{}
}
export default (state=initState,action)=>{
    switch (action.type) {
        case FETCH_EXTERN_LOGIN_STARTED:
            return{
                ...state,
                loading:true
            };
        case FETCH_EXTERN_LOGIN_SUCCESS:
            return{
                ...state,
                result:true,
                loading: false
            };
        case FETCH_EXTERN_LOGIN_FAILURE:
            return{
                ...state,
                result:false,
                error:action.error,
                loading:false
            };
        default:
            return state
    }
}
