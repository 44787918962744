import React,{Component} from 'react';
import HomeStyle from "../../../HomeStyle";

/**
 * @name: Spinner
 * @author: Seeker
 * @date: 2021-08-17 17:00
 * @description：Spinner
 * @update: 2021-08-17 17:00
 */
class Spinner extends Component{
    render(){

        const color = HomeStyle.spinnerBackgroundColor(this.props.homeStyleCode,this.props.venueSetting);

        return (
            <div className="spinner spinner-size">
                <div className="rect1" style={{backgroundColor:color}}/>
                <div className="rect2" style={{backgroundColor:color}}/>
                <div className="rect3" style={{backgroundColor:color}}/>
                <div className="rect4" style={{backgroundColor:color}}/>
                <div className="rect5" style={{backgroundColor:color}}/>
            </div>
        );
    }
}

export default Spinner
