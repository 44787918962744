/**
 * @name: SyncMqttClient.js
 * @author: Seeker
 * @date: 2022-03-22 08:43
 * @description：SyncMqttClient.js
 * @update: 2022-03-22 08:43
 */
import {MQTTClient} from "./MQTT";

export default class SyncMqttClient extends MQTTClient{

}
