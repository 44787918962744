/**
 * @name: FileReader
 * @author: Seeker
 * @date: 2021-04-07 13:10
 * @description：FileReader
 * @update: 2021-04-07 13:10
 */
export const V1 = [
    [require('../../data/V1/1617705268_filtered'),
        require('../../data/V1/1617705328_filtered'),
        require('../../data/V1/1617705388_filtered'),
        require('../../data/V1/1617705448_filtered'),
        require('../../data/V1/1617705508_filtered'),
        require('../../data/V1/1617705568_filtered'),
        require('../../data/V1/1617705628_filtered'),
        require('../../data/V1/1617705688_filtered'),
        require('../../data/V1/1617705748_filtered'),
        require('../../data/V1/1617705808_filtered')],
    [require('../../data/V1/other_annotation'),]
];

export const V2 = [
    [require('../../data/V2/1617675829_filtered'),
        require('../../data/V2/1617675889_filtered'),
        require('../../data/V2/1617675949_filtered'),
        require('../../data/V2/1617676009_filtered'),
        require('../../data/V2/1617676069_filtered'),
        require('../../data/V2/1617676129_filtered'),
        require('../../data/V2/1617676189_filtered'),
        require('../../data/V2/1617676249_filtered'),
        require('../../data/V2/1617676310_filtered'),
        require('../../data/V2/1617676370_filtered')],
    [require('../../data/V2/other_annotation'),]
];

export const S1 = [
    [require('../../data/S1/1617659791_filtered'),
        require('../../data/S1/1617659851_filtered'),
        require('../../data/S1/1617659911_filtered'),
        require('../../data/S1/1617659971_filtered'),
        require('../../data/S1/1617660031_filtered'),
        require('../../data/S1/1617660091_filtered'),
        require('../../data/S1/1617660151_filtered'),
        require('../../data/S1/1617660211_filtered'),
        require('../../data/S1/1617660271_filtered'),
        require('../../data/S1/1617660331_filtered')],
    [require('../../data/S1/other_annotation'),]
];

export const S2 = [
    [require('../../data/S2/1617703966_filtered'),
        require('../../data/S2/1617704026_filtered'),
        require('../../data/S2/1617704086_filtered'),
        require('../../data/S2/1617704146_filtered'),
        require('../../data/S2/1617704206_filtered'),
        require('../../data/S2/1617704266_filtered'),
        require('../../data/S2/1617704326_filtered'),
        require('../../data/S2/1617704387_filtered'),
        require('../../data/S2/1617704447_filtered'),
        require('../../data/S2/1617704507_filtered')],
    [require('../../data/S2/other_annotation'),]
];

export const RR1 = [
    [require('../../data/RR1/1617661549_filtered'),
        require('../../data/RR1/1617661609_filtered'),
        require('../../data/RR1/1617661669_filtered'),
        require('../../data/RR1/1617661729_filtered'),
        require('../../data/RR1/1617661790_filtered'),
        require('../../data/RR1/1617661850_filtered'),
        require('../../data/RR1/1617661910_filtered'),
        require('../../data/RR1/1617661970_filtered'),
        require('../../data/RR1/1617662030_filtered'),
        require('../../data/RR1/1617662090_filtered')],
    [require('../../data/RR1/other_annotation'),]
];

export const RR2 = [
    [require('../../data/RR2/1617722289_filtered'),
        require('../../data/RR2/1617722350_filtered'),
        require('../../data/RR2/1617722410_filtered'),
        require('../../data/RR2/1617722470_filtered'),
        require('../../data/RR2/1617722530_filtered'),
        require('../../data/RR2/1617722590_filtered'),
        require('../../data/RR2/1617722650_filtered'),
        require('../../data/RR2/1617722711_filtered'),
        require('../../data/RR2/1617722771_filtered'),
        require('../../data/RR2/1617722831_filtered')],
    [require('../../data/RR2/other_annotation'),]
];

export const N1 = [
    [require('../../data/N1/1617683466_filtered'),
        require('../../data/N1/1617683526_filtered'),
        require('../../data/N1/1617683586_filtered'),
        require('../../data/N1/1617683646_filtered'),
        require('../../data/N1/1617683706_filtered'),
        require('../../data/N1/1617683766_filtered'),
        require('../../data/N1/1617683826_filtered'),
        require('../../data/N1/1617683886_filtered'),
        require('../../data/N1/1617683946_filtered'),
        require('../../data/N1/1617684006_filtered')],
    [require('../../data/N1/other_annotation'),]
];

export const FAST2 = [
    [require('../../data/RR1/1617661549_filtered'),
        require('../../data/RR1/1617661609_filtered'),
        require('../../data/RR1/1617661669_filtered'),
        require('../../data/RR1/1617661729_filtered'),
        require('../../data/RR1/1617661790_filtered'),
        require('../../data/RR1/1617661850_filtered'),
        require('../../data/RR1/1617661910_filtered'),
        require('../../data/RR1/1617661970_filtered'),
        require('../../data/RR1/1617662030_filtered'),
        require('../../data/RR1/1617662090_filtered')],
    [require('../../data/RR1/other_annotation'),]
];

export const SLOW1 = [
    [require('../../data/RR1/1617661549_filtered'),
        require('../../data/RR1/1617661609_filtered'),
        require('../../data/RR1/1617661669_filtered'),
        require('../../data/RR1/1617661729_filtered'),
        require('../../data/RR1/1617661790_filtered'),
        require('../../data/RR1/1617661850_filtered'),
        require('../../data/RR1/1617661910_filtered'),
        require('../../data/RR1/1617661970_filtered'),
        require('../../data/RR1/1617662030_filtered'),
        require('../../data/RR1/1617662090_filtered')],
    [require('../../data/RR1/other_annotation'),]
];


export const SLOW2 = [
    [require('../../data/RR1/1617661549_filtered'),
        require('../../data/RR1/1617661609_filtered'),
        require('../../data/RR1/1617661669_filtered'),
        require('../../data/RR1/1617661729_filtered'),
        require('../../data/RR1/1617661790_filtered'),
        require('../../data/RR1/1617661850_filtered'),
        require('../../data/RR1/1617661910_filtered'),
        require('../../data/RR1/1617661970_filtered'),
        require('../../data/RR1/1617662030_filtered'),
        require('../../data/RR1/1617662090_filtered')],
    [require('../../data/RR1/other_annotation'),]
];

export const PM = [
    [require('../../data/PM/1639125493_filtered'),
        require('../../data/PM/1639125553_filtered'),
        require('../../data/PM/1639125613_filtered'),
        require('../../data/PM/1639125673_filtered'),
        require('../../data/PM/1639125733_filtered'),
        require('../../data/PM/1639125794_filtered'),
        require('../../data/PM/1639125854_filtered'),
        require('../../data/PM/1639125914_filtered'),
        require('../../data/PM/1639125974_filtered'),
        require('../../data/PM/1639126034_filtered'),],
    []
];

const data2Voltage = (v)=> v / 32767.0 * 2.42 / 6 * 1000;

const read = (path)=>{
    const readBlob = (blob)=>{
        return new Promise(function (resolve, reject) {
            const fs = new FileReader();
            fs.onload = ()=>{
                resolve(fs.result);
            };
            fs.readAsArrayBuffer(blob);
        });
    };
    const request = new Request(path,{method: 'GET',});
    return fetch(request)
        .then(res=>{
            return res.blob();
        })
        .then(res=>{
            return (async (blob)=>{
                return await readBlob(blob);
            })(res);
        });
};

const loadOne = (array)=>{
    const dataPromises = [];
    array[0].forEach(v=>{
        dataPromises.push(
            read(v)
            .then(res=>{
                return new Float32Array(res);
            })
            .then(fa=>{
                const temp = [];
                fa.forEach(v=>{
                    temp.push({data: data2Voltage(v)})
                });
                return temp;
            })
        );
    });
    const data = Promise.all(dataPromises)
        .then(results=>{
            const temps = [];
            results.forEach(rr=>{
                temps.push(...rr);
            });
            return temps;
        });
    const annotation = array[1].length > 0 ?read(array[1][0])
        .then(res=>{
            const temp = [];
            for (let i = 0,len = res.byteLength;i< len;i+=22){
                const index = new Int32Array(res.slice(i,i+4))[0];
                const type = new Int16Array(res.slice(i+4,i+6))[0];
                const typeDes = getTypeDes(type);
                temp.push({index,type,typeDes});
            }
            return temp;
        }):null;
    const values = [data];
    if (annotation){
        values.push(annotation);
    }
    return Promise.all(values)
        .then(res=>{
            const tempData = res[0];
            const length = tempData.length;
            if (res.length >= 2){
                const tempAnno = res[1];
                tempAnno.forEach(a=>{
                    if (a.index < length){
                        const temp = tempData[a.index];
                        tempData[a.index] = {...temp,...a};
                    }
                });
                tempAnno.length = 0;
            }
            return tempData;
        });
};

const getTypeDes = (type)=>{
    switch (type) {
        case 1: return 'N';
        case 5:
        case 41:return 'V';
        case 8:return 'S';
        case 45: return 'Pause';
        default: return `${type}`;
    }
};

export default loadOne;