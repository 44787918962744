import React, {Component} from 'react';
import axios from '../myAxios';
import {Avatar, Dropdown, Menu} from 'antd';
import ResetPwd from './ResetPwd';
import {reload} from '../UnloadEvent';
import logo from '../img/logo.png';
import {LogoutOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import settingStorage from "../main/util/setting-storage";
import HomeStyle from "../HomeStyle";

class UserCtrl extends Component {
    constructor() {
        super(...arguments);
        this.logout = this.logout.bind(this);
        this.systemSetting = this.systemSetting.bind(this);
    }

    logout() {
        axios.get('/logoutJson.action').then(() => {
            settingStorage.logoutAccount();
            reload(true);
        });
    }

    systemSetting(){
        this.props.systemSetting();
    }

    render() {
        const menu = (
            <Menu>
                {
                    this.props.homeStyleCode === 4?
                        <>
                            <Menu.Item>
                                <div onClick={this.systemSetting}><SettingOutlined style={{marginRight: 8, fontSize: 12}}/>系统设置</div>
                            </Menu.Item>
                            <Menu.Divider />
                        </>
                        :null
                }
                <Menu.Item>
                    <ResetPwd/>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                    <div onClick={this.logout}><LogoutOutlined style={{marginRight: 8, fontSize: 12}}/>退出登录</div>
                </Menu.Item>
            </Menu>
        )

        let avatar;
        if (this.props.userInfo && this.props.userInfo.userAvatarAddr) {
            avatar = <Avatar className='right-action-avatar' size='small' style={HomeStyle.getAvatarStyle(this.props.homeStyleCode)} src={logo} />
        } else {
            avatar = <Avatar className='right-action-avatar' size='small' style={HomeStyle.getAvatarStyle(this.props.homeStyleCode)} icon={<UserOutlined/>} />
        }
        return (
            <Dropdown overlay={menu} placement="bottomRight">
                <span className='right-action' title={`当前用户:${this.props.userInfo && this.props.userInfo.userName}`}>
                    {avatar}
               </span>
            </Dropdown>
        )
    }
}

export default UserCtrl;
