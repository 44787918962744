/**
 * @name: printer
 * @author: Seeker
 * @date: 2021-11-24 08:49
 * @description：printer
 * @update: 2021-11-24 08:49
 */

const Printer = {
    printCurWindow: (imgSrc) => {
        let iframe = document.createElement('IFRAME')
        let doc = null
        iframe.setAttribute('class', 'print-iframe')
        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;')
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        // 取一个不重复的方法名称，可以随机字符串
        doc.___imageLoad___ = function () {
            iframe.contentWindow.print()
            if (navigator.userAgent.indexOf('MSIE') > 0) {
                document.body.removeChild(iframe)
            }
        }
        doc.write('<div style="height: 100%;width: 100%;text-align: center">' + `<img src="${imgSrc}" alt="" style="max-height:100%;max-width: 100%;" onload="___imageLoad___()"/>` + '</div>')
        doc.close()
        iframe.contentWindow.focus()
    },
    printNewWindow:(imgSrc) => {
        let oWin = window.open('', 'pringwindow', 'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=1000,height=660')
        oWin.document.fn = function() {
            if (oWin) {
                oWin.print()
                oWin.close()
            }
        }
        let html = '<div style="height: 100%;width: 100%;text-align: center">' + `<img src="${imgSrc}" alt="" onload="fn()" style="max-height:100%;max-width: 100%;" />` + '</div>'
        oWin.document.open()
        oWin.document.write(html)
        oWin.document.close()
    }
};

export default Printer


