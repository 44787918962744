/**
 * @name: is-type
 * @author: Seeker
 * @date: 2021-05-23 14:46
 * @description：is-type
 * @update: 2021-05-23 14:46
 */

const toString = {}.toString;

const isType = (value, type) => toString.call(value) === '[object ' + type + ']';

export default isType;