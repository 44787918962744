import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {ConfigProvider} from 'antd';
import {BrowserRouter as Router} from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN';
import axios from 'axios';
import store from './Store.js'
import {baseUrl,pageUrl} from "./UrlConfig";

// let base = process.env.REACT_APP_BASE_URL;
// let base = 'beta';
// base = (base === undefined) ? '' : base;
axios.defaults.baseURL = baseUrl;


ReactDOM.render(
    <Provider store={store}>
        <Router basename={baseUrl} >
            <ConfigProvider locale={zhCN}>
                <App />
            </ConfigProvider>
        </Router>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
