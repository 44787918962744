import axios from 'axios';
import {reload} from "./UnloadEvent";
import autoLogin from "./login/modules/autoLogin";

const check = (res) => {
    return res && res.request && res.request.responseURL && res.request.responseURL.indexOf('noLoginJson') !== -1;
}

axios.interceptors.response.use(
    response => {
        if (check(response)){
            if (response.config.hasOwnProperty('retryCount') && response.config.retryCount >= 3){
                console.log(`interceptors.autoLogin()called before: retryCount >= 3`);
                reload(true);
                return;
            }
            return autoLogin()
                .then(res =>{
                    console.log(`interceptors.autoLogin()called:${res}`);
                    if (response.config.hasOwnProperty('retryCount')){
                        response.config.retryCount = response.config.retryCount +1;
                    }else {
                        response.config.retryCount = 1;
                    }
                    return axios.request(response.config);
                })
                .catch(err=>{
                    console.log(`interceptors.autoLogin()called error:${err}`);
                    reload(true);
                })
        }else {
            return Promise.resolve(response);
        }
    },
);

export default {
    get: function () {
        return axios.get(...arguments).then((res) => {
            if (check(res)){
                reload(true);
            }
            return res;
        }).catch((err) => {
            throw(err);
        })
    },
    post: function () {
        return axios.post(...arguments).then((res) => {
            if (check(res)){
                reload(true);
            }
            return res;
        }).catch((err) => {
            throw(err);
        })
    },
};
