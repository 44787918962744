/**
 * @name: is-number
 * @author: Seeker
 * @date: 2021-05-23 16:58
 * @description：is-number
 * @update: 2021-05-23 16:58
 */

import isType from './is-type';

const isNumber = function(value) {
    return isType(value, 'Number');
};
export default isNumber;