import {message} from "antd";

/**
 * @name: MobileMediaScreen
 * @author: Seeker
 * @date: 2021-11-27 08:39
 * @description：MobileMediaScreen
 * @update: 2021-11-27 08:39
 */
const MobileMediaScreen = {

    enter: function (){
        if (document.webkitIsFullScreen){
            return;
        }
        let el = document.documentElement;
        let rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
        if (rfs) {
            rfs.call(el);
        }else {
            message.warn('请按键盘F11键开启/关闭全屏显示').then(r => {});
        }
    },
    exit:function (){
        if (!document.webkitIsFullScreen){
            return;
        }
        let el = document;
        let cfs = el.cancelFullScreen || el.mozCancelFullScreen || el.msExitFullscreen || el.webkitExitFullscreen || el.exitFullscreen;
        if (cfs) {
            cfs.call(el)
        }else {
            message.warn('请按键盘F11键开启/关闭全屏显示').then(r => {});
        }
    },
    getDpi: function (){
        for (let i = 56;i < 2000;i++){
            if (window.matchMedia("(max-resolution: " + i + "dpi)").matches === true){
                return i;
            }
        }
    },
};

export default MobileMediaScreen;
