import {
    FETCH_MQTT_CHANNEL_LIST_STARTED, FETCH_MQTT_CHANNEL_LIST_SUCCESS
} from "./actionTypes";
import {FETCH_USER_HISTORY_LIST_FAILURE} from "../HistoryRecord/actionTypes";
const initState={
    loading:false,
    list:[],
    filters:{}
}
export default (state=initState,action)=>{
    switch (action.type) {
        case FETCH_MQTT_CHANNEL_LIST_STARTED:
            return{
                ...state,
                loading:true
            };
        case FETCH_MQTT_CHANNEL_LIST_SUCCESS:
            return{
                ...state,
                list:action.payload.result,
                total:action.payload.total,
                loading: false
            };
        case FETCH_USER_HISTORY_LIST_FAILURE:
            return{
                ...state,
                loading:false
            };
        default:
            return state
    }
}
