/**
 * @name: WorkerEvent
 * @author: Seeker
 * @date: 2022-03-22 08:28
 * @description：WorkerEvent
 * @update: 2022-03-22 08:28
 */

import {EventEmitter} from "events";

export default new EventEmitter();
