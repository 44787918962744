import {pageUrl,baseUrl} from "./UrlConfig";

const unloadEvent = (event) => {
    // 目前大部分浏览器已经移除了自定义关闭页面时的自定义提醒信息功能
    const msg = '您确定要离开当前页面吗？';
    event.returnValue = msg;
    return msg;
};

let reloading = false;

const reload = (login) => {
    if (!reloading) {
        reloading = true;
        window.removeEventListener('beforeunload', unloadEvent);
        if (!login) {
            window.location.reload();
        } else {
            // let base = process.env.REACT_APP_BASE_URL;
            // base = (base === undefined) ? '/' : '/' + base + '/';
            //window.location.href = pageUrl + '/login';
            window.location.href = baseUrl + '/login';
        }
    }
}

export default unloadEvent;
export {reload};
