/**
 * @name: debounce
 * @author: Seeker
 * @date: 2021-03-10 09:10
 * @description：debounce
 * @update: 2021-03-10 09:10
 */
export default function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const later = function () {
      timeout = null;
      if (!immediate) {
        func();
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
    if (callNow) {
      func();
    }
  };
}
