import React,{Component} from 'react';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchMqttChannelList} from "./actions";
import MQTTTestClient from "./MQTTTestClient";
import Text from "antd/es/typography/Text";
import Moment from "moment";
import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'

const stateItem = (channel,states) => {
    return <div>
        <Text style={{color:'#000000',fontSize:'16px',paddingTop:'20px',whiteSpace:'pre-wrap'}}>医院：{channel.company_name}-{channel.dept_name}      患者姓名：{channel.user_name} | {channel.bed_number} | {channel.mach_sn}</Text>
        <div style={{paddingBottom:'20px'}}>
            {
                states && states.map(({stateValue,date,time}) => {
                    return <div style={{color:stateValue === 0?'green':'red'}}>
                        发生时间:  {stateValue === 0?Moment(time).format('yyyy-MM-DD HH:mm:ss'):Moment(date).format('yyyy-MM-DD HH:mm:ss')}  连接状态：{stateValue === 0?"连接":"断开"}
                    </div>
                })
            }
        </div>
    </div>
};

const workSheetColumns = [
    {header:'医院',key:'hospital',width: 35},
    {header:'科室',key:'department',width: 25},
    {header:'患者',key:'patientName',width: 15},
    {header:'设备号',key:'machSn',width: 15},
    {header:'床位号',key:'bedNumber',width: 15},
    {header:'连接状态',key:'connectState',width: 10},
    {header:'发生时间',key:'occurTime',width: 25},
];

const createExcelRow = (channel, state,connectStateFun) => {
    return {
        hospital: channel.company_name,
        department: channel.dept_name,
        patientName: channel.user_name,
        machSn: channel.mach_sn,
        bedNumber:channel.bed_number,
        connectState: connectStateFun(state),
        occurTime:state.stateValue === 0?Moment(state.time).format('yyyy-MM-DD HH:mm:ss'):Moment(state.date).format('yyyy-MM-DD HH:mm:ss'),
    };
}

class MqttTest extends Component{

    constructor() {
        super(...arguments);
        this.mqttTestClient = new MQTTTestClient(this.appMqttClientConnectStateListener,this.cardioguardConnectStateListener)
        this.mqttStates = {};
        this.cardioguardStates = {};
        this.refreshTimer = null;
        this.state = {
            refreshCount:0
        };
    }
    componentDidMount(){
        console.log(`[MqttTest] componentDidMount...`)
        this.props.queryData()
        this.refreshPager()
    }

    appMqttClientConnectStateListener = (channelName,stateValue,time) =>{
        let states = this.mqttStates[channelName];
        if (states === undefined || states === null){
            states = [];
            this.mqttStates[channelName] = states;
        }
        states.push({
            stateValue: stateValue,
            date: new Date().getTime(),
            time:time
        })
    };

    cardioguardConnectStateListener = (channelName,stateValue,time) =>{
        let states = this.cardioguardStates[channelName];
        if (states === undefined || states === null){
            states = [];
            this.cardioguardStates[channelName] = states;
        }
        states.push({
            stateValue: stateValue,
            date: time,
            time:time
        })
    };


    refreshPager = () => {
        this.refreshTimer = setInterval(() =>{
            this.setState((preState) =>{
                return {
                    refreshCount:preState.refreshCount + 1
                }
            },() =>{
                console.log(`refreshCount = ${this.state.refreshCount}`)
            })
        },60 * 1000);
    }

    exportData = () =>{
        const workBook = new ExcelJS.Workbook();
        const mqttConnectSheet = workBook.addWorksheet('APP端遥测连接状态');
        const cardioguardConnectSheet = workBook.addWorksheet('心电仪设备连接状态');
        mqttConnectSheet.columns = workSheetColumns;
        cardioguardConnectSheet.columns = workSheetColumns;
        this.props.recList && this.props.recList.forEach(value =>{
            const states = this.mqttStates[value.mqtt_channel];
            states && states.forEach(state => {
                mqttConnectSheet.addRow(createExcelRow(value,state,(state) => state.stateValue === 0?"连接":"断开"))
            })
            const deviceStates = this.cardioguardStates[value.mqtt_channel];
            deviceStates && deviceStates.forEach(state => {
                cardioguardConnectSheet.addRow(createExcelRow(value,state,(state) => state.stateValue === 1?"连接":"断开"))
            })
        });
        workBook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer], {
            }), `连接状态-${Moment().format('YYYYMMDDHHmm')}.xlsx`))
            .catch(err => console.log('Error writing excel export', err));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(`[MqttTest] componentWillReceiveProps...`)
        this.mqttTestClient.updateVisibleChannel(nextProps.recList)
    }

    render(){
        console.log(`[MqttTest] render,refreshCount = `+this.state.refreshCount)
        return(
            <div style={{overflow:'scroll',height:'100vh',padding:'30px 50px'}}>

                <button style={{right:'80px',position:'fixed',width:'100px',height:'62px',fontSize:'30px'}} onClick={() =>{
                  this.exportData()
                }}>导出</button>

                {
                    this.props.recList && this.props.recList.map((value) =>{
                        return stateItem(value,this.mqttStates[value.mqtt_channel])
                    })
                }
            </div>
        )
    }
}
const mapStateToProps =(state,props)=>{
    let recList = [];
    const data = state.main.mqttTest;
    if (data) {
        if (data.list) {
            recList.push(...data.list);
        }
    }
    recList = recList.sort((a,b) =>{
        return a.company_name.localeCompare(b.company_name)
    })
    return {
        recList,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        queryData:()=>{
            dispatch(fetchMqttChannelList())
        }
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MqttTest))
