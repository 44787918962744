/**
 * @name: data
 * @author: Seeker
 * @date: 2021-04-08 10:09
 * @description：data
 * @update: 2021-04-08 10:09
 */
import loadOne, {V1, V2, S1, S2, RR1, RR2, N1, PM} from "./FileReader";
import {data} from "../Common/MQTT";

const demoChannelList = [];
const demoDataBuffer = {};

const demoBorn = (gridArgs)=>{
    for(let i=0;i < gridArgs[0]*gridArgs[1];i++){
        let item = {
            bed_number: `00${i+1}`,
            case_number: null,
            dept_id: null,
            dept_name: `正心演示科`,
            end_time: null,
            key: i,
            mach_sn: `S1C330079B`,
            mqtt_channel: `S1C330079B-${i+1}`,
            rec_id: null,
            start_time: null,
            user_name: `患者-${i+1}`,
        };
        demoChannelList.push(item);
    }

    let arrs = [loadOne(PM),loadOne(V1),loadOne(V2),loadOne(N1),loadOne(S1),loadOne(S2),loadOne(N1),loadOne(RR1),loadOne(RR2),loadOne(N1)];
     if (gridArgs[0]*gridArgs[1] === 15){
         arrs = [loadOne(PM),loadOne(V1),loadOne(V2),loadOne(N1),loadOne(S1),loadOne(S2),loadOne(N1),loadOne(RR1),loadOne(RR2),loadOne(N1),
                 loadOne(V1),loadOne(V2),loadOne(S1),loadOne(S2),loadOne(RR1)];
    }else if (gridArgs[0]*gridArgs[1] === 12){
         arrs = [loadOne(PM),loadOne(V1),loadOne(V2),loadOne(N1),loadOne(S1),loadOne(S2),loadOne(N1),loadOne(RR1),loadOne(RR2),loadOne(N1),
             loadOne(V1),loadOne(V2)];
     }else if (gridArgs[0]*gridArgs[1] === 18){
         arrs = [loadOne(PM),loadOne(V1),loadOne(V2),loadOne(N1),loadOne(S1),loadOne(S2),loadOne(N1),loadOne(RR1),loadOne(RR2),loadOne(N1),
             loadOne(V1),loadOne(V2),loadOne(S1),loadOne(S2),loadOne(RR1),loadOne(N1),loadOne(V1),loadOne(V2)];
     }
    Promise.all(arrs)
        .then(res=>{
            for (let i = 0,len = res.length;i < len;i++){
                demoDataBuffer[`S1C330079B-${i+1}`] = res[i];
            }
        })
        .catch(err=>{
            console.error(err);
        });
};

const demoClear = ()=>{
    demoChannelList.length = 0;
    for (const key in  demoDataBuffer){
        delete demoDataBuffer[key];
    }
    for (const key in  data){
        delete data[key];
    }
};

export {demoBorn,demoClear,demoChannelList,demoDataBuffer};