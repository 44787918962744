/**
 * @name: is-mobile
 * @author: Seeker
 * @date: 2022-04-26 15:15
 * @description：is-mobile
 * @update: 2022-04-26 15:15
 */

export default () =>{
    const system = {};
    const p = navigator.platform;
    system.win = p.indexOf("Win") === 0;
    system.mac = p.indexOf("Mac") === 0;
    system.x11 = (p === "X11") || (p.indexOf("Linux") === 0);
    return !(system.win || system.mac || system.x11);
};
