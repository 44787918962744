import React,{Component} from 'react';
import MonitorList from "./modules/MonitorList";
class RealTimeMonitor extends Component{
    render(){
        return(
            <div style={{margin:'0px 10px 0px 10px'}}>
                <MonitorList/>
            </div>
        )
    }

}
export default RealTimeMonitor;
