import ViewAdapt2 from "./main/RealTimeMonitor/modules/v2/ViewAdapt2";
import picBg from "../src/img/pic_bg@2x.png"
import picHeadBg from "../src/img/pic_bg_head@2x.png"
import picBg3 from "../src/img/bg_pic2@2x.png"
// import nameIcon from "../src/img/ic_name.png";
// import deptIcon from "../src/img/ic_department.png";
// import bedIcon from "../src/img/ic_hospitalbed.png";
import nameDarkIcon2x from "../src/img/ic_name_dark@2x.png";
// import deptDarkIcon2x from "../src/img/ic_department_dark@2x.png";
// import bedDarkIcon2x from "../src/img/ic_hospitalbed_dark@2x.png";
// import channelDisconnectDarkIcon2x from "../src/img/ic__irrnormal_dark@2x.png";
import phoneDisconnectDarkIcon2x from "../src/img/ic_phone__irrnormal_dark@2x.png";
import deviceDisconnectDarkIcon2x from "../src/img/ic_device__irrnormal_dark@2x.png";
// import wifiDisconnectDarkIcon2x from "../src/img/Wifi_connect_dark@2x.png";
import phoneBatteryLowDarkIcon2x from "../src/img/ic_phone__normal_dark_ battery1@2x.png";
import phoneBattery30DarkIcon2x from "../src/img/ic_phone__normal_dark_30@2x.png";
import phoneBattery60DarkIcon2x from "../src/img/ic_phone__normal_dark_60@2x.png";
import phoneBattery100DarkIcon2x from "../src/img/ic_phone__normal_dark_100@2x.png";
// import bltDisconnectDarkIcon2x from "../src/img/ic_bluetooth_dark@2x.png";
import deviceBatteryLowDarkIcon2x from "../src/img/ic_device__nol_dark_ battery@2x.png";
import deviceBattery30DarkIcon2x from "../src/img/ic_device__normal_dark_30@2x.png";
import deviceBattery60DarkIcon2x from "../src/img/ic_device__normal_dark_60@2x.png";
import deviceBattery100DarkIcon2x from "../src/img/ic_device__normal_dark_100@2x.png";
import venueDeviceBatteryIcon from "../src/img/venue_device_battery_icon.png";
import venuePhoneBatteryIcon from "../src/img/venue_phone_battery_icon.png";
import icPhoneIcon from "../src/img/ic_shouji@2x.png"
import icDeviceIcon from "../src/img/ic_device@2x.png"
import icNameIcon from "../src/img/ic_yonghu@2x.png"
import icNameFemaleIcon from "../src/img/ic_yonghu_famale@2x.png"

/**
 * @name: HomeStyle
 * @author: Seeker
 * @date: 2023-12-19 14:09
 * @description：HomeStyle
 * @update: 2023-12-19 14:09
 */

const paginationItemBgBackgroundColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return "#24858c"
    }
    if (styleCode === 1){
        return '#000000';
    }
    if (styleCode === 2){
        return '#273142';
    }
    if (styleCode === 3){
        return 'transparent';
    }
    if (styleCode === 4){
        return 'transparent';
    }
    return '#000000';
};

const metaColor = (styleCode) =>{
    if (styleCode === 1){
        return '#000000';
    }
    if (styleCode === 2){
        return '#273142';
    }
    if (styleCode === 3){
        return '#273142';
    }
    return '#000000';
};

const headSpanStyle = (styleCode) =>{
    if (styleCode === 1){
        return {color:'#FFFFFF',marginRight:20};
    }
    if (styleCode === 2){
        return {color:'#000000',marginRight:20};
    }
    if (styleCode === 3){
        return {color:'#000000',marginRight:20};
    }
    return {color:'#FFFFFF',marginRight:20};
}

const bodyBackground = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return {background:"#636974"}
    }
    if (styleCode === 1){
        return {background:"#000000"}
    }
    if (styleCode === 2){
        return {background:"#273142"}
    }
    if (styleCode === 3){
        return {backgroundImage:`url(${picBg3})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover'}
    }
    if (styleCode === 4){
        return {backgroundImage:`url(${picBg})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover'}
    }
    return {background:"#273142"}
};

const copyRightTextColor = (styleCode,venueSetting) =>{
    return '#FFFFFF'
};

const gridGutter = (styleCode) => {
    if (styleCode === 1 || styleCode === 2 || styleCode === 5){
        return [5,5];
    }
    return [15,18]
};

const toolsTextColor = (styleCode,venueSetting) =>{
    return '#FFFFFF'
};

const toolsBackgroundStyle = (styleCode,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return {background:"#24858c",border: '1px solid #4A5566'}
        }
    }
    if (styleCode === 1){
        return {background:"#000000",border: '1px solid #4A5566'}
    }
    if (styleCode === 2){
        return {background:"#273142",border: '1px solid #4A5566'}
    }
    if (styleCode === 3){
        return {background:"transparent"}
    }
    if (styleCode === 4){
        return {backgroundImage:`url(${picHeadBg})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'contain',
            backgroundPosition:'center'}
    }
    return {background:"#273142"}
};

const batteryTextColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return "#000000"
    }
    if (styleCode === 4){
        return "#000000"
    }
    return '#FFFFFF'
};

const channelBorder = (styleCode,venueSetting) =>{
    if (styleCode === 4){
        return {borderRadius:5};
    }
    return {border: '1px solid rgba(74, 85, 102, 0.8)',
        borderRadius:3};
};

const channelBackgroundColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return "#FFFFFF"
    }
    if (styleCode === 1){
        return '#000000';
    }
    if (styleCode === 2){
        return '#273142';
    }
    if (styleCode === 3){
        return '#273142';
    }
    if (styleCode === 4){
        return '#FFFFFF';
    }
    return '#273142';
};

const leadWireOffBackgroundColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return 'rgba(21,27,36,0.2)'
    }
    if (styleCode === 1){
        return 'rgba(21,27,36,0.8)'
    }
    if (styleCode === 2){
        return 'rgba(39,49,66,0.7)'
    }
    if (styleCode === 3){
        return 'rgba(39,49,66,0.7)'
    }
    if (styleCode === 4){
        return 'transparent'
    }
    return 'rgba(21,27,36,0.8)'
}

const leadWireOffTextBackgroundColor  = (styleCode,venueSetting) =>{
    if (styleCode === 4){
        return 'rgba(21,27,36,0.5)'
    }
    return 'transparent'
}

const leadWireOffTextColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return '#333333'
    }
    if (styleCode === 4){
        return "#FFFFFF"
    }
    return '#FFFFFF'
}

const spinnerBackgroundColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return "#5A5A5A"
    }
    if (styleCode === 4){
        return "#5A5A5A"
    }
    return 'white';
};

const chartGridLineWidth = (styleCode,venueSetting) =>{
    if (styleCode === 3){
        return 1;
    }
    return 2;
}

const chartPadding = (styleCode) => {
    return 10;
}

const chartGridLineColor = (styleCode,highlight,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        if (highlight){
            return '#595959';
        }
        return '#BFBFBF';
    }

    if (styleCode === 1){
        if (highlight){
            return '#06561c';
        }
        return '#062e1c';
    }
    if (styleCode === 2){
        if (highlight){
            return 'rgba(38,120,82,0.6)';
        }
        return 'rgba(38,85,82,0.5)';
    }

    if (styleCode === 3){
        return '#4c5485';
    }

    if (styleCode === 4){
        if (highlight){
            return '#B1B8B7';
        }
        return '#E0E2E6';
    }

    if (highlight){
        return '#06561c';
    }
    return '#062e1c';

};

const ecgLineColor = (styleCode,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return '#45b854';
        }
    }
    if (styleCode === 1){
        return '#00FF00';
    }
    if (styleCode === 2){
        return '#1FEE8E';
    }
    if (styleCode === 3){
        return '#19d3f3';
    }
    if (styleCode === 4) {
        return '#249D9B';
    }
    return '#00FF00';
};

const gainTextColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return '#000000'
    }
    if (styleCode === 4) {
        return 'transparent';
    }
    return '#FFFFFF'
}

const getEcgLineWidth = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return 1.5
    }
    if (styleCode === 4) {
        return 1.5;
    }
    return 1
}

const noDataTextColor = (styleCode,venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return "#000000"
    }
    if (styleCode === 4) {
        return '#000000';
    }
    return '#FFFFFF'
};

const channelHeadTextStyle = (styleCode, venueSetting) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return {color:"#000000",fontWeight:'bold'}
    }
    if (styleCode === 4){
        return {color:"#000000",fontWeight:'bold'}
    }
    return {color:"#FFFFFF"}
}

const getBpmColor = (homeStyleCode,avgBeat,hrRanger,venueSetting) =>{
    const vs = venueSetting && venueSetting.venueOpenState;
    let color = vs?'#45B854':'#00FF00';
    const {min,max} = hrRanger;
    if (avgBeat) {
        const hr = avgBeat.hr;
        if (hr > 0) {
            if (hr >= min && hr <= max) {
                color = vs?'#45B854':'#00FF00';
            } else if (hr < ViewAdapt2.BPM_LIMIT.min || hr > ViewAdapt2.BPM_LIMIT.max) {
                color = '#ff3838';
            }else {
                color = '#F8EA1D';
            }
        }
    }
    return color;
};

const getChannelBodyBpmPaddingLeft = (homeStyleCode) =>{
    if (homeStyleCode === 3){
        return 9;
    }
    if (homeStyleCode === 4){
        return 5;
    }
    return 0;
};

const getEdrColor = (homeStyleCode,venueSetting) =>{
    if (venueSetting){
        if (venueSetting.venueOpenState){
            return "#06D5E7"
        }
    }
    return '#12e9ea';
};

const getMobileBatteryIconSrc = (homeStyleCode,venueSetting,state,value) =>{
    if (venueSetting && venueSetting.venueOpenState){
        return venuePhoneBatteryIcon;
    }
    if (homeStyleCode === 4){
        return icPhoneIcon;
    }

    if (homeStyleCode === 1 || homeStyleCode === 2 || homeStyleCode === 3){
        if (state === 0){
            return phoneDisconnectDarkIcon2x;
        }
        if (value <= 20) {
            return  phoneBatteryLowDarkIcon2x;
        } else if (value > 20 && value <= 50) {
            return  phoneBattery30DarkIcon2x;
        } else if (value > 50 && value <= 80) {
            return  phoneBattery60DarkIcon2x;
        } else {
            return  phoneBattery100DarkIcon2x;
        }
    }
}

const getBleDeviceBatteryIconSrc = (homeStyleCode,venueSetting,state,value) =>{

    if (venueSetting && venueSetting.venueOpenState){
        return venueDeviceBatteryIcon;
    }
    if (homeStyleCode === 4){
        return icDeviceIcon;
    }
    if (homeStyleCode === 1 || homeStyleCode === 2 || homeStyleCode === 3){
        if (state === 0){
            return deviceDisconnectDarkIcon2x;
        }
        if (value <= 20) {
            return deviceBatteryLowDarkIcon2x;
        } else if (value > 20 && value <= 50) {
            return deviceBattery30DarkIcon2x;
        } else if (value > 50 && value <= 80) {
            return deviceBattery60DarkIcon2x;
        } else {
            return deviceBattery100DarkIcon2x;
        }
    }
}

const getNameIconSrc = (user_gender,homeStyleCode,venueSetting) =>{
    if (homeStyleCode === 4){
        if (user_gender === 0){
            return icNameFemaleIcon;
        }
        return icNameIcon;
    }
    return nameDarkIcon2x;
}

const getAvatarStyle = (homeStyleCode,venueSetting) =>{
    if (homeStyleCode === 4){
        return {
            width:'35px',
            height:'35px',
            border: '1px solid white'
        };
    }
    return {};
};

const HomeStyle = {
    getAvatarStyle,
    getNameIconSrc,
    getMobileBatteryIconSrc,
    getBleDeviceBatteryIconSrc,
    paginationItemBgBackgroundColor,
    metaColor,
    headSpanStyle,
    bodyBackground,
    copyRightTextColor,
    gridGutter,
    toolsTextColor,
    toolsBackgroundStyle,
    batteryTextColor,
    channelBackgroundColor,
    channelBorder,
    leadWireOffBackgroundColor,
    leadWireOffTextBackgroundColor,
    leadWireOffTextColor,
    spinnerBackgroundColor,
    chartGridLineColor,
    chartGridLineWidth,
    chartPadding,
    ecgLineColor,
    gainTextColor,
    getEcgLineWidth,
    noDataTextColor,
    channelHeadTextStyle,
    getBpmColor,
    getEdrColor,
    getChannelBodyBpmPaddingLeft
}

export default HomeStyle
