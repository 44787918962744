/**
 * @name: MobileBPM
 * @author: Seeker
 * @date: 2022-03-19 12:01
 * @description：MobileBPM
 * @update: 2022-03-19 12:01
 */
import React, {Component} from 'react';
import ViewAdapt2 from "./ViewAdapt2";

export default class BPM extends Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    }

    render() {
        const bpm = ViewAdapt2.getChannelBodyBpm(
            this.props.darkModule,
            this.props.homeStyleCode,
            this.props.bpmLarger,
            this.props.edrState,
            this.props.avgBeat,
            this.props.height,
            this.props.bpmTextFontSize,
            this.props.contentObj,
            this.props.bedTextFontSize,
            this.props.bpmWidth,
            this.props.bedWidth,
            this.props.bedShowLeft,
            this.props.hrRanger,
            this.props.venueSetting);
        return <div>
            {bpm}
        </div>

    }

}
