import getDate from "../RealTimeMonitor/modules/Date";
import AbnormalCompat, {AlarmEventCode} from "./AbnormalCompat";

/**
 * @name: DataParseCompat
 * @author: Seeker
 * @date: 2022-01-10 13:14
 * @description：DataParseCompat
 * @update: 2022-01-10 13:14
 */
const _digit = {
    chars: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    str62to10:(value) =>{
        let radix = this.chars.length;
        let valueStr = value.toString();
        let negative = false;
        if (valueStr.startsWith("-")){
            negative = true;
            valueStr = valueStr.substring(1,valueStr.length);
        }
        let len = valueStr.length;
        let decimal = 0;
        for (let i = 0; i < len;i++){
            decimal += this.chars.indexOf(valueStr.charAt(i)) * Math.pow(radix, len - i - 1);
        }
        return negative? "-"+decimal:""+decimal;
    },
};

const v0 = (dataV,edr) => {
    let items = dataV.split('#');
    const len = items.length;
    if (len === 0){
        return undefined;
    }
    let bpm = undefined;
    if (items.length >= 2){
        bpm = {
            hr: parseInt(items[1]),
            type: items.length === 3?items[2]:'N',
            edr,
            date: getDate(1),
            time: new Date().getTime()
        };
    }
    return {
        value:[parseFloat(items[0])],
        bpm,
    }
};

const v1 = (dataV,edr)=>{
    let items = dataV.split('#');
    const len = items.length;
    if (len === 0){
        return undefined;
    }
    const data = {
        value: [parseFloat(items[0])],
    };
    if (len >= 2){
       const hr = parseInt(items[1]);
        if (hr !== -1){
            data.bpm = {
                hr: parseInt(items[1]),
                edr,
            };
        }
        if (len >= 3){
            data.alarmEvent = {
                typeCode:AbnormalCompat.typeCode(items[2],hr),
                time: new Date().getTime(),
            };
        }
    }
    return data;
};

const decode = (value,version) =>{
    if (version === 4){
        let temp = this._Digit.str62to10(value);
        return parseFloat(temp) / 100;
    }
    return parseFloat(value);
};

const v2 = (destinationName,dataV,edr,verion) =>{
    const items = dataV.split(/[#&]/);
    const len = items.length;
    if (len === 0){
        return undefined;
    }
    const temp = items[0].split('+');
    if (temp.length <= 0){
        return undefined;
    }
    const values = [];
    for (let i = 0,length = temp.length;i < length;i++){
        values.push(parseFloat(temp[i]));
    }
    const data = {
        value: values,
    };
    if (len >= 2){
        const item2Child = items[1];
        if (item2Child === 'Z'){
            data.alarmEvent = {
                typeCode:AlarmEventCode.NOISE,
                time: new Date().getTime(),
            };
        }else if (item2Child === 'L'){
            data.alarmEvent = {
                typeCode:AlarmEventCode.LEAD_OFF,
                time: new Date().getTime(),
            };
        }else if (item2Child === 'W'){
            data.alarmEvent = {
                typeCode:AlarmEventCode.WEAK_SIGNAL,
                time: new Date().getTime(),
            };
        }else {
            const hr = parseInt(items[1]);
            if (hr !== -1){
                data.bpm = {
                    hr: parseInt(items[1]),
                    edr,
                };
            }
            if (len >= 3){
                data.alarmEvent = {
                    typeCode:AbnormalCompat.typeCode(items[2],hr),
                    time: new Date().getTime(),
                };
            }
        }
    }
    return data;
};

const v3s = (destinationName,dataV,edr,verion) => v2(destinationName,dataV,edr,verion);

const v3m = (destinationName,dataV,edr,verion) => v2(destinationName,dataV,edr,verion);

const DataParseCompat = {
    v0,v1,v2,v3s,v3m
};

export default DataParseCompat
