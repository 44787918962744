/**
 * @name: Date
 * @author: Seeker
 * @date: 2021-07-28 10:17
 * @description：Date
 * @update: 2021-07-28 10:17
 */

const fnW = (str)=>{
    let num;
    str >= 10? num = str:num="0"+str;
    return num;
};

const getDate = (code = 0) =>{
    const date=new Date();
    const year=date.getFullYear();//当前年份
    const month=date.getMonth();//当前月份
    const data=date.getDate();//天
    const hours=date.getHours();//小时
    const minute=date.getMinutes();//分
    const second=date.getSeconds();//秒
    if (code === 0){
        return year + "年" + fnW((month + 1)) + "月" + fnW(data) + "日 " + fnW(hours) + ":" + fnW(minute) + ":" + fnW(second);
    }else if (code === -1){
        return year + "-" + fnW((month + 1)) + "-" + fnW(data) + " " + fnW(hours) + ":" + fnW(minute) + ":" + fnW(second);
    }
    return fnW(hours) + ":" + fnW(minute) + ":" + fnW(second);
};

export default getDate;
