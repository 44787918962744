/**
 * @name: AsyncMqttClient
 * @author: Seeker
 * @date: 2022-03-22 08:42
 * @description：AsyncMqttClient
 * @update: 2022-03-22 08:42
 */
import {MQTTClient} from "./MQTT";
import mqttWorker from "../worker/MQTTWorker";

export default class AsyncMqttClient extends MQTTClient{

    constructor(refreshChannel) {
        super(refreshChannel);
        this.worker = new mqttWorker().registerCallback(this.handlerECGDataResult,this.handlerExtraDataResult);
    }

    handlerECGDataResult = (result) =>{
        const {destinationName,version,value,needCacheChannelPrintData,deviceModel} = result;
        if (value){
            const channelDataBuffer = this.checkChannelDataBuffer(destinationName,version,deviceModel);
            value.forEach(v => {
                this.cacheData(destinationName,v,channelDataBuffer,needCacheChannelPrintData,deviceModel)
                // channelDataBuffer.data.push(v);
                // this.cachePrintData(v,needCacheChannelPrintData)
            });
        }
    };

    handlerExtraDataResult = (result) =>{
        console.log(`handlerExtraDataResult`,result)
    };

    transformData = (destinationName,decomposeData,needCacheChannelPrintData) =>{
        this.worker.postEcgData(destinationName,decomposeData,needCacheChannelPrintData);
    }
}
