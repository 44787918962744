/**
 * @name: MobileChannelTitle
 * @author: Seeker
 * @date: 2022-03-19 11:02
 * @description：MobileChannelTitle
 * @update: 2022-03-19 11:02
 */

import React, {Component} from 'react';
import ViewAdapt3 from "./ViewAdapt3";

export default class ChannelTitle extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps);
     }

    render() {
        const titleContainerStyle = ViewAdapt3.getChannelCardTitleContainerStyle(
            this.props.clientWidth,
            this.props.nameAndBedLarger,
            this.props.grid[0],
            this.props.venueSetting);
        const title = ViewAdapt3.getChannelCardTitle(
            this.props.contentObj,
            this.props.darkModule,
            this.props.homeStyleCode,
            this.props.item,
            this.props.abnInfo,
            this.props.abnInfoBackColor,
            this.props.flicker,
            this.props.grid[1],
            this.props.clientWidth,
            this.props.nameAndBedLarger,
            this.props.onNameClick,
            this.props.nameTextFontSize,
            this.props.departmentShow,
            this.props.bedShowLeft,
            this.props.bedTextFontSize,
            this.props.batteryShowRule,
            this.props.clearEmergencyAlarmEvent,
            this.props.venueSetting);
        return <div id={'card_title_container'} style={titleContainerStyle}>
            {title}
        </div>
    }
}
