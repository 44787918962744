import React, {Component} from 'react';
import {Button, Divider, List, Radio} from 'antd';
import connect from "react-redux/es/connect/connect";
import {fetchUserRecordList} from "../actions";
import {withRouter} from "react-router-dom";
import Tools from "./Tools";
import {setHomeStyle, setShowModule} from "../../../app/actions";
import {demoChannelList} from "../../demo/data";
import settingStorage from "../../util/setting-storage";
import audioManagerImpl from "../../audioManager";
import {emergencyResidentCode, residentCode, nonEmergencyResidentCode} from "./v2/AlarmEvent";
import ChannelGrid, {MOBILE, CHECKER} from "./v2/ChannelGrid";
import withMenu, {MenuItemId} from "../../../useContextMenu/menu/Menu";
import emitter from "../../../useContextMenu/event/EventEmitter"
import ModalFactory from "../../../useContextMenu/modal/modalFactory";
import MQTTClientFactory from "../../Common/MQTTClientFactory";
import MonitorItem2 from "./v2/MonitorItem2";
import MonitorItem3 from "./v3/MonitorItem3";
import MonitorItem4 from "./v4/MonitorItem4";
import Tools4 from "./v4/Tools4";
import BottomTools4 from "./v4/BottomTools4";
import MobileMonitorItem from "./mobile/MobileMonitorItem3";
import MobileTools3 from "./mobile/MobileTools3";
import isMobile from '../../util/is-mobile'
import debounce from '../../util/debounce'
import Text from "antd/es/typography/Text";
import {LONG_TEST_TIME_LONG} from "./v2/ChannelPrinter";
import HomeStyle from "../../../HomeStyle";

const MobileScreenWidth = 600;

const defaultSettings = {
    fps: 60,//刷新率
    abnSound: true, //提示音开启
    abnSoundInterval: 60, // 提示音间隔
    alarmEvent: residentCode, // 报警事件
    bpmLarger: false,
    nameAndBedLarger: false,
    deptName: [], //科室名称
    promptVoice: 0, //提示音音效

    deptId: [],
    minDelay: 3, // 数据延迟
    darkModule: true, //深色模式
    homeStyleCode: 1,//主页风格样式
    edrState: true, //呼吸率
    colCount: 2, // 列数
    sortWith: 0, // 排序
    hrRanger: {
        min: 50,
        max: 120
    },
    pagerFlipDuration: -1,
    nameComplete: true, // 显示完整姓名
    ratioRuleShow: false,
    nameTextFontSize: 15,
    bedTextFontSize: 15,
    bpmTextFontSize: 40,
    departmentShow: true,
    bedShowLeft: false, //床号左侧显示
    batteryShowRule: 0, // 电量显示规则
    bedNumLength: 3, //床号显示长度
    emergencySound: 0,
    emergencySoundState: true,
    emergencySoundInterval: 0,
    emergencyAlarmEvent: emergencyResidentCode,
    emergencyTextShowTimeLong: 0,
    nonEmergencySound: 1,
    nonEmergencySoundState: true,
    nonEmergencySoundInterval: 60,
    nonEmergencyAlarmEvent: nonEmergencyResidentCode,
    nonEmergencyTextShowTimeLong: 30,
    derivedLead: [],
    gain:0,
    rrLongPause:2,
};

const emptyItem = (key) => {
    return {
        bed_number: null,
        case_number: null,
        dept_id: null,
        dept_name: null,
        end_time: null,
        key: key,
        mach_sn: null,
        mqtt_channel: null,
        rec_id: null,
        start_time: null,
        user_name: null,
        hospital_number: null,
        is_pacemaker: null
    };
};

class MonitorList extends Component {
    constructor() {
        super(...arguments);
        const {companyId, companyName} = this.props;
        const defaultTemp = {...defaultSettings, companyName};
        this.state = {
            mobileScreen: isMobile() || document.body.clientWidth < MobileScreenWidth,
            clientWidth: document.body.clientWidth,
            clientHeight: document.body.clientHeight,
            printChannel: {
                visible: false,
                channel: null,
                timeLong: 30
            },
            gainAdjust: {
                visible: false,
                channel: null,
            },
            checkParams: {
                gainValue: 0,
                baseline: 0
            },
            longTest: {
                testing: false,
                minute: 0
            },
            renderEnable: true,
            loading: false,
            page: 1,
            demoShow: false, //演示数据
            duration: 10, // 自动翻页
            abnSoundDuration: 3, // 提示音时长
            ...settingStorage.fillSetState(defaultTemp, companyId)
        };
        this.mqtt = MQTTClientFactory.impl(this.refreshChannel);
        this.pagerFliptimer = null;//自动翻页计时器
        this.refreshChannleTimeoutId = null;
        this.eventEmitter = null;
        this.startPagerFlipInterval = this.startPagerFlipInterval.bind(this);
        this.closePagerFlipInterval = this.closePagerFlipInterval.bind(this);
        this.setParams = this.setParams.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refreshDataInterval = this.refreshDataInterval.bind(this);
        this.updateWindow = this.updateWindow.bind(this);
        this.sort = this.sort.bind(this);
        this.checkState = this.checkState.bind(this);
        this.longTestIntervalId = undefined;
    }

    componentDidMount() {
        if (this.state.mobileScreen) {
            this.setState({
                edrState: false
            })
        }
        window.addEventListener('resize', this.updateWindow);
        this.props.onLoad(this.state.deptId, this.state.page, this.getTotalCount(), this.sort(), 'componentDidMount');
        this.changeTheme(this.state.homeStyleCode);
        // audioManagerImpl.setWarnDuration(this.state.abnSoundDuration);
        // audioManagerImpl.setCanPlay(this.state.abnSound);
        // this.props.setDarkModule(this.state.darkModule,this.getVenueSettingDelegate());
        this.props.setHomeStyle(this.state.homeStyleCode, this.getVenueSettingDelegate())
        this.mqttSet();
        // TODO:自动翻页和刷新在正式环境下应该放开。
        this.startPagerFlipInterval();
        this.refreshDataInterval(this.timeOut(), this.pointCount());
        settingStorage.clearMqttMsg();
        this.eventEmitter = emitter.addListener("callMe", ({id, channel}) => {
            console.log(`id = ${id},channel = `,channel)
            if (id === MenuItemId.CHANNEL_CLOSE) {
                ModalFactory.closeChannel(channel, this.deleteChannel);
            } else if (id === MenuItemId.CHANNEL_PRINT) {
                this.setPrintChannelState(true, channel, 30)
            } else if (id === MenuItemId.CHANNEL_GAIN_ADJUST) {
                this.channelSetting(true, channel);
            }
        });
    }

    channelSetting = (visible, channel) => {
        this.setState({
            gainAdjust: {
                visible: visible,
                channel: channel,
            }
        }, () => {
            if (this.state.gainAdjust.visible) {
                this.closePagerFlipInterval();
            } else {
                this.startPagerFlipInterval();
                this.refreshDataInterval(this.timeOut(), this.pointCount());
            }
        })
    };

    hideGainAdjust = () => this.channelSetting(false, null);

    hideChannelPrinter = () => this.setPrintChannelState(false, null);

    setPrintChannelState = (visible, channel, timeLong) => {
        this.setState({
            printChannel: {
                visible: visible,
                channel: channel,
                timeLong: timeLong
            }
        }, () => {
            if (this.state.printChannel.visible) {
                this.closePagerFlipInterval();
            } else {
                this.startPagerFlipInterval();
                this.refreshDataInterval(this.timeOut(), this.pointCount());
            }
            this.mqtt.setPrintChannel(this.state.printChannel.channel, this.state.printChannel.timeLong);
        })
    };

    mqttSet = () => {

        // audioManagerImpl.setWarnDuration(this.state.abnSoundDuration);
        // audioManagerImpl.setCanPlay(this.state.abnSound);
        // audioManagerImpl.setVoiceValue(this.state.promptVoice);

        audioManagerImpl.setWarnSetting({
            emergencySound: this.state.emergencySound,
            emergencySoundState: this.state.emergencySoundState,

            nonEmergencySound: this.state.nonEmergencySound,
            nonEmergencySoundState: this.state.nonEmergencySoundState,
        })

        this.mqtt.setDemoShow(this.state.demoShow);
        this.mqtt.setMinDelay(this.state.minDelay);
    };

    sendMsgToAppClient = (channelId, code) => {
        this.mqtt.sendMsgToAppClient(channelId, code + "&" + this.props.companyId);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindow);
        if (this.pagerFliptimer !== null) {
            clearInterval(this.pagerFliptimer);
        }
        if (this.refreshTimer !== null) {
            clearInterval(this.refreshTimer);
        }
        if (this.eventEmitter) {
            emitter.removeListener(this.eventEmitter, () => {
            });
        }
    }

    checkState(curState, nextState) {
        for (let key in curState) {
            if (curState[key] !== nextState[key]) {
                return true;
            }
        }
        return false;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.checkState(this.state, nextState)) {
            return true;
        }
        const curChannels = this.props.recList;
        const nextChannels = nextProps.recList;
        if (curChannels.length !== nextChannels.length) {
            return true;
        }
        for (let i = 0, len = curChannels.length; i < len; i++) {
            if (curChannels[i].mqtt_channel !== nextChannels[i].mqtt_channel
                || curChannels[i].hospital_number !== nextChannels[i].hospital_number
                || curChannels[i].bed_number !== nextChannels[i].bed_number
                || curChannels[i].order_end_time !== nextChannels[i].order_end_time) {
                return true;
            }
        }
        return false;
    }

    // 修复刷新时，最后一页没有数据显示空的问题
    componentWillReceiveProps(nextProps, nextContext) {
        const {page, listLength, loading} = nextProps;
        if (!loading && (page !== undefined && page > 1 && listLength === 0)) {
            console.log(`componentWillReceiveProps()called: 当前页 ${page} 没有数据，从第一页开始加载！`)
            this.onPageChange(1);
        }
    }

    _updateWindow = () => {
        const clientWidth = document.body.clientWidth;
        const clientHeight = document.body.clientHeight;
        const mobileScreen = isMobile() || clientWidth < MobileScreenWidth;
        const edrState = !mobileScreen;
        this.setState({
            clientWidth,
            clientHeight,
            edrState,
            mobileScreen
        }, () => {
            console.log(`_updateWindow()called: clientWidth = ${this.state.clientWidth},clientHeight = ${this.state.clientHeight}`)
            this.props.onLoad(this.state.deptId, this.state.page, this.getTotalCount(), this.sort(), '_updateWindow');
        })
    };

    updateWindow = debounce(this._updateWindow, 500);

    containCurChannel = (channelId, hospitalNum, bedNun, orderEndTime) => {
        let isContain = false;
        let recList = this.props.recList
        for (let i = 0, len = recList.length; i < len; i++) {
            if (recList[i].mqtt_channel === channelId) {
                isContain = true;
                if (recList[i].hospital_number !== hospitalNum
                    || recList[i].bed_number !== bedNun
                    || recList[i].order_end_time !== orderEndTime) {
                    isContain = false;
                }
                break;
            }
        }
        return isContain;
    };

    // 新的通道添加时，只针对本医院本科室的通道进行刷新
    refreshChannel = (code, channelId, companyId, deptId, hospitalNum, bedNun, orderEndTime) => {
        if (companyId === undefined || deptId === undefined) {
            const temp = channelId.split('-');
            companyId = temp[0];
            deptId = temp[1];
        }
        let loginCompanyId = this.props.companyId; // 用于判断是否是admin账号
        let isContain = this.containCurChannel(channelId, hospitalNum, bedNun, orderEndTime);
        let sameDept = this.state.deptId.length === 0 || this.state.deptId.indexOf(Number.parseInt(deptId)) >= 0;
        let sameCompany = Number.parseInt(companyId) === Number.parseInt(loginCompanyId);
        console.log(`refreshChannel()called: code = ${code},channelId = ${channelId},isContain = ${isContain},channelCompanyId = ${companyId},channelDeptId = ${deptId}
                        ,loginCompanyId = ${loginCompanyId},filterId = `, this.state.deptId, 'sameCompany = ', sameCompany, 'sameDept = ', sameDept);
        // 会引起界面数据重绘问题
        if (code === 0) { // 通道结束
            settingStorage.removeChannelSetting(channelId);
            this._refreshChannel();
        } else if (code === 2) {
            settingStorage.removeChannelSetting(channelId);
        } else {
            if (((loginCompanyId === 1) || sameCompany) && sameDept) {
                if (!isContain) {
                    this._refreshChannel();
                }
            }
        }
    };

    _refreshChannel = () => {
        if (!this.refreshChannleTimeoutId) {
            this.refreshChannleTimeoutId = setTimeout(() => {
                this.refreshChannleTimeoutId = null;
                this.refreshData(this.state.page);
            }, 3000);
        }
    };

    timeOut = () => {
        return (1000 / this.state.fps) >> 0;
    };

    pointCount = () => {
        return ((1000 / this.state.fps / 4) >> 0) + 1;
    };

    changeTheme(homeStyleCode) {
        if (!window.less.modifyVars) {
            return;
        }
        const venueSetting = this.getVenueSettingDelegate();
        window.less.modifyVars({
            '@component-background': '#273142',
            '@input-bg': '#273142',
            '@input-color': '#FFFFFF',
            '@input-disabled-bg': '#273142',
            '@input-disabled-color': '#FFFFFF',
            '@border-color-base': '#4A5566',
            '@text-color': '#FFFFFF',
            '@disabled-color': '#d9d9d9',
            '@switch-bg': '#273142',
            '@select-item-selected-color': '#1890FF',
            '@select-item-selected-bg': '#273142',
            '@select-item-active-bg': '#1890FF',
            '@select-selection-item-bg': '#FF0000',
            '@select-background': '#273142',
            '@link-color': '#FFFFFF',
            '@item-hover-bg': '#1890FF',
            '@modal-content-bg': '#273142',
            '@modal-close-color': '#FFFFFF',
            '@label-color': '#FFFFFF',
            '@card-head-color': '#FFFFFF',
            '@tooltip-bg': '#1890FF',
            '@pagination-item-bg': HomeStyle.paginationItemBgBackgroundColor(homeStyleCode, venueSetting),
            '@pagination-item-bg-active': HomeStyle.paginationItemBgBackgroundColor(homeStyleCode, venueSetting),
        }).then(() => {
            console.log(`changeTheme() complete!!!!`)
            let meta = document.querySelector("meta[name=theme-color]");
            meta.content = HomeStyle.metaColor(homeStyleCode);
        }).catch((err) => {
            console.log(err)
        })
    }

    sort() {
        if (this.state.sortWith === 1) {
            return {
                sort: 'bed_number+0,rec_id',
                order: 'asc,asc'
            }
        } else if (this.state.sortWith === 2) {
            return {
                sort: 'user_name,rec_id',
                order: 'asc,asc'
            }
        } else if (this.state.sortWith === 3) {
            return {
                sort: 'dept_name,rec_id',
                order: 'asc,asc'
            }
        }
        return {
            sort: 'rec_id',
            order: 'asc'
        }
    }

    setParams(params) {
        if (params) {
            const companyId = this.props.companyId;
            new Promise((resolve, reject) => {
                const tempState = {...settingStorage.updateSettingParams(params, companyId), page: 1};
                resolve(settingStorage.updateCacheState(this.state, tempState))
            }).then((newState) => {
                this.setState(newState, () => {
                    // this.props.setDarkModule(this.state.darkModule,this.getVenueSettingDelegate());
                    this.props.setHomeStyle(this.state.homeStyleCode, this.getVenueSettingDelegate())
                    this.changeTheme(this.state.homeStyleCode);
                    this.mqttSet();
                    this.startPagerFlipInterval();
                    this.props.onLoad(this.state.deptId, this.state.page, this.getTotalCount(), this.sort(), 'setParams');
                });
            }).catch(err => {
                console.error('setParams()called error:', err);
            });
        }
    }

    refreshData(from) {
        if (from) {
            this.props.onLoad(this.state.deptId, from, this.getTotalCount(), this.sort(), 'refreshChannel');
        } else {
            let currentPage = this.state.page;
            let totalPage;
            const pageSize = this.getTotalCount();
            if (this.props.total % pageSize === 0) {
                totalPage = this.props.total / pageSize;
            } else {
                totalPage = Math.floor(this.props.total / pageSize) + 1;
            }
            if (totalPage === currentPage) {
                currentPage = 1;
                this.props.onLoad(this.state.deptId, currentPage, this.getTotalCount(), this.sort(), 'refreshData_1');
            } else {
                if (currentPage < totalPage) {
                    currentPage++;
                    this.props.onLoad(this.state.deptId, currentPage, this.getTotalCount(), this.sort(), 'refreshData_2')
                }
            }
            if (currentPage !== this.state.page) {
                this.setState({
                    page: currentPage
                })
            }
        }
    }

    onPageChange(page) {
        this.setState({
            page,
        }, () => {
            console.log(`onPageChange()called: curPage = ${this.state.page}`);
            this.startPagerFlipInterval();
            this.props.onLoad(this.state.deptId, this.state.page, this.getTotalCount(), this.sort());
        })
    }

    closePagerFlipInterval() {
        if (this.pagerFliptimer != null) {
            clearInterval(this.pagerFliptimer);
            this.pagerFliptimer = null;
        }
    }

    startPagerFlipInterval() {
        this.closePagerFlipInterval();
        if (this.state.pagerFlipDuration !== -1) {
            this.pagerFliptimer = setInterval(() => {
                this.refreshData();
            }, this.state.pagerFlipDuration * 1000)
        }
    }

    refreshDataInterval(timeout, count) {
        if (this.state.printChannel.visible || this.state.gainAdjust.visible) {
            return;
        }
        this.refreshTimer = setTimeout(() => {
            const start = new Date().getTime();
            if (this.child) {
                this.child.forEach(value => {
                    value.refresh(count);
                })
            }
            const end = new Date().getTime();
            const duration = end - start;
            const tOut = this.timeOut();
            const pCount = this.pointCount();
            // console.log(`timeout = ${timeout},count = ${count},duration = ${duration},tOut = ${tOut}`);
            // console.log(`duration-tOut = ${duration-tOut},count = ${Math.ceil((duration-tOut)/4)}，ccc = ${(duration-tOut)/tOut * pCount}`);
            if (duration > tOut) {
                let nextCount = Math.floor((duration - tOut) / tOut * pCount) + pCount;
                if (this.state.demoShow) {
                    nextCount++;
                }
                this.refreshDataInterval(0, nextCount);
            } else {
                this.refreshDataInterval(tOut - duration, pCount);
            }
        }, timeout)
    };

    bindRef = (ref) => {
        if (!this.child) {
            this.child = [];
        }
        this.child.push(ref);
    };

    unBindRef = (ref) => {
        if (this.child && this.child.length) {
            const index = this.child.indexOf(ref);
            if (index >= 0) {
                this.child.splice(index, 1);
            }
        }
    };

    deleteChannel = (rec_id, channelId) => {
        settingStorage.removeChannelSetting(channelId);
        this.props.onLoad(this.state.deptId, this.state.page, this.getTotalCount(), this.sort(), 'deleteChannel', rec_id);
    };

    getColCount = () => {
        return this.state.mobileScreen ? MOBILE : this.state.colCount;
    };

    getTotalCount = () => {
        return ChannelGrid.getTotalCount(this.getColCount());
    };

    isCheckMode = () => {
        return this.state.colCount === CHECKER
    }

    channelSettingParams = (mqtt_channel) => {
        const setting = settingStorage.getChannelSetting(mqtt_channel, this.state.hrRanger, this.state.emergencySoundInterval);
        if (this.isCheckMode()) {
            return {
                ...setting,
                gain: this.state.checkParams.gainValue
            }
        }
        return setting;
    }

    onRadioChange = (e) => {
        this.setState({
            checkParams: {
                gainValue: e.target.value,
                baseline: this.state.checkParams.baseline
            }
        }, () => {
            console.log(`onRadioChange()called:`, this.state.checkParams)
        })
    }

    onBaselineChange = (value) => {
        let pre = this.state.checkParams.baseline;
        if (value === 0) {
            pre = 0;
        } else {
            pre += value;
        }
        this.setBaseline(pre)
    }

    setBaseline = (value) => {
        this.setState({
            checkParams: {
                baseline: value,
                gainValue: this.state.checkParams.gainValue
            }
        }, () => {
            console.log(`setBaseline()called:`, this.state.checkParams)
        })
    }

    calculateBaseLine = () => {
        console.log(`calculateBaseLine()called:`)
        this.mqtt.calculateBaseLine(this.props.recList[0], (value) => {
            console.log(`calculateBaseLine()called: value = ${value}`)
            this.setBaseline(value * 100)
            this.mqtt.calculateBaseLine(undefined, undefined)
        })
    }

    onDataToPrint = (timeLong) => {
        this.setPrintChannelState(true, this.props.recList[0], timeLong)
    }

    onChartRenderState = (enable) => {
        this.setState({
            renderEnable: enable
        }, () => {
            this.mqtt.setCanCacheData(this.state.renderEnable)
        })
    }

    onLongTestStart = (start) => {
        if (this.state.longTest.testing !== start) {
            this.setState({
                longTest: {
                    testing: start,
                    minute: 0
                }
            }, () => {
                console.log(`onLongTestStart()called:`, this.state.longTest)
                if (this.state.longTest.testing) {
                    this.longTestIntervalId = setInterval(() => {
                        this.setLongTestChartVisible()
                    }, 60 * 1000);
                } else {
                    if (this.longTestIntervalId) {
                        clearInterval(this.longTestIntervalId);
                        this.longTestIntervalId = undefined;
                    }
                }
            })
        }
    }

    setLongTestChartVisible = () => {
        const preMinute = this.state.longTest.minute
        this.setState({
            longTest: {
                testing: true,
                minute: preMinute + 1
            }
        }, () => {
            if (this.showLongTestChart()) {
                this.setPrintChannelState(true, this.props.recList[0], LONG_TEST_TIME_LONG)
            } else {
                this.mqtt.setPrintChannel(undefined, LONG_TEST_TIME_LONG);
            }
        })
    }

    showLongTestChart = () => {
        const minute = this.state.longTest.minute
        return minute === 1
            || minute === 2
            || minute === 5
            || minute === 10
            || minute === 20
            || minute === 30
            || minute === 45
            || minute === 60
            || minute === 120
            || minute === 180
            || minute === 24 * 60
    }

    getVenueSettingDelegate = () => {
        return {"venueOpenState":this.state.homeStyleCode === 5};
    }

    render() {
        const gridArgs = ChannelGrid.getGridArgs(this.getColCount());
        const yGridCount = ChannelGrid.yGridCount(this.getColCount());
        let column = gridArgs[1];
        const list = [];
        if (this.state.demoShow) {
            list.push(...demoChannelList);
        } else {
            list.push(...this.props.recList);
            this.mqtt.updateVisibleChannel(list);
        }
        return (
            <div>
                {
                    this.state.mobileScreen ?
                        <div>
                            <MobileTools3
                                bindRef={this.bindRef}
                                unBindRef={this.unBindRef}
                                totalCount={this.getTotalCount()}
                                mngDept={this.props.mngDept}
                                list={list}
                                page={this.state.page}
                                total={this.state.demoShow ? list.length : this.props.total}
                                onPageChange={this.onPageChange}
                                setParams={this.setParams}
                                {...this.state}
                                venueSetting={this.getVenueSettingDelegate()}
                                grid={gridArgs}
                            />
                            <List
                                grid={{
                                    gutter: [0, 8], column
                                }}
                                dataSource={list}
                                renderItem={item => (
                                    <List.Item style={{marginBottom: 0}}>
                                        <MobileMonitorItem
                                            bindRef={this.bindRef}
                                            unBindRef={this.unBindRef}
                                            useContextTrigger={this.props.useContextTrigger}
                                            data={item}
                                            deleteChannel={this.deleteChannel}
                                            sendMsgToAppClient={this.sendMsgToAppClient}
                                            {...this.state}
                                            venueSetting={this.getVenueSettingDelegate()}
                                            grid={gridArgs}
                                            yGridCount={yGridCount}
                                            hasChannelSettingKey={settingStorage.hasChannelSettingKey(item.mqtt_channel)}
                                            channelSetting={this.channelSettingParams(item.mqtt_channel)}
                                            baseline={this.state.checkParams.baseline / 100}
                                            forceClearPlotData={!this.isCheckMode()}/>
                                    </List.Item>
                                )}
                            />
                        </div> :
                        this.state.homeStyleCode === 1 || this.state.homeStyleCode === 2 || this.state.homeStyleCode === 5?
                            <div>
                                <Tools
                                    bindRef={this.bindRef}
                                    unBindRef={this.unBindRef}
                                    totalCount={this.getTotalCount()}
                                    mngDept={this.props.mngDept}
                                    list={list}
                                    page={this.state.page}
                                    total={this.state.demoShow ? list.length : this.props.total}
                                    onPageChange={this.onPageChange}
                                    setParams={this.setParams}
                                    {...this.state}
                                    venueSetting={this.getVenueSettingDelegate()}
                                    grid={gridArgs}
                                />
                                <List
                                    grid={{
                                        gutter: HomeStyle.gridGutter(this.state.homeStyleCode), column
                                    }}
                                    dataSource={list}
                                    renderItem={item => (
                                        <List.Item style={{marginBottom: 0}}>
                                            <MonitorItem2
                                                gutterSize={HomeStyle.gridGutter(this.state.homeStyleCode)}
                                                bindRef={this.bindRef}
                                                unBindRef={this.unBindRef}
                                                useContextTrigger={this.props.useContextTrigger}
                                                data={item}
                                                deleteChannel={this.deleteChannel}
                                                sendMsgToAppClient={this.sendMsgToAppClient}
                                                {...this.state}
                                                venueSetting={this.getVenueSettingDelegate()}
                                                grid={gridArgs}
                                                yGridCount={yGridCount}
                                                hasChannelSettingKey={settingStorage.hasChannelSettingKey(item.mqtt_channel)}
                                                channelSetting={this.channelSettingParams(item.mqtt_channel)}
                                                baseline={this.state.checkParams.baseline / 100}
                                                forceClearPlotData={!this.isCheckMode()}/>
                                        </List.Item>
                                    )}
                                />
                            </div> :
                            this.state.homeStyleCode === 3 ?
                                <div>
                                    <Tools
                                        bindRef={this.bindRef}
                                        unBindRef={this.unBindRef}
                                        totalCount={this.getTotalCount()}
                                        mngDept={this.props.mngDept}
                                        list={list}
                                        page={this.state.page}
                                        total={this.state.demoShow ? list.length : this.props.total}
                                        onPageChange={this.onPageChange}
                                        setParams={this.setParams}
                                        {...this.state}
                                        venueSetting={this.getVenueSettingDelegate()}
                                        grid={gridArgs}
                                    />
                                    <List
                                        grid={{
                                            gutter: HomeStyle.gridGutter(this.state.homeStyleCode), column
                                        }}
                                        dataSource={list}
                                        renderItem={item => (
                                            <List.Item style={{marginBottom: 0}}>
                                                <MonitorItem3
                                                    gutterSize={HomeStyle.gridGutter(this.state.homeStyleCode)}
                                                    bindRef={this.bindRef}
                                                    unBindRef={this.unBindRef}
                                                    useContextTrigger={this.props.useContextTrigger}
                                                    data={item}
                                                    deleteChannel={this.deleteChannel}
                                                    sendMsgToAppClient={this.sendMsgToAppClient}
                                                    {...this.state}
                                                    venueSetting={this.getVenueSettingDelegate()}
                                                    grid={gridArgs}
                                                    yGridCount={yGridCount}
                                                    hasChannelSettingKey={settingStorage.hasChannelSettingKey(item.mqtt_channel)}
                                                    channelSetting={this.channelSettingParams(item.mqtt_channel)}
                                                    baseline={this.state.checkParams.baseline / 100}
                                                    forceClearPlotData={!this.isCheckMode()}/>
                                            </List.Item>
                                        )}
                                    />
                                </div> :
                                this.state.homeStyleCode === 4 ?
                                    <div>
                                        <Tools4
                                            bindRef={this.bindRef}
                                            unBindRef={this.unBindRef}
                                            totalCount={this.getTotalCount()}
                                            mngDept={this.props.mngDept}
                                            list={list}
                                            page={this.state.page}
                                            total={this.state.demoShow ? list.length : this.props.total}
                                            onPageChange={this.onPageChange}
                                            setParams={this.setParams}
                                            {...this.state}
                                            venueSetting={this.getVenueSettingDelegate()}
                                            grid={gridArgs}
                                        />
                                        <List
                                            grid={{
                                                gutter: HomeStyle.gridGutter(this.state.homeStyleCode), column
                                            }}
                                            dataSource={list}
                                            renderItem={item => (
                                                <List.Item style={{marginBottom: 0}}>
                                                    <MonitorItem4
                                                        gutterSize={HomeStyle.gridGutter(this.state.homeStyleCode)}
                                                        bindRef={this.bindRef}
                                                        unBindRef={this.unBindRef}
                                                        useContextTrigger={this.props.useContextTrigger}
                                                        data={item}
                                                        deleteChannel={this.deleteChannel}
                                                        sendMsgToAppClient={this.sendMsgToAppClient}
                                                        {...this.state}
                                                        venueSetting={this.getVenueSettingDelegate()}
                                                        grid={gridArgs}
                                                        yGridCount={yGridCount}
                                                        hasChannelSettingKey={settingStorage.hasChannelSettingKey(item.mqtt_channel)}
                                                        channelSetting={this.channelSettingParams(item.mqtt_channel)}
                                                        baseline={this.state.checkParams.baseline / 100}
                                                        forceClearPlotData={!this.isCheckMode()}/>
                                                </List.Item>
                                            )}
                                        />
                                        <BottomTools4
                                            bindRef={this.bindRef}
                                            unBindRef={this.unBindRef}
                                            totalCount={this.getTotalCount()}
                                            mngDept={this.props.mngDept}
                                            list={list}
                                            page={this.state.page}
                                            total={this.state.demoShow ? list.length : this.props.total}
                                            onPageChange={this.onPageChange}
                                            setParams={this.setParams}
                                            {...this.state}
                                            venueSetting={this.getVenueSettingDelegate()}
                                            grid={gridArgs}
                                        />
                                    </div> :
                                    null
                }

                {
                    this.isCheckMode() ?
                        <div style={{width: '100%', marginTop: 20, display: 'flex', justifyContent: 'justify'}}>
                            <div style={{width: 'initial'}}>
                        <span>
                        <Text style={{marginRight: 20, fontSize: 18}}>增益调整：</Text>
                        <Radio.Group defaultValue={this.state.checkParams.gainValue} buttonStyle="solid"
                                     onChange={this.onRadioChange}>
                            <Radio.Button value={3} style={{color: 'white'}}>5mm/mv</Radio.Button>
                            <Radio.Button value={0} style={{color: 'white'}}>10mm/mv</Radio.Button>
                            <Radio.Button value={1} style={{color: 'white'}}>20mm/mv</Radio.Button>
                        </Radio.Group>
                    </span>
                                <br/><br/>
                                <span>
                        <Text style={{marginTop: 100, marginRight: 20, fontSize: 18}}>基线调整：</Text>
                        <Button
                            style={{marginRight: 20}}
                            onClick={() => {
                                this.onBaselineChange(5)
                            }}>下移 ( -0.05mv )</Button>

                        <Button
                            onClick={() => {
                                this.onBaselineChange(-5)
                            }}>上移 ( +0.05mv )</Button>

                    </span>

                                <br/><br/>
                                <span>
                         <Text style={{marginTop: 100, marginRight: 20, fontSize: 18}}>APP端滤波关闭后请校准基线：</Text>
                            <Button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    this.calculateBaseLine()
                                }}>校准基线</Button>
                            </span>

                                <br/><br/>
                                <span>
                         <Text style={{marginTop: 100, marginRight: 20, fontSize: 18}}>APP端滤波开启后请重置基线：</Text>
                            <Button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    this.onBaselineChange(0)
                                }}>重置基线</Button>
                            </span>

                                <br/><br/>

                                <span>
                        <Text style={{marginTop: 100, marginRight: 20, fontSize: 18}}>心电图打印：</Text>

                            <Button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    this.onDataToPrint(12)
                                }}>12秒数据打印</Button>

                        <Button
                            onClick={() => {
                                this.onDataToPrint(30)
                            }}>30秒数据打印</Button>

                        <Button
                            style={{marginLeft: 20, marginRight: 20}}
                            onClick={() => {
                                this.onDataToPrint(60)
                            }}>1分钟数据打印</Button>
                    </span>

                                <br/><br/>
                                <span>
                         <Text style={{marginTop: 100, marginRight: 20, fontSize: 18}}>心电图绘制状态：</Text>
                            <Button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    this.onChartRenderState(true)
                                }}>开始绘制</Button>
                                 <Button
                                     style={{marginRight: 20}}
                                     onClick={() => {
                                         this.onChartRenderState(false)
                                     }}>暂停绘制</Button>
                            </span>
                            </div>
                            <div style={{width: '1px', backgroundColor: 'white', marginLeft: 50, marginRight: 50}}/>
                            <div style={{width: 'initial', textAlign: 'center'}}>
                            <span>
                            <Button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    this.onLongTestStart(true)
                                }}>开始24小时测试</Button>
                                 <Button
                                     style={{marginRight: 20}}
                                     onClick={() => {
                                         this.onLongTestStart(false)
                                     }}>结束24小时测试</Button>
                                {
                                    this.state.longTest.testing ? <Text style={{
                                        color: 'red',
                                        fontSize: '18px'
                                    }}>正在进行24小时测试，已运行 {this.state.longTest.minute} 分钟</Text> : null
                                }
                            </span>
                            </div>
                        </div>
                        : null
                }

                {
                    ModalFactory.printChannel(this.state.printChannel, this.state.clientWidth, this.state.clientHeight, this.state.darkModule, this.hideChannelPrinter, this.getVenueSettingDelegate())
                }
                {
                    ModalFactory.channelSetting(this.state.gainAdjust, this.hideGainAdjust, this.state.hrRanger, this.state.emergencySoundInterval)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let loading = false;
    let data = state.main.monitor;
    let recList = [];
    if (data) {
        loading = data.loading;
        if (data.list) {
            let list = data.list.map(item => ({...item, key: item.mqtt_channel}));
            recList.push(...list);
        }
    }
    const listLength = recList.length;
    for (let i = 0, len = data.totalCount - listLength; i < len; i++) {
        recList.push(emptyItem(listLength + i));
    }
    const total = data.total;
    let userInfo = state.admin.userInfo;
    let mngDept = [];
    let companyId = -1;
    let deptId = -1;
    let companyName = '';
    if (userInfo) {
        mngDept = userInfo.mngDept;
        companyId = userInfo.companyId;
        deptId = userInfo.deptId;
        companyName = userInfo.companyName;
    }
    let page = data.page;
    return {
        companyId,
        companyName,
        deptId,
        page,
        loading,
        mngDept,
        recList,
        total,
        listLength
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: (deptId, page, totalCount, sort, from, recId) => {
            console.log(`onLoad()called : page = ${page},totalCount = ${totalCount},sort = ${sort},from = ${from},recId = ${recId}`)
            dispatch(fetchUserRecordList(deptId, page, totalCount, sort, recId));
        },
        setDarkModule: (checked, venueSetting) => {
            console.log(`setDarkModule()called:checked = ${checked},venueSetting = `, venueSetting)
            dispatch(setShowModule(checked, venueSetting))
        },
        setHomeStyle: (homeStyleCode, venueSetting) => {
            console.log(`setHomeStyle()called:homeStyleCode = ${homeStyleCode},venueSetting = `, venueSetting)
            dispatch(setHomeStyle(homeStyleCode, venueSetting))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMenu(MonitorList)));
