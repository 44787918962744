import React,{Component}from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import Loadable from 'react-loadable';
//import HistoryRecord from "./main/RealTimeMonitor/HistoryRecord";
import {HistoryRecord} from "./main"
import {MqttTest} from "./main";
import {ElementTest} from "./main";
import {ExternAccess} from './main';
//import unloadEvent from './UnloadEvent';
// import {MQTTClient} from './main/Common/MQTT';

const LoadableLogin = Loadable({
    loader: () => import('./login/modules/login'),
    loading: Loading,
});
const LoadableAdmin = Loadable({
    loader: () => import ('./app/view'),
    loading: Loading
});

class App extends Component{
  constructor(){
    super(...arguments);
    this.keepalive = this.keepalive.bind(this);
      // this.mqtt = new MQTTClient();
  }
  componentDidMount(){
    document.title='心电遥测中央监护软件';
    this.keepalive();
  }
  keepalive() {
      setTimeout(() => {
          axios.post('/keeplive.action').then((res) => {
              // console.log('keepalive response', res.data);
          }).catch((err) => {
              console.error('keep alive error', err);
          })
          this.keepalive();
      }, 60 * 1000 * 3);
  }
  render(){
    return(
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/admin" />)} />
            <Route exact path="/admin/main/history/record" component={HistoryRecord}/>
            <Route exact path="/admin/main/elementTest" component={ElementTest}/>
            <Route exact path="/admin/main/mqttTest" component={MqttTest}/>
            <Route exact path="/access" component={ExternAccess}/>
            <Route path="/login" component={LoadableLogin} />
            <Route exact path="/admin" render={() => (<Redirect to="/admin/main" />)} />
            <Route path="/admin" component={LoadableAdmin} />
        </Switch>
    )
  }
}
export default App;
