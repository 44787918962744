/**
 * @name: is-Function
 * @author: Seeker
 * @date: 2021-05-23 14:45
 * @description：is-Function
 * @update: 2021-05-23 14:45
 */
import isType from './is-type';
export default (value) => {
    return isType(value, 'Function');
};