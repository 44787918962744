import React, {Component} from 'react';
import {Select, Switch, Button, Form, Slider, Drawer, Typography} from 'antd';
import MobileViewAdapt3 from "./MobileViewAdapt3";
import MobileConstantSetting from "./MobileConstantSetting";
import {DoubleRightOutlined} from "@ant-design/icons";
import MobileAbnormalEventSetting from "./MobileAbnormalEventSetting";
import './style.css'
import MobileFormItem from "./MobileFormItem";
const Option = Select.Option;
const FormItem = Form.Item;

export default class BasicSettingWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childrenDrawer: false,
            ...props
        };
    }

    showChildrenDrawer = () => {
        this.setState({
            childrenDrawer: true
        });
    };

    hideChildrenDrawer = () => {
        this.setState({
            childrenDrawer: false
        });
    };

    handleSubmit = (values) => {
        console.log('handleSubmit ==== ');
        console.log(values);
        this.props.setParams(MobileConstantSetting.filter(values));
        this.props.onOK();
    }

    render() {

        const sizeType = MobileConstantSetting.sizeType(this.props.clientWidth);
        // const labelFontSize = MobileConstantSetting.labelFontSize(this.props.clientWidth);

        return (
            <Form.Provider onFormFinish={(name, {values, forms}) => {
                if (name === 'basicForm') {
                    let hrRanger = values.hrRanger;
                    this.handleSubmit({
                        ...this.state,
                        ...values,
                        hrRanger: {
                            min: hrRanger[0],
                            max: hrRanger[1]
                        },
                    })
                } else if (name === 'abnEventSettingForm') {
                    this.setState({
                        ...values
                    })
                }
            }}>

                <Form
                    name={'basicForm'}
                    initialValues={{
                        ...this.state,
                        hrRanger: [this.props.hrRanger.min, this.props.hrRanger.max],
                    }}>

                    <MobileFormItem
                        label={'纯黑背景模式'}
                        item={
                            <FormItem
                                name={'darkModule'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'显示每秒分割线'}
                        item={
                            <FormItem
                                name={'ratioRuleShow'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'床号左侧独立显示'}
                        item={
                            <FormItem
                                name={'bedShowLeft'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'显示完整姓名'}
                        item={
                            <FormItem
                                name={'nameComplete'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'显示科室名称'}
                        item={
                            <FormItem
                                name={'departmentShow'}
                                valuePropName={'checked'}
                            >
                                <Switch size={sizeType} checkedChildren="是" unCheckedChildren="否"/>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'正常心率区间'}
                        item={
                            <FormItem
                                name={'hrRanger'}
                            >
                                <Slider
                                    range={true}
                                    min={MobileViewAdapt3.BPM_LIMIT.min}
                                    max={MobileViewAdapt3.BPM_LIMIT.max}
                                    marks={MobileConstantSetting.sliderMarks(this.props.hrRanger)}
                                />
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'电量显示规则'}
                        item={
                            <FormItem
                                name={'batteryShowRule'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'always_show'}
                                            value={0}>总是显示</Option>
                                    <Option className={'setting-select-size'} key={'never_show'} value={1}>从不显示</Option>
                                    <Option className={'setting-select-size'} key={'abn_no_show'}
                                            value={2}>有异常时不显示</Option>
                                </Select>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'通道显示排序'}
                        item={
                            <FormItem
                                name={'sortWith'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'defaultNet'} value={0}>默认</Option>
                                    <Option className={'setting-select-size'} key={'bedNum'} value={1}>床位号</Option>
                                    <Option className={'setting-select-size'} key={'name'} value={2}>患者姓名</Option>
                                    <Option className={'setting-select-size'} key={'dptName'} value={3}>科室名称</Option>
                                </Select>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'数据显示延迟'}
                        item={
                            <FormItem
                                name={'minDelay'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'second1'} value={1}>1秒</Option>
                                    <Option className={'setting-select-size'} key={'second3'} value={3}>3秒</Option>
                                    <Option className={'setting-select-size'} key={'second5'} value={5}>5秒</Option>
                                </Select>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'自动翻页周期'}
                        item={
                            <FormItem
                                name={'pagerFlipDuration'}
                            >
                                <Select className={'setting-select-size'} size={sizeType}>
                                    <Option className={'setting-select-size'} key={'close'} value={-1}>关闭</Option>
                                    <Option className={'setting-select-size'} key={'30'} value={30}>30秒</Option>
                                    <Option className={'setting-select-size'} key={'60'} value={60}>1分钟</Option>
                                    <Option className={'setting-select-size'} key={'180'} value={180}>3分钟</Option>
                                    <Option className={'setting-select-size'} key={'300'} value={300}>5分钟</Option>
                                    <Option className={'setting-select-size'} key={'600'} value={600}>10分钟</Option>
                                </Select>
                            </FormItem>}
                    />
                    <MobileFormItem
                        label={'异常提示事件'}
                        item={
                            <FormItem>
                                <Button onClick={this.showChildrenDrawer} style={{color: '#FF8000'}}>
                                    前往设置 <DoubleRightOutlined/>
                                </Button>
                            </FormItem>}
                    />
                    <FormItem {...MobileConstantSetting.tailFormItemLayout} style={{marginTop: '5%'}}>
                        <Button size={sizeType} style={{width: '20%', marginLeft: '20%'}}
                                onClick={this.props.onOK}>取消</Button>
                        <Button size={sizeType} style={{width: '20%', marginLeft: '30%'}} type='primary'
                                htmlType={'submit'}>确定</Button>
                    </FormItem>
                </Form>

                <Drawer
                    width={this.props.clientWidth}
                    title={<Typography.Text>异常提示事件设置</Typography.Text>}
                    headerStyle={{padding: '10px 10px'}}
                    bodyStyle={{paddingTop: '10px'}}
                    closable={false}
                    destroyOnClose={true}
                    visible={this.state.childrenDrawer}>
                    <MobileAbnormalEventSetting
                        hideChildrenDrawer={this.hideChildrenDrawer}
                        {...this.state}
                    />
                </Drawer>

            </Form.Provider>
        )
    }
}
