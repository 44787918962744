import {
    FETCH_USER_HISTORY_LIST_STARTED,
    FETCH_USER_HISTORY_LIST_SUCCESS,
    FETCH_USER_HISTORY_LIST_FAILURE,
    SET_USER_HISTORY_FILTER
} from "./actionTypes";
import axios from "../../myAxios";
import qs from "qs";
import moment from "moment";

export const fetchUserHistoryListStarted = () =>({
    type:FETCH_USER_HISTORY_LIST_STARTED
})
export const fetchUserHistoryListSuccess = (list, total, pagination, sort) =>({
    type:FETCH_USER_HISTORY_LIST_SUCCESS,
    payload:{
        list,
        pagination: {...pagination, total},
        sort
    }
});
export const fetchUserHistoryListFailure = (err)=>({
    type:FETCH_USER_HISTORY_LIST_FAILURE,
    error:err
});
export const fetchUserHistoryList = (filter={}, pagination={current: 1, pageSize: 10}, sort={field: 'start_time', order: 'desc'}) =>{

    return(dispatch)=>{
        dispatch(fetchUserHistoryListStarted());
        const dateStart =  filter.startTime ? moment(filter.startTime).format('YYYY-MM-DD') : null;
        const dateEnd = filter.endTime ?  moment(filter.endTime).format('YYYY-MM-DD') : null;
        const custSort = `${sort.field},id`;
        const custOrder = `${sort.order},${sort.order}`;
        const params = {
            page: pagination.current,
            rows: pagination.pageSize,
            sort:custSort,
            order:custOrder,
            'filter.user_name': filter.userName ,
            'filter.company_name': filter.companyName,
            'filter.prescriber_name':filter.prescriberName,
            'filter.dept_name':filter.deptName,
            'filter.start_time':dateStart,
            'filter.end_time': dateEnd
        };
        return axios.post('/cust/rt/rtHisRecListByCheckOrder.action',qs.stringify(params)).then(res=>{
            const {actionErrors,rows,total} = res.data;
            if(actionErrors.length){
                dispatch(fetchUserHistoryListFailure(actionErrors[0]||'操作失败'))
            }else{
                dispatch(fetchUserHistoryListSuccess(rows,total,pagination,sort))
            }
        }).catch(err =>{
            dispatch(fetchUserHistoryListFailure(err))
        })
    }
}
export const setUserHistoryFilter = (filter)=>({
    type:SET_USER_HISTORY_FILTER,
    payload:{
        filter
    }
})
