import {
    FETCH_EXTERN_LOGIN_STARTED,
    FETCH_EXTERN_LOGIN_SUCCESS,
    FETCH_EXTERN_LOGIN_FAILURE
} from "./actionTypes";
import qs from "qs";
import axios from "../../myAxios";

export const fetchExternLoginStarted = () =>({
    type:FETCH_EXTERN_LOGIN_STARTED
})
export const fetchExternLoginSuccess = () =>({
    type:FETCH_EXTERN_LOGIN_SUCCESS,
    payload:{}
});
export const fetchExternLoginFailure = (err)=>({
    type:FETCH_EXTERN_LOGIN_FAILURE,
    error:err
});
export const fetchExternLogin = (token) =>{
    return(dispatch)=>{
        dispatch(fetchExternLoginStarted());
        return axios.post('/oapi/auth/doctor',qs.stringify({}),{
            headers:{
                'X-Access-Token':token
            }
        }).then(res=>{
            console.log('fetchExternLogin:',res.data)
            const data = res.data;
            if (data){
                if (data.success){
                    dispatch(fetchExternLoginSuccess())
                }else {
                    dispatch(fetchExternLoginFailure(data.message||data.result||'操作失败'))
                }
            }else {
                dispatch(fetchExternLoginFailure('操作失败'))
            }
        }).catch(err =>{
            dispatch(fetchExternLoginFailure(err))
        })
    }
}
