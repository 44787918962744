/**
 * @name: MobileChannelGrid
 * @author: Seeker
 * @date: 2021-11-19 13:50
 * @description：MobileChannelGrid
 * @update: 2021-11-19 13:50
 */

const MOBILE = 21;

const CHECKER = 11;

const ChannelGrid = {
    getGridArgs: function (key) {
        switch (key) {
            case MOBILE:
                return [2, 1];
            case CHECKER:
                return [1,1];
            case 2:
                return [5, 2];
            case 3:
                return [5, 3];
            case 4:
                return [6, 2];
            case 5:
                return [6, 3];
            case 32:
                return [3,2];
            case 33:
                return [3,3];
            case 64:
                return [6,4];
            case 54:
                return [5,4];
            case 51:
                return [5,1];
            case 43:
                return [4,3];
            case 44:
                return [4,4];
            default:
                return [5,2];
        }
    },
    getTotalCount: function (key) {
        switch (key) {
            case MOBILE:
                return 2*1;
            case CHECKER:
                return 1*1;
            case 2:
                return 5*2;
            case 3:
                return 5*3;
            case 4:
                return 6*2;
            case 5:
                return 6*3;
            case 32:
                return 3*2;
            case 33:
                return 3*3;
            case 64:
                return 6*4;
            case 54:
                return 5*4;
            case 51:
                return 5*1;
            case 43:
                return 4*3;
            case 44:
                return 4*4;
            default:
                return 5*2;
        }
    },
    yGridCount: function (key){
        if (key === CHECKER){
            return 12;
        }
        return 8;
    },
};

export {MOBILE,CHECKER}

export default ChannelGrid
