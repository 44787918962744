import LeadCompat from "../../leadCompat";
import HomeStyle from "../../HomeStyle";

// export const COUNT_PER_PIXEL = 3.5;
// export const MV_SCALE = 30;

// export const LINE_WIDTH = 2;
const radio = 4;

export const ctrlYmv = (gainValue,yGridCount) =>{
    let ymv = yGridCount / 2;
    if (gainValue === 0){ // 10mm/mv
        ymv /= 2;
    }else if (gainValue === 1){ //20mm/mv
        ymv /= 4;
    }else if (gainValue === 2){//30mm/mv
        ymv /= 6;
    }else if (gainValue === 3){ // 5mm/mv

    }
    return ymv;
};

const ctrlGainText = (gainValue) =>{
    let text = '10mm/mv   25mm/s';
    if (gainValue === 1){
        text = '20mm/mv   25mm/s';
    }else if (gainValue === 2){
        text = '30mm/mv   25mm/s';
    }else if (gainValue === 3){
        text = '5mm/mv   25mm/s';
    }
    return text;
};

const ctrlScreenY = (height,point,ymv,baseline)=>{
    const offset = (point + ymv-baseline)*(height / (ymv*2));
    return height - offset;
};

export const DrawGrid = (ctx,width,height,darkModule,homeStyleCode,cellWidth,pacemaker,ratioRuleShow,gainValue,venueSetting,derivedLead,mqttChannel)=>{
    const chartGridLineWidth = HomeStyle.chartGridLineWidth(homeStyleCode)
	ctx.clearRect(0,0,width,height);
    ctx.save();
    ctx.beginPath();
	let thickGridSize = cellWidth;
    let xStart = 0;//x轴起点
    let yStart = 0;//y轴起点
    let drawWidth = width;//绘图区域宽度
    let drawHeight = height;//绘图区域高度
    let halfLineWidth = chartGridLineWidth / 2;
    ctx.lineCap = 'square';
    ctx.lineWidth = chartGridLineWidth;//网格线宽度
    ctx.strokeStyle = HomeStyle.chartGridLineColor(homeStyleCode,false,venueSetting);
    while(yStart <= drawHeight){
        if (yStart === 0){
            ctx.moveTo(0,halfLineWidth);
            ctx.lineTo(drawWidth-chartGridLineWidth,halfLineWidth);
        }else {
            ctx.moveTo(0,yStart);
            ctx.lineTo(drawWidth-chartGridLineWidth,yStart);
        }
        yStart += thickGridSize;
    }
    ctx.stroke();

    let  i = 0;
    while (xStart <= drawWidth) {
        if (i === 0){
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(halfLineWidth, 0);
            ctx.lineTo(halfLineWidth, drawHeight-chartGridLineWidth);
            ctx.stroke();
        }else if (ratioRuleShow && i % 5 === 0){
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = HomeStyle.chartGridLineColor(homeStyleCode,true,venueSetting);
            // ctx.lineWidth = chartGridLineWidth * 2;//网格线宽度
            ctx.moveTo(xStart, 0);
            ctx.lineTo(xStart, drawHeight-chartGridLineWidth);
            ctx.stroke();
        }
        else {
            ctx.save();
            ctx.beginPath();
            // ctx.lineWidth = chartGridLineWidth;//网格线宽度
            ctx.strokeStyle = HomeStyle.chartGridLineColor(homeStyleCode,false,venueSetting);
            ctx.moveTo(xStart, 0);
            ctx.lineTo(xStart, drawHeight-chartGridLineWidth);
            ctx.stroke();
        }
        i ++;
        xStart = thickGridSize * i;
    }
    ctx.restore();
    ctx.save();
    ctx.beginPath();
    let x = 5;
    const tempLeadNames = LeadCompat.checkDerivedLead(derivedLead,mqttChannel);
    if (tempLeadNames.length === 2){
        ctx.fillStyle = HomeStyle.ecgLineColor(homeStyleCode,venueSetting);
        ctx.fillText(LeadCompat.getLeadTitle(derivedLead[0]),5,drawHeight/2-cellWidth/4);

        const lead2Text = LeadCompat.getLeadTitle(derivedLead[1]);
        const lead2TextWidth = ctx.measureText(lead2Text).width;
        ctx.fillText(lead2Text,5,drawHeight-cellWidth/4);
        x += 5 + lead2TextWidth
    }
    if (pacemaker){
        ctx.fillStyle = '#C759EC';
        const pacemakerText = '起搏器';
        const pacemakerTextWidth = ctx.measureText(pacemakerText).width;
        ctx.fillRect(x,drawHeight-20,pacemakerTextWidth+10,18)
        ctx.fillStyle = 'yellow';
        ctx.fillText(pacemakerText,x+5,drawHeight-7);
    }
    ctx.fillStyle = HomeStyle.gainTextColor(homeStyleCode,venueSetting);
    let text = ctrlGainText(gainValue)
    const textWidth = ctx.measureText(text).width;
    ctx.fillText(text,drawWidth-textWidth-6,drawHeight-6);
    ctx.restore();
};

const _draw = (lineCtx,channelData,start,end,chartMaxDataLength,ymv,width,height,baseline,calculationMethod) => {
    let preY = 0;
    const tempStart = Math.max(0,start);
    const tempEnd = Math.min(chartMaxDataLength,end);
    for(let i = tempStart;i <= tempEnd;i++){
        let point = channelData[i];
        let x = i/chartMaxDataLength * width;
        let y = 0;
        if (Array.isArray(point)){
            const plotValue = calculationMethod(point);
            if(i === tempStart){
                y = ctrlScreenY(height,plotValue,ymv,baseline);
                preY = y;
                lineCtx.moveTo(x,Math.min(y,height));
            }else if(!Array.isArray(channelData[i-1]) && isNaN(channelData[i-1])) {
                y = ctrlScreenY(height,plotValue,ymv,baseline);
                preY = y;
                lineCtx.moveTo(x,Math.min(y,height));
            }else{
                y = ctrlScreenY(height,plotValue,ymv,baseline);
                if (preY > height){
                    lineCtx.moveTo(x,height);
                }else {
                    lineCtx.lineTo(x,Math.min(y,height));
                }
                preY = y;
            }
        }
    }
};

export const drawImpl = (lineCtx,drawWidth,drawHeight,darkModule,homeStyleCode,venueSetting,tempHeadIndex,tempPreHeadIndex,channelPlotData,chartMaxDataLength,baseline,calculationMethod,ymv) => {
    lineCtx.save();
    lineCtx.beginPath();
    lineCtx.strokeStyle= HomeStyle.ecgLineColor(homeStyleCode,venueSetting);
    lineCtx.lineWidth = HomeStyle.getEcgLineWidth(homeStyleCode,venueSetting);
    if (tempHeadIndex > tempPreHeadIndex){
        _draw(lineCtx,channelPlotData,Math.max(0,tempPreHeadIndex-5),tempHeadIndex,chartMaxDataLength,ymv,drawWidth,drawHeight,baseline,calculationMethod)
    }else if (tempHeadIndex < tempPreHeadIndex){
        _draw(lineCtx,channelPlotData,Math.max(0,tempPreHeadIndex-5),chartMaxDataLength,chartMaxDataLength,ymv,drawWidth,drawHeight,baseline,calculationMethod)
        _draw(lineCtx,channelPlotData,0,tempHeadIndex,chartMaxDataLength,ymv,drawWidth,drawHeight,baseline,calculationMethod)
    }
    lineCtx.stroke();
    lineCtx.restore();

    lineCtx.save();
    lineCtx.beginPath();
    lineCtx.fillStyle = HomeStyle.ecgLineColor(homeStyleCode,venueSetting);
    let point = channelPlotData[tempHeadIndex];
    let x = tempHeadIndex/chartMaxDataLength * drawWidth;
    let y = ctrlScreenY(drawHeight,calculationMethod(point),ymv,baseline);
    y = Math.min(y,drawHeight-radio);
    if (x < drawWidth - radio / 2) {
        lineCtx.arc(x+radio,y,radio,0,2*Math.PI);
        lineCtx.fill(); // 进行绘制
    }
    lineCtx.restore();
};

// export const DrawChart2 = (lineCtx,width,height,mqttChannel,darkModule,cellWidth,height2,gainValue,chartMaxDataLength,preHeadIndex,headIndex,baseline,yGridCount,venueSetting,readLeadIndex)=>{
//     let channelData = data[mqttChannel];
//     if(channelData !== undefined && channelData && channelData.length){
//         const tempPreHeadIndex = Math.max(0,preHeadIndex-1);
//         const tempHeadIndex = Math.max(0,headIndex-1);
//         const ymv = ctrlYmv(gainValue,yGridCount);
//         const preHeadX = tempPreHeadIndex/chartMaxDataLength * width;
//         let wipeIndex = (tempHeadIndex + 100) % chartMaxDataLength;
//         const wipeX = wipeIndex/chartMaxDataLength * width;
//         if (wipeIndex >= tempPreHeadIndex){
//             lineCtx.clearRect(preHeadX,0,wipeX-preHeadX,height2);
//         }else {
//             lineCtx.clearRect(preHeadX,0,width-preHeadX+5,height2);
//             lineCtx.clearRect(0,0,wipeX,height2);
//         }
//         drawImpl(lineCtx,width,height,darkModule,venueSetting,tempHeadIndex,tempPreHeadIndex,channelData,chartMaxDataLength,baseline,readLeadIndex,ymv,radio)
//     }else {
//         lineCtx.clearRect(0,0,width+3,height2);
//     }
// };

// export const DrawChart = (lineCtx,width,height,mqttChannel,darkModule,cellWidth,height2,gainValue,chartMaxDataLength,headIndex,baseline,yGridCount,venueSetting)=>{
//     let channelData = data[mqttChannel];
//     if(channelData !== undefined && channelData && channelData.length){
//         const ymv = ctrlYmv(gainValue,yGridCount);
//         lineCtx.clearRect(0,0,width+3,height2);
//         lineCtx.beginPath();
//         lineCtx.strokeStyle= ThemeColor.ecgLineColor(darkModule,venueSetting);
//         lineCtx.lineWidth = ThemeColor.getEcgLineWidth(darkModule,venueSetting);
//         let preY = 0;
//         for(let i=0;i<channelData.length;i++){
//             let point = channelData[i];
//             let x = i/chartMaxDataLength * width;
//             let y = 0;
//             if(i === 0){
//                 if(!isNaN(channelData[i])){
//                      y = ctrlScreenY(height,point,ymv,baseline);
//                      preY = y;
//                      lineCtx.moveTo(x,Math.min(y,height));
//                 }
//             }else if(isNaN(channelData[i-1]) && !isNaN(channelData[i])) {
//                 y = ctrlScreenY(height,point,ymv,baseline);
//                 preY = y;
//                 lineCtx.moveTo(x,Math.min(y,height));
//             }else{
//                 y = ctrlScreenY(height,point,ymv,baseline);
//                 if(i>=headIndex && i<=headIndex+50){
//                     // 刷新的端点不能与后面的线连接,相当于跳过端点后面的10个点不做连线
//                     lineCtx.moveTo(x,Math.min(y,height));
//                 }
//                 if (preY > height){
//                     // lineCtx.moveTo(x,Math.min(y,height));
//                     lineCtx.moveTo(x,height);
//                 }else {
//                     lineCtx.lineTo(x,Math.min(y,height));
//                 }
//                 preY = y;
//             }
//         }
//         lineCtx.stroke();
//         lineCtx.beginPath();
//         lineCtx.fillStyle = ThemeColor.ecgLineColor(darkModule,venueSetting);
//         let point = channelData[headIndex];
//         let x = headIndex/chartMaxDataLength * width;
//         let y = ctrlScreenY(height,point,ymv,baseline);
//         let radio = 4;
//         y = Math.min(y,height-radio);
//         if (x < width - radio / 2) {
//             lineCtx.arc(x,y,radio,0,2*Math.PI);
//             lineCtx.fill(); // 进行绘制
//         }
//     }
//
// };
