/**
 * @name: Menu
 * @author: Seeker
 * @date: 2021-11-20 15:32
 * @description：Menu
 * @update: 2021-11-20 15:32
 */
import React from "react";
import useContextMenu from "../index";
import "./menu.css"
import emitter from "../event/EventEmitter"
import {EditOutlined, PoweroffOutlined, PrinterOutlined} from "@ant-design/icons";
import {Divider} from "antd";

const MenuItemId = {
    CHANNEL_DIVIDER:0,
    CHANNEL_CLOSE:1,
    CHANNEL_PRINT:2,
    CHANNEL_GAIN_ADJUST:3,
};

const menuItems = [
    {
        id:MenuItemId.CHANNEL_CLOSE,
        name:'通道关闭',
        icon:<PoweroffOutlined/>,
        onClick:(channel)=>{
            emitter.emit("callMe",{
                id:MenuItemId.CHANNEL_CLOSE,
                channel
            });
        }
    },
    {
        id:MenuItemId.CHANNEL_DIVIDER,
    },
    {
        id:MenuItemId.CHANNEL_GAIN_ADJUST,
        name:'通道设置',
        icon:<EditOutlined/>,
        onClick:(channel)=>{
            emitter.emit("callMe",{
                id:MenuItemId.CHANNEL_GAIN_ADJUST,
                channel
            });
        }
    },
    {
        id:MenuItemId.CHANNEL_DIVIDER,
    },
    {
        id:MenuItemId.CHANNEL_PRINT,
        name:'打印心电图片段',
        icon:<PrinterOutlined/>,
        onClick:(channel)=>{
            emitter.emit("callMe",{
                id:MenuItemId.CHANNEL_PRINT,
                channel
            });
        }
    },
];

const Menu = props => {
    const {
        bindMenu,
        data,
        bindMenuItem,
        hideMenu
    } = props;
    return (
        <div {...bindMenu} className="menu-right">
            {
                menuItems.map((item,index)=>(
                    item.id === MenuItemId.CHANNEL_DIVIDER ?
                        <div key={`divider-${item.id}-${index}`} style={{padding:'0px 5px 0px 5px',margin:'10px 0 0 0'}}><Divider style={{backgroundColor:'#484F5F',margin:0,width:1}}/></div>:
                        <div {...bindMenuItem} key={item.id} onClick={()=>{hideMenu();item.onClick(data)}}>{item.icon} {item.name}</div>
                ))
            }
        </div>
    );
};

function withMenu(Component) {
    return function WrapperMenu(props){
        const [
            bindMenu,
            bindMenuItem,
            useContextTrigger,
            { data, coords, setVisible }
        ] = useContextMenu();
        const hideMenu = () => setVisible(false);
        return (
            <>
                <Component {...props} useContextTrigger={useContextTrigger}/>
                <Menu
                    bindMenu={bindMenu}
                    data={data}
                    bindMenuItem={bindMenuItem}
                    coords={coords}
                    hideMenu={hideMenu}
                />
            </>
        );
    }
}

export {MenuItemId}

export default withMenu;
