/**
 * @name: channelSetting
 * @author: Seeker
 * @date: 2022-03-10 11:08
 * @description：channelSetting
 * @update: 2022-03-10 11:08
 */
import React, {Component} from "react";
import settingStorage from "../util/setting-storage";
import {Button, Form, Row, Col, Select, Slider, InputNumber, Divider, Switch} from "antd";
import ConstantSetting from "./modules/v2/ConstantSetting";
import ViewAdapt2 from "./modules/v2/ViewAdapt2";

const FormItem = Form.Item;
const Option = Select.Option;

/**
 *{
 *     gain:0 增益
 *     rrLongPause: 0 长间歇
 *     bpmRanger: [50,120] 正常心率范围
 *}
 */
export default class channelSetting extends Component {

    constructor(props) {
        super(props);
    }

    save = (values) => {
        settingStorage.setChannelSetting(this.props.channel.mqtt_channel, {...values,hrRanger:{min:values.bpmRanger[0],max:values.bpmRanger[1]}});
        this.props.hideFun();
    };

    render() {
        const settings = settingStorage.getChannelSetting(this.props.channel.mqtt_channel,this.props.globalHrRangerSetting,this.props.globalEmergencySoundInterval);
        const labelFontSize = ConstantSetting.labelFontSize(this.props.clientWidth);
        const sizeType = ConstantSetting.sizeType(this.props.clientWidth);
        return (
            <Form initialValues={{...settings,bpmRanger:[settings.hrRanger.min,settings.hrRanger.max]}} onFinish={this.save}>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, '长间歇最低时长 (秒)')}
                            name={'rrLongPause'}
                        >
                            <InputNumber className={`setting-select-size`}
                                         size={sizeType} min={2}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, '正常心率区间')}
                            name={'bpmRanger'}>
                            <Slider
                                range={true}
                                min={ViewAdapt2.BPM_LIMIT.min}
                                max={ViewAdapt2.BPM_LIMIT.max}
                                marks={ConstantSetting.sliderMarks(settings.hrRanger)}
                            />
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, '增益调整')}
                            name={'gain'}
                        >
                            <Select className={'setting-select-size'} size={sizeType}>
                                <Option className={'setting-select-size'} key={'3'} value={3}>5mm/mv</Option>
                                <Option className={'setting-select-size'} key={'0'} value={0}>10mm/mv</Option>
                                <Option className={'setting-select-size'} key={'1'} value={1}>20mm/mv</Option>
                                <Option className={'setting-select-size'} key={'2'} value={2}>30mm/mv</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, '紧急事件提示音间隔')}
                            name={'soundInterval'}
                        >
                            <Select className={'setting-select-size'} size={sizeType}>
                                <Option className={'setting-select-size'} key={'zero'} value={0}>无间隔</Option>
                                <Option className={'setting-select-size'} key={'minute1'} value={60}>1分钟</Option>
                                <Option className={'setting-select-size'} key={'minute5'} value={300}>5分钟</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={24} className={'setting-row-size2-slider'}>
                        <FormItem
                            {...ConstantSetting.selectFormItemLayout3}
                            label={ConstantSetting.label(labelFontSize, 'ECG失真重绘')}
                            name={'ecgRedrawState'}
                            valuePropName={'checked'}
                        >
                            <Switch size={sizeType} checkedChildren="开" unCheckedChildren="关"/>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem style={{textAlign: 'right', margin: '20px 20px 10px 0px'}}>
                    <Button onClick={this.props.hideFun} style={{marginRight: '20px'}}>取消</Button>
                    <Button type="primary" htmlType={'submit'}>确定</Button>
                </FormItem>

            </Form>
        )
    }
}
