/**
 * @name: local-storage
 * @author: Seeker
 * @date: 2021-05-23 16:50
 * @description：local-storage
 * @update: 2021-05-23 16:50
 */
import _isBoolean from "./is-boolean";
import _isInteger from "./is-integer";
import _isArray from "./is-array";
import _isObject from "./is-object"

const _localStorage = window.localStorage;

const settingStorage = {

    key:function(companyId){
        return `companyId_${companyId}`;
    },

    getSettingCache:function(companyId){
        let cache;
        if ( _localStorage.hasOwnProperty(this.key(companyId))
            && _localStorage.getItem(this.key(companyId)) !== 'null'
            && _localStorage.getItem(this.key(companyId)) !== undefined){
            cache = JSON.parse(_localStorage.getItem(this.key(companyId)));
        }else {
            cache = {};
        }
        return cache;
    },

    updateCacheState: function (rawState,updateState){
        const newState = {...rawState};
        for (const key in rawState){
            if (rawState.hasOwnProperty(key)){
                const value = rawState[key];
                if (updateState.hasOwnProperty(key) && updateState[key] !== 'null' && updateState[key] !== undefined){
                    const localValue = updateState[key] ;
                    if (_isBoolean(value)){
                        newState[key] = localValue === 'true';
                    }else if (_isInteger(value)){
                        newState[key] = parseInt(localValue);
                    }else if (_isArray(value) || _isObject(value)){
                        newState[key] = JSON.parse(localValue);
                    }else {
                        newState[key] = localValue;
                    }
                }else {
                    newState[key] = value;
                }
            }
        }
        return newState;
    },

    fillSetState:function(state,companyId){
        if (companyId){
            const cache = this.getSettingCache(companyId);
            return this.updateCacheState(state,cache);
        }
        return state;
    },

    updateStateParams: function (params,state){
        for (const key in params){
            if (params.hasOwnProperty(key)){
                const value = params[key];
                if (_isArray(value) || _isObject(value)){
                    state[key] = JSON.stringify(value);
                }else {
                    state[key] = `${value}`;
                }
            }
        }
    },

    updateSettingParams:function(params,companyId){
        const cache = this.getSettingCache(companyId);
        this.updateStateParams(params,cache);
        _localStorage.setItem(this.key(companyId),JSON.stringify(cache));
        return cache;
    },

    cacheAccount: function (name,pwd,state){
        if (name && pwd){
            try {
                const account = {name:name,pwd:window.btoa(pwd),state:state};
                _localStorage.setItem('zenihealth_account',JSON.stringify(account));
            }catch (e) {
                console.log(`cacheAccount()error: `,e);
            }
        }else {
            _localStorage.removeItem('zenihealth_account');
        }
    },
    logoutAccount:function () {
        const account = this.getAccount();
        this.cacheAccount(account.name,account.pwd,'0');
    },
    getAccount: function (){
        if (_localStorage.hasOwnProperty('zenihealth_account')){
            const cache = _localStorage.getItem('zenihealth_account');
            if (cache !== 'null' && cache !== undefined){
               try {
                   const account = JSON.parse(cache);
                   account.pwd = window.atob(account.pwd);
                   return account;
               }catch (e){
                   console.log(`getAccount()exception...`,e)
               }
            }
        }
        return {name:'',pwd:''};
    },
    clearMqttMsg: function (){
        const willDeleted = [];
        for (let i = 0,len = _localStorage.length;i < len;i++){
            const key = _localStorage.key(i);
            if (key.indexOf('Sent') >= 0 || key.indexOf('Received') >= 0){
                willDeleted.push(key);
            }
        }
        willDeleted.forEach(key => _localStorage.removeItem(key));
        willDeleted.length = 0;
    },

    hasChannelSettingKey:function (accountChannelId) {
        const key = `zenihealth-${accountChannelId}`;
        return _localStorage.hasOwnProperty(key);
    },

    getChannelSetting:function (accountChannelId,globalHrRangerSetting,globalEmergencySoundInterval){
        const key = `zenihealth-${accountChannelId}`;
        if (_localStorage.hasOwnProperty(key)){
            const cache = _localStorage.getItem(key);
            if (cache !== 'null' && cache !== undefined){
                try {
                    return JSON.parse(cache);
                }catch (e){
                    console.log(`getChannelSetting()exception...`,e)
                }
            }
        }
        const tempSetting = globalHrRangerSetting || {min:50,max:120};
        return {
            gain: 0,
            rrLongPause:2,
            hrRanger:tempSetting,
            soundInterval:globalEmergencySoundInterval,
            ecgRedrawState:false
        };
    },

    setChannelSetting:function (accountChannelId,settings){
        const key = `zenihealth-${accountChannelId}`;
        _localStorage.setItem(key,JSON.stringify(settings));
    },

    removeChannelSetting:function (accountChannelId){
        const key = `zenihealth-${accountChannelId}`;
        if (_localStorage.hasOwnProperty(key)) {
            _localStorage.removeItem(key);
        }
    },

    getVenueSetting:function (){
        const key = `zenihealth-venue-setting`;
        if (_localStorage.hasOwnProperty(key)){
            const cache = _localStorage.getItem(key);
            if (cache !== 'null' && cache !== undefined){
                try {
                    return JSON.parse(cache);
                }catch (e){
                    console.log(`getVenueSetting()exception...`,e)
                }
            }
        }
        return {
            venueOpenState: false,
        };
    },

    setVenueSetting:function (settings){
        const key = `zenihealth-venue-setting`;
        _localStorage.setItem(key,JSON.stringify(settings));
    },

    setDataLossReceivedRefreshPagerAble:function (refreshAbleData){
        const key = `zenihealth-dataLoss-refreshPager`;
        _localStorage.setItem(key,JSON.stringify(refreshAbleData));
    },
    getDataLossReceivedRefreshPagerAble:function (){
        const key = `zenihealth-dataLoss-refreshPager`;
        if (_localStorage.hasOwnProperty(key)){
            const cache = _localStorage.getItem(key);
            if (cache !== 'null' && cache !== undefined){
                try {
                    return JSON.parse(cache);
                }catch (e){
                    console.log(`getDataLossReceivedRefreshPagerAble()exception...`,e)
                }
            }
        }
        return {};
    },

};

export default settingStorage;
