import PahoMQTT from "paho-mqtt";
import {mqttUrl} from "../../UrlConfig";

/**
 * @name: MQTTTestClient
 * @author: Seeker
 * @date: 2023-08-02 10:38
 * @description：MQTTTestClient
 * @update: 2023-08-02 10:38
 */

let channelVisibleList = [];

class MQTTTestClient{
    constructor(appMqttClientConnectStateListener,cardioguardConnectStateListener) {
        // 增加2位随机数以避免clientid可能重复, clientid经过UTF8编码后，不能超过23字节长度
        this.clientId = 'web_' + new Date().getTime() + '_' + Math.floor(Math.random() * 100);
        this.client = null;
        this.isHttps = window.location.href.includes('https://');
        if (!this.client) {
            this.client = new PahoMQTT.Client(mqttUrl, this.isHttps ? 443 : 8083, this.clientId);
            // this.client.startTrace();
            this.client.onConnected = this.onConnected.bind(this);
            this.client.onConnectionLost = this.onConnectionLost.bind(this);
            this.client.onMessageArrived = this.onMessageArrived.bind(this);
        }

        // onConnected和onSuccess都会在连接成功后掉用，先调用onSuccess，后调用onConnected。onConnected里多一个reconnect属性，没其他的区别
        this.client.connect({
            userName: 'emqx',
            password: 'public',
            useSSL: this.isHttps,
            invocationContext: this,
            cleanSession: true,
            reconnect: true,
            keepAliveInterval: 10,
            onSuccess: this.connectSuccess,
            onFailure: this.connectFailure,
        });
        this.isConnect = false;
        this.appMqttClientConnectStateListener = appMqttClientConnectStateListener;
        this.cardioguardConnectStateListener = cardioguardConnectStateListener;
    }

    connectSuccess = (connectOption) => {
        this.isConnect = true;
        if (channelVisibleList.length) {
            // 假设disconnect以后自动重连，需要重新建立所有订阅。
            for (let i = 0; i < channelVisibleList.length; i++) {
                let item = channelVisibleList[i];
                if (item.mqtt_channel) {
                    connectOption.invocationContext.subscribeAllTopic(item.mqtt_channel, i);
                }
            }
        }
    }

    updateVisibleChannel = (list) => {
        channelVisibleList = list.slice();
        if (this.isConnect){
            for (let i = 0, len = list.length; i < len; i++) {
                const id = list[i].mqtt_channel;
                if (id !== null) {
                    this.subscribeAllTopic(id, i); // dataBuffer中没有的，增加订阅。
                }
            }
        }
    }

    subscribeAllTopic = (topic, index) => {
        // this.subscribeTopic(topic, 2, index, true); // dataBuffer中没有的，增加订阅。
        this.subscribeTopic(`${topic}-extra-res-bleState`, 2, index);
        // this.subscribeTopic(`${topic}-extra-res-mobileBattery`, 2, index);
        // this.subscribeTopic(`${topic}-extra-res-bleBattery`, 2, index);
        this.subscribeTopic(`${topic}-extra-res-offline`, 2, index);
        // this.subscribeTopic(`${topic}-extra-res-finish`, 2, index);
    }

    subscribeTopic = (topic, qos, index, canCache) => {
        try {
            this.client.subscribe(topic, {
                qos: qos,
                invocationContext: this,
                timeout: 3000,
                onSuccess: function (invocationContext) {
                    console.log(`subscribeTopic.onSuccess() called: topic = [${topic}],index = ${index},canCache = ${canCache}`);
                },
                onFailure: function (invocationContext, errorCode, errorMessage) {
                    console.log(`subscribeTopic.onFailure()called: topic = [${topic}],index = [${index}],` + 'code: ' + errorCode + ', message: ' + errorMessage);
                }
            });
        } catch (e) {
            console.error(`subscribeTopic() catch error: topic = [${topic}]`, e)
        }
    }

    connectFailure = (connectOption, errorCode, errorMessage) => {
        console.log('连接失败', errorCode, errorMessage);
        this.isConnect = false;
    }

    onConnected = (reconnect, URI) => {
        if (reconnect) {
            // 关开网络、切换网络以后，都会先触发onConnectionLost然后再自动重连，不用任何操作，但有时候连得快有时候连的慢。
            console.info('onConnected 自动重连client成功', URI);
        } else {
            console.info('onConnected 主动调用connet连接成功', URI);
        }
    }

    onConnectionLost = (responseObject) => {
        console.log(`onConnectionLost()called: errorCode = ${responseObject.errorCode},errorMessage =  ${responseObject.errorMessage},uri = ${this.client.uri}`);
        this.isConnect = false;
    }

    onMessageArrived = (message) => {
        let destinationName = message.destinationName;
        if (destinationName.includes("extra-res")){
            let extraMessage = message.payloadString;
            if (extraMessage.indexOf('extra:') === 0) {
                // 忽略旧版协议数据
                return;
            }
            let topicPair = destinationName.split('-extra-res-');
            let channelName = topicPair[0];
            let stateName = topicPair[1];
            let dataPair = extraMessage.split('@');
            let stateValue = parseInt(dataPair[1]);
            let time = parseInt(dataPair[0])
            if (stateName.includes("offline")){
                this.appMqttClientConnectStateListener && this.appMqttClientConnectStateListener(channelName,stateValue,time);
            }else if (stateName.includes("bleState")){
                this.cardioguardConnectStateListener && this.cardioguardConnectStateListener(channelName,stateValue,time);
            }
        }
    }
}

export default MQTTTestClient
