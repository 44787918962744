import {LOGIN_STARTED, LOGIN_SUCCESS, LOGIN_FAILURE} from './actionTypes';
import {LOGOUT} from './actionTypes';
import * as Status from './status.js';

export default (state = {loginStatus: Status.WAIT}, action) => {

    switch(action.type) {
        case LOGIN_STARTED: {
            return {loginStatus: Status.LOADING};
        }
        case LOGIN_SUCCESS: {
            return {loginStatus: Status.SUCCESS,companyId:action.result.user.companyId,companyName: action.result.user.companyName};
        }
        case LOGIN_FAILURE: {
            return {loginStatus: Status.FAILURE, error: action.error};
        }
        case LOGOUT:
            return {loginStatus: Status.LOGGED_OUT};
        default: {
            return state;
        }
    }
}
