
const processBeta = true;

const pageUrl =(function () {
    let base = 'page';
    base = (base === undefined) ? '/' : '/' + base;
    return base;
})();
const baseUrl = (function () {
    // let base = process.env.REACT_APP_BASE_URL;
    let base = processBeta?'beta':'ecg';
    /*if(process.env.NODE_ENV !== 'development'){
        base = '/zxapi';
        return base;
    }else{
        base = (base === undefined) ? '/' : '/' + base;
        return base;
    }*/
    base = (base === undefined) ? '/' : '/' + base;

    return base;

})();

const mqttUrl  = (function () {
    return processBeta?'emqx.zxthealth.com':'test-emqx.zxthealth.com';
})();

export{baseUrl,pageUrl,mqttUrl,processBeta};
