/**
 * @name: is-boolean
 * @author: Seeker
 * @date: 2021-05-23 16:55
 * @description：is-boolean
 * @update: 2021-05-23 16:55
 */

import isType from './is-type';

const isBoolean = function(value) {
    return isType(value, 'Boolean');
};

export default isBoolean;