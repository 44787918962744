/**
 * @name: TimeLong
 * @author: Seeker
 * @date: 2022-07-29 08:49
 * @description：TimeLong
 * @update: 2022-07-29 08:49
 */

const LONG_TEST_TIME_LONG = 7;

const TimeLong = {
    modalWidth:(timeLong) =>{
        if (timeLong === 30){
            return 600;
        }
        if (timeLong === 60 || timeLong === 12){
            return 1100;
        }
        if (timeLong === LONG_TEST_TIME_LONG){
            return 700;
        }
        return 600;
    },
    modalTop:(timeLong,clientHeight) =>{
        if (timeLong === LONG_TEST_TIME_LONG){
            return (clientHeight-250)/2;
        }
        return (clientHeight-850)/2;
    },
    chartLayout:(timeLong) =>{
        if (timeLong === 60){
            return {
                rowSeconds: 12,
                rowCount:5,
            }
        }
        if (timeLong === LONG_TEST_TIME_LONG){
            return {
                rowSeconds: LONG_TEST_TIME_LONG,
                rowCount:1,
                yGridCount:12,
            }
        }
        if (timeLong === 12){
            return {
                rowSeconds: 12,
                rowCount:1,
                yGridCount:12
            }
        }
        return {
            rowSeconds: 6,
            rowCount:5,
        }
    },
    calLoadingMargin:(timeLong) =>{
        if (timeLong === 30){
            return {marginTop:'46%',marginLeft:'40%'}
        }
        if (timeLong === 60){
            return {marginTop:'35%',marginLeft:'50%'}
        }
        if (timeLong === 12){
            return {marginTop:'10%',marginLeft:'50%'}
        }
        return {marginTop:'10%',marginLeft:'40%'}
    },
    dataPointLength: (timeLong) =>{
        return timeLong * 250;
    },
    svgConfig:(timeLong) => {
        if (timeLong === 30){
            return {
                yGridCount:8,
                pointCountPerRow: 6*250,
                rowSpace:5
            }
        }
        if (timeLong === 60){
            return {
                yGridCount:8,
                pointCountPerRow: 12*250,
                rowSpace:5
            }
        }
        if (timeLong === 12){
            return {
                yGridCount:12,
                pointCountPerRow: 12*250,
            }
        }
        if (timeLong === LONG_TEST_TIME_LONG){
            return {
                yGridCount:12,
                pointCountPerRow: 7*250,
            }
        }
        return {
            yGridCount:8,
            pointCountPerRow: 6*250,
            rowSpace:5
        }
    },
}

export {
    LONG_TEST_TIME_LONG
}

export default TimeLong
