/*
MQTT协议中文文档参考
https://mcxiaoke.gitbooks.io/mqtt-cn/
qos === 2时，有本地缓存操作逻辑
*/

import PahoMQTT from 'paho-mqtt';
import {message, Modal} from 'antd';
import Channel from "./Channel";
import DataParseCompat from "../compat/DataParseCompat";
import Saver from "../util/Saver";
import LeadCompat from "../../leadCompat";
import settingStorage from "../util/setting-storage";
import {mqttUrl} from "../../UrlConfig";

let isConnect = false;
let isErrorModalShow = false;
let channelVisibleList = [];
let data = {};        //存储接收到的数据
let dataBuffer = {};
let enableDraw = {};
let canCacheData = {}; //当前通道是否可以缓冲数据
let channelPrintDataBuffer = []; // 将要打印的心电数据
let isCalculateChannelPrintDataBaseline = false; //校准打印基线
let dataLastReceivedTime = {}; // 通道收到上次数据的时间

// let prePktIndex = {};

// let bpm = {};
// let DEBUG = true;
// let DEBUG_totalDataCount = 0;
// let DEBUG_allchannel = false;
// let DEBUG_channel_name = 'S1F032E6RB';
// let preDate = 0;

let extra = {};
// let Mobile_DataPacket_Points = 50;
// let DEBUG_preMsgTimestamp = 0;

let topic_new_channel = 'topic_zenihealth_medical_monitor_channel_created_online';

let testData = [];

class MQTTClient {
    constructor(refreshChannel) {
        // 增加2位随机数以避免clientid可能重复, clientid经过UTF8编码后，不能超过23字节长度
        this.clientId = 'web_' + new Date().getTime() + '_' + Math.floor(Math.random() * 100);
        this.client = null;
        this.isHttps = window.location.href.includes('https://');
        this.refreshChannle = refreshChannel;
        if (!this.client) {
            this.client = new PahoMQTT.Client(mqttUrl, this.isHttps ? 443 : 8083, this.clientId);
            // this.client.startTrace();
            this.client.onConnected = this.onConnected.bind(this);
            this.client.onConnectionLost = this.onConnectionLost.bind(this);
            this.client.onMessageArrived = this.onMessageArrived.bind(this);
        }

        // onConnected和onSuccess都会在连接成功后掉用，先调用onSuccess，后调用onConnected。onConnected里多一个reconnect属性，没其他的区别
        this.client.connect({
            userName: 'emqx',
            password: 'public',
            useSSL: this.isHttps,
            invocationContext: this,
            cleanSession: true,
            reconnect: true,
            keepAliveInterval: 10,
            onSuccess: this.connectSuccess,
            onFailure: this.connectFailure,
        });

        // this.updateVisibleChannel = this.updateVisibleChannel.bind(this);
        // this.setDemoShow = this.setDemoShow.bind(this);
        // this.setMinDelay = this.setMinDelay.bind(this);
        // this._subscribeWithCheck = this._subscribeWithCheck.bind(this);
        // this.setPrintChannel = this.setPrintChannel.bind(this);
        // this.reloadPager = this.reloadPager.bind(this);
        // this.checkDataLastReceivedTime = this.checkDataLastReceivedTime.bind(this);
        // this.sendMsgToAppClient = this.sendMsgToAppClient.bind(this);
        this.reloadPagerTimeout = null;
        this.isDemoShow = false;
        this.minDelay = 1;
        this.printChannel = null;
        this.timeLong = 30;
        this.channelDataBaseline = 0; //数据基线
        this.calculateBaselinePointCount = 0;//计算基线的点数
        this.calculateBaselineChannel = undefined;
        this.calculateBaselineCallback = undefined;
        this.checkDataLastReceivedTimeTimeInterval = null;
        this.canCacheData = true;
    }

    onConnected = (reconnect, URI) => {
        if (reconnect) {
            // 关开网络、切换网络以后，都会先触发onConnectionLost然后再自动重连，不用任何操作，但有时候连得快有时候连的慢。
            console.info('onConnected 自动重连client成功', URI);
        } else {
            console.info('onConnected 主动调用connet连接成功', URI);
        }
    }

    _subscribeWithCheck = (list) => {
        if (isConnect) {
            for (let i = 0, len = list.length; i < len; i++) {
                const id = list[i].mqtt_channel;
                // if (id !== null && (!dataBuffer.hasOwnProperty(id) || dataBuffer[id] === undefined)){
                if (id !== null && !canCacheData[id]) {
                    // canCacheData[id] = true;
                    // dataBuffer[id] = [];
                    // enableDraw[id] = false;
                    // data[id] = [];
                    this.subscribeAllTopic(id, i); // dataBuffer中没有的，增加订阅。
                }
            }
            for (let ch in dataBuffer) {
                const find = list.find(newChannel => newChannel.mqtt_channel === ch);
                if (!find) {
                    // canCacheData[ch] = false;
                    // dataBuffer[ch] = undefined;
                    // enableDraw[ch] = false;
                    // data[ch] = [];
                    this.unsubscribeAllTopic(ch);
                }
            }
        }
    }

    updateVisibleChannel = (list) => {
        console.log(`updateVisibleChannel()called:`, list, isConnect);
        channelVisibleList = [];
        if (list) {
            this._subscribeWithCheck(list);
            channelVisibleList = list.slice();
        } else {
            message.warn('未获取到患者信息！');
        }
    }

    subscribeAllTopic = (topic, index) => {
        this.subscribeTopic(topic, 2, index, true); // dataBuffer中没有的，增加订阅。
        this.subscribeTopic(`${topic}-extra-res-bleState`, 2, index);
        this.subscribeTopic(`${topic}-extra-res-mobileBattery`, 2, index);
        this.subscribeTopic(`${topic}-extra-res-bleBattery`, 2, index);
        this.subscribeTopic(`${topic}-extra-res-offline`, 2, index);
        this.subscribeTopic(`${topic}-extra-res-finish`, 2, index);
    }

    reloadPager = () => {
        if (!this.reloadPagerTimeout) {
            this.reloadPagerTimeout = setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    }

    subscribeTopic = (topic, qos, index, canCache) => {
        try {
            this.client.subscribe(topic, {
                qos: qos,
                invocationContext: this,
                timeout: 3000,
                onSuccess: function (invocationContext) {
                    console.log(`subscribeTopic.onSuccess() called: topic = [${topic}],index = ${index},canCache = ${canCache}`);
                    if (canCache) {
                        canCacheData[topic] = true;
                        dataBuffer[topic] = {};
                        enableDraw[topic] = false;
                        data[topic] = [];
                        extra[topic] = {};
                        dataLastReceivedTime[topic] = new Date().getTime();
                    }
                },
                onFailure: function (invocationContext, errorCode, errorMessage) {
                    console.log(`subscribeTopic.onFailure()called: topic = [${topic}],index = [${index}],` + 'code: ' + errorCode + ', message: ' + errorMessage);
                    invocationContext.invocationContext.reloadPager();
                }
            });
        } catch (e) {
            console.error(`subscribeTopic() catch error: topic = [${topic}]`, e)
        }
    }

    unsubscribeAllTopic = (topic) => {
        this.unsubscribeTopic(topic, true); // dataBuffer中没有的，增加订阅。
        this.unsubscribeTopic(`${topic}-extra-res-bleState`);
        this.unsubscribeTopic(`${topic}-extra-res-mobileBattery`);
        this.unsubscribeTopic(`${topic}-extra-res-bleBattery`);
        this.unsubscribeTopic(`${topic}-extra-res-offline`);
        this.unsubscribeTopic(`${topic}-extra-res-finish`);
    }

    unsubscribeTopic = (topic, noCache) => {
        try {
            this.client.unsubscribe(topic, {
                invocationContext: this,
                timeout: 3000,
                onSuccess: function (invocationContext) {
                    console.log(`unsubscribeTopic.onSuccess() called: topic = ${topic},noCache = ${noCache}`)
                    if (noCache) {
                        canCacheData[topic] = false;
                        dataBuffer[topic] = undefined;
                        enableDraw[topic] = false;
                        data[topic] = [];
                        dataLastReceivedTime[topic] = undefined;
                    }
                },
                onFailure: function (invocationContext, errorCode, errorMessage) {
                    console.log(`unsubscribeTopic.onFailure()called: topic = [${topic}],` + 'code: ' + errorCode + ', message: ' + errorMessage);
                    invocationContext.invocationContext.reloadPager();
                }
            });
        } catch (e) {
            console.error(`unsubscribeTopic() catch error: topic = [${topic}]`, e)
        }
    }

    connectSuccess = (connectOption) => {
        // console.log('connectSuccess invocationContext',callback.invocationContext);
        if (Modal !== undefined && Modal) {
            Modal.destroyAll();
        }
        isConnect = true;
        isErrorModalShow = false;
        if (channelVisibleList.length) {
            // 假设disconnect以后自动重连，需要重新建立所有订阅。
            for (let i = 0; i < channelVisibleList.length; i++) {
                let item = channelVisibleList[i];
                if (item.mqtt_channel) {
                    connectOption.invocationContext.subscribeAllTopic(item.mqtt_channel, i);
                }
            }
        }
        connectOption.invocationContext.subscribeTopic(topic_new_channel, 2)
        connectOption.invocationContext.checkDataLastReceivedTime(false);
    }

    connectFailure = (connectOption, errorCode, errorMessage) => {
        // 当连接断开再次尝试自动重连时，依然会有timeout的问题，不会触发该回调，而是继续尝试重连。
        // 也就是说在设置reconnect = true的情况下，只有首次主动调用connect有可能进入该fail回调。
        console.log('连接失败', errorCode, errorMessage);
        this._disConnect(true);
        // 自动重连的尝试间隔依次增加，最大两分钟。也就是说如果网络断开时间比较长，则自动重连需要在网络恢复后2分钟才能生效。
        // 所以长时间网络断开可能需要人工刷新浏览器来第一时间验证是否恢复网络。

        // If set to true, in the event that the connection is lost, the client will attempt to reconnect to the server.
        // It will initially wait 1 second before it attempts to reconnect, for every failed reconnect attempt,
        // the delay will double until it is at 2 minutes at which point the delay will stay at 2 minutes.
    }

    onConnectionLost = (responseObject) => {
        console.log(`onConnectionLost()called: errorCode = ${responseObject.errorCode},errorMessage =  ${responseObject.errorMessage},uri = ${this.client.uri}`);
        this._disConnect(responseObject.errorCode !== 0);
    }

    _disConnect = (showModal) => {
        isConnect = false;
        this.checkDataLastReceivedTime(true);
        if (!isErrorModalShow && showModal) {
            isErrorModalShow = true;
            Modal.error({
                content: '与远程遥测服务器断开连接，请检查电脑联网状态!',
                afterClose: () => {
                    console.log(`MQTT连接断开弹框关闭！！`)
                    isErrorModalShow = false;
                }
            })
        }
    };

    parseExtraMessage = (message) => {
        let destinationName = message.destinationName;
        let extraMessage = message.payloadString;

        if (extraMessage.indexOf('extra:') === 0) {
            // 忽略旧版协议数据
            return;
        }
        // if (destinationName.indexOf(DEBUG_channel_name) > 0){
        //     console.log('[extraMessage]',Moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'),',',destinationName,',', extraMessage);
        // }
        let topicPair = destinationName.split('-extra-res-');
        let channelName = topicPair[0];
        let stateName = topicPair[1];

        let dataPair = extraMessage.split('@');
        // let timestamp = parseInt(dataPair[0]);
        let stateValue = parseInt(dataPair[1]);
        // let now = new Date().getTime();

        /**
         状态信息有效的前提是MQTT连接状态，若在非连接状态，读取过时的电量等信息来显示也是没有意义的。
         所有状态消息的qos和retain都应该是1。
         手机将在MQTT connect建立连接后立刻发送上线通知，而在结束监测(主动断开连接之前)发送结束消息，或遗嘱消息时发送下线通知。
         手机端在点击结束监测时，需要先发结束消息，再在某个延迟后disconnect。但即使结束消息发送成功前disconnect导致未发送，keepAlive的延迟后，遗嘱消息也会发送下线通知。
         也就是说这种情况下，Web监听端无法第一时间通过接收到结束消息而得知应该关闭通道，而是通过遗嘱消息错误认为该通道临时下线，但Web端定时刷新会通过后台接口获知通道关闭，只是有延迟。

         extra对象属性：finish、offline、bleState、mobileBattery、bleBattery
         offline = 0 上线状态，手机端每次连接MQTT成功后发送。
         offline = 1 下线状态，因监测过程中业务逻辑不应该下线，所以下线信息只可能因为MQTT连接中断后通过遗嘱消息触发。
         finish = 0 结束状态，手机端结束监测时发送，发送后断开MQTT，Web端收到该消息后关闭对应通道。
         bleState = 0 蓝牙断开  bleState = 1 蓝牙连接
         mobileBattery = int 手机电量 bleBattery = int 蓝牙电量

         由于状态切换的消息可能在MQTT离线时产生，故应该允许客户端存储离线消息，即bleState、mobileBattery、bleBattery消息发送时不要检查MQTT连接性。
         每次心电仪蓝牙连接后，发送bleState = 1和bleBattery，连接过程中监听bleBattery发送。
         每次MQTT通道连接后，发送offline = 0和mobileBattery，连接过程中监听mobileBattery发送。

         Web显示端判断逻辑，当某个通道offline = 1时，所有状态信息无效，手机电量、设备电量都显示--，图标换成未知样式。附加一个手机未连接至网络的提示图标和文字。
         当offline = 0时，读取mobileBattery显示手机电量。
         当offline = 0，bleState = 1时，读取bleBattery显示设备电量。
         当offline = 0，bleState = 0时，附加一个心电仪未连接至手机的提示图标和文字。
         **/

            // 如果对象存在，则直接赋值
        let extraObj;
        if (!extra[channelName]) {
            extraObj = {};
        } else {
            extraObj = extra[channelName];
        }

        // console.log('extraObj',extraObj, stateName, channelName);

        extraObj[stateName] = stateValue;
        extra[channelName] = extraObj;

        // 由于遗嘱消息发送异常的原因，offline可能频繁收到，所以现在不在判断offline来清空数据
        if ((stateName === 'bleState' && stateValue === 0)
            || (stateName === 'finish' && stateValue === 0)) {
            // || (stateName === 'offline' && stateValue === 1)){
            data[channelName] = [];
            dataBuffer[channelName] = {};
            enableDraw[channelName] = false;
        }

        if (stateName === 'finish') {
            // 对于结束的通道，取消订阅。
            this.unsubscribeAllTopic(channelName);
            this.refreshChannle && this.refreshChannle(0, channelName);
        }
    }

    checkDataLastReceivedTime = (stop) => {
        if (stop) {
            if (this.checkDataLastReceivedTimeTimeInterval) {
                clearInterval(this.checkDataLastReceivedTimeTimeInterval);
                this.checkDataLastReceivedTimeTimeInterval = null;
            }
        } else {
            this.checkDataLastReceivedTimeTimeInterval = setInterval(() => {
                const curTime = new Date().getTime();
                const refreshAbleData = settingStorage.getDataLossReceivedRefreshPagerAble();
                for (let key in refreshAbleData){
                    if (Math.abs(curTime - refreshAbleData[key].date) > 24 * 60 * 60 * 1000){
                        delete refreshAbleData[key];
                    }
                }
                let needReloadPager = false;
                for (let i = 0; i < channelVisibleList.length; i++) {
                    let item = channelVisibleList[i];
                    if (item.mqtt_channel) {
                        if (refreshAbleData[item.mqtt_channel]) {
                            continue;
                        }
                        if (dataLastReceivedTime[item.mqtt_channel]
                            && Math.abs(curTime - dataLastReceivedTime[item.mqtt_channel]) > 20000) {
                            if (!Channel.badExtraMsg(extra[item.mqtt_channel])) {
                                refreshAbleData[item.mqtt_channel] = {
                                    date: curTime
                                };
                                needReloadPager = true;
                                break;
                            }
                        }
                    }
                }
                settingStorage.setDataLossReceivedRefreshPagerAble(refreshAbleData);
                if (needReloadPager){
                    this.reloadPager();
                }
            }, 10000);
        }
    }

    checkChannelDataBuffer = (destinationName, version, deviceModel) => {
        let channelDataBuffer = dataBuffer[destinationName];
        if (!channelDataBuffer) {
            channelDataBuffer = {
                version: version,
                deviceModel: deviceModel,
                data: []
            };
        } else {
            if (!channelDataBuffer.hasOwnProperty('version')) {
                channelDataBuffer.version = version;
            }
            if (!channelDataBuffer.hasOwnProperty('data')) {
                channelDataBuffer.data = [];
            }
            if (!channelDataBuffer.hasOwnProperty('deviceModel')) {
                channelDataBuffer.deviceModel = deviceModel;
            }
        }
        dataBuffer[destinationName] = channelDataBuffer;
        return channelDataBuffer;
    };

    decomposeData = (dataPair) => {
        let pktIndex = parseInt(dataPair[0]); // 数据包序号
        let valueStr = dataPair[1]; // 心电数据
        let edr = dataPair[2]; // 呼吸率
        let version = 0; // 以前版本没有传version，则默认为0
        let deviceModel = 'S1';
        if (dataPair.length >= 4) {
            version = parseInt(dataPair[3]);
        }
        if (dataPair.length >= 5) {
            deviceModel = dataPair[4];
        }
        return {pktIndex, edr, valueStr, version, deviceModel};
    };

    needCacheChannelPrintData = (destinationName) => {
        return this.printChannel && this.printChannel.mqtt_channel === destinationName;
    };

    needCalculateBaseline = (destinationName) => {
        return this.calculateBaselineChannel && this.calculateBaselineChannel.mqtt_channel === destinationName;
    };

    transformData = (destinationName, decomposeData, needCacheChannelPrintData) => {
        const {pktIndex, edr, valueStr, version, deviceModel} = decomposeData;
        const channelDataBuffer = this.checkChannelDataBuffer(destinationName, version, deviceModel);
        // 数据点切分
        let dataArray = valueStr.split(',');
        if (version === 0) {
            for (let i = 0, len = dataArray.length; i < len; i++) {
                this.cacheData(destinationName, DataParseCompat.v0(dataArray[i], edr), channelDataBuffer, needCacheChannelPrintData, deviceModel);
            }
        } else if (version === 1) {
            for (let i = 0, len = dataArray.length; i < len; i++) {
                this.cacheData(destinationName, DataParseCompat.v1(dataArray[i], edr), channelDataBuffer, needCacheChannelPrintData, deviceModel);
            }
        } else if (version === 2) {
            for (let i = 0, len = dataArray.length; i < len; i++) {
                this.cacheData(destinationName, DataParseCompat.v2(destinationName, dataArray[i], edr,version), channelDataBuffer, needCacheChannelPrintData, deviceModel);
            }
        } else if (version === 3 || version === 4) {
            if (LeadCompat.isMultiDevice(deviceModel)) {
                for (let i = 0, len = dataArray.length; i < len; i++) {
                    this.cacheData(destinationName, DataParseCompat.v3m(destinationName, dataArray[i], edr,version), channelDataBuffer, needCacheChannelPrintData, deviceModel);
                }
            } else {
                for (let i = 0, len = dataArray.length; i < len; i++) {
                    this.cacheData(destinationName, DataParseCompat.v3s(destinationName, dataArray[i], edr,version), channelDataBuffer, needCacheChannelPrintData, deviceModel);
                }
            }
        }
    };

    parseDataMessage = (message) => {
        // 新数据包结构
        let dataPair = message.payloadString.split('@');
        if (dataPair.length < 3) {
            console.warn(`parseDataMessage()error: topic[${destinationName}]`, dataPair);
            return;
        }
        let destinationName = message.destinationName;
        const decomposeData = this.decomposeData(dataPair);
        //是否需要缓存打印数据
        this.transformData(destinationName, decomposeData, this.needCacheChannelPrintData(destinationName));
        dataLastReceivedTime[destinationName] = new Date().getTime();

        // 处理包索引连续性
        // if(channelDataBuffer.length === 0){
        //     if(DEBUG) {
        //         if(DEBUG_allchannel || (!DEBUG_allchannel && destinationName.indexOf(DEBUG_channel_name)>0  )){
        //             console.log('channel:'+destinationName+'缓冲区被清空后，再次获得MessageArrived, 时间:'+ new Date().getTime() );
        //         }
        //     }
        //     prePktIndex[destinationName] = pktIndex;
        // }else{
        //     // 数据包index不连续，有以下可能原因:
        //     // 1、蓝牙丢包，APP标记index回1
        //     // 2、蓝牙重连，APP标记index回1
        //     // 3、网络丢包，QOS=1的mqtt不会补发，导致中间缺包
        //     // 4、网络补发，QOS>=1的情况下，重发duplicate数据包
        //     if(pktIndex - prePktIndex[destinationName] !== 1) {
        //         // 当前每个数据包长度是50个点，只要有一个数据包不连续，就会影响波形绘制，所以就不能连续绘图，
        //         // 也就不存在少量丢点可以用空白点补充上的可能性，所以对这种情况不再做处理。
        //        // console.log(destinationName + ' 数据包索引不连续, prePktIndex:'+ prePktIndex[destinationName] +', curPktIndex:' + pktIndex );
        //         if( pktIndex === 1) {   // 前两种可能
        //             //TODO: 提示蓝牙信号差
        //             //清空缓冲区，重新开始积攒
        //             let ele = document.getElementById(`ecg-notice-${destinationName}`);
        //             let ele1 = document.getElementById(`ecg-notice-back-${destinationName}`);
        //             ele1.style.display = 'block';
        //             if(ele){
        //                 ele.innerHTML = '心电仪重新连接';
        //             }
        //             setTimeout(()=>{
        //                 if(ele1){
        //                     ele1.style.display = 'none';
        //                 }
        //             },1000);
        //             channelDataBuffer = [];
        //             enableDraw[destinationName] = false;
        //             data[destinationName] = [];
        //         }else if(pktIndex === prePktIndex[destinationName]){  // 第四种可能
        //             // 忽略掉补发的数据包，不用做任何操作
        //         }else{  // 第三种可能
        //             //TODO: 提示网络丢包
        //             let ele = document.getElementById(`ecg-notice-${destinationName}`);
        //             let ele1 = document.getElementById(`ecg-notice-back-${destinationName}`);
        //             ele1.style.display = 'block';
        //             if(ele){
        //                 ele.innerHTML = '网络传输丢包';
        //             }
        //             setTimeout(()=>{
        //                 if(ele1){
        //                     ele1.style.display = 'none';
        //                 }
        //             },1000);
        //             //清空缓冲区，重新开始积攒
        //             channelDataBuffer = [];
        //             enableDraw[destinationName] = false;
        //             data[destinationName] = [];
        //         }
        //     }
        //     prePktIndex[destinationName] = pktIndex;
        // }

        // // 数据点切分
        // let dataArray = valueStr.split(',');

        // let curDate = new Date().getTime();
        // if (DEBUG && destinationName.indexOf(DEBUG_channel_name)>0 && preDate > 0){
        //     console.log(`pktIndex = ${pktIndex},duration = ${curDate-preDate},len = ${dataArray.length}`)
        // }
        // preDate = curDate;

        // if(DEBUG){
        //     // 评估数据包接收间隔，以及数据包长度
        //     if(DEBUG_allchannel || (!DEBUG_allchannel && destinationName.indexOf(DEBUG_channel_name)>0  )){
        //         if(DEBUG_preMsgTimestamp === 0) {
        //             DEBUG_preMsgTimestamp = new Date().getTime();
        //         }else{
        //             let now = new Date().getTime();
        //             //console.log(destinationName + ' msg interval :'+ (now - DEBUG_preMsgTimestamp)+ ', 缓冲区数据点数:'+arr.length, dataArray, message.payloadBytes.length);
        //             DEBUG_preMsgTimestamp = now;
        //         }
        //     }
        //     // 评估单一通道接2500点的总耗时
        //     if(destinationName.indexOf(DEBUG_channel_name)>0) {
        //         if(DEBUG_totalDataCount === 0) {
        //             console.time();
        //         }
        //         for (let i = 0; i < dataArray.length; i++) {
        //             DEBUG_totalDataCount++;
        //             if(DEBUG_totalDataCount%2500 === 0){  // 10秒打印一次
        //                 console.timeEnd();
        //                 console.time();
        //             }
        //         }
        //     }
        // }

        // 对于手机端EOF问题，以下代码好像并没有卵用，删掉！
        // if (isConnect && this.client != null){
        //     this.client.publish(destinationName+"-data-arrived-response",`${pktIndex}`,1,true);
        // }
    }

    canCachePrintData = (needCacheChannelPrintData) => {
        return needCacheChannelPrintData && channelPrintDataBuffer.length < this.timeLong * 250;
    }

    cacheData = (destinationName, data, channelDataBuffer, needCacheChannelPrintData, deviceModel) => {
        if (this.canCacheData) {
            if (data) {
                this._calculateBaseline(data, deviceModel)
                if (!isCalculateChannelPrintDataBaseline) {
                    channelDataBuffer.data.push(data);
                    this.cachePrintData(data, needCacheChannelPrintData, deviceModel);
                }
                // this.cacheTestData(destinationName.data.value);
            }
        }
    }

    _calculateBaseline = (data, deviceModel) => {
        if (this.calculateBaselinePointCount < 10 * 250) {
            this.channelDataBaseline += data.value[LeadCompat.getMajorIndexByDeviceModel(deviceModel)];
            this.calculateBaselinePointCount++
            if (this.calculateBaselinePointCount === 10 * 250) {
                this.channelDataBaseline /= this.calculateBaselinePointCount
                isCalculateChannelPrintDataBaseline = false;
                this.calculateBaselineCallback && this.calculateBaselineCallback(this.channelDataBaseline)
            }
        }
    }

    cachePrintData = (data, needCacheChannelPrintData, deviceModel) => {
        if (this.canCachePrintData(needCacheChannelPrintData)) {
            channelPrintDataBuffer.push(data.value[LeadCompat.getMajorIndexByDeviceModel(deviceModel)] - this.channelDataBaseline);
        }
    }

    cacheTestData = (destinationName, value) => {
        if (destinationName.includes('S1F03086RB')) {
            testData.push(value);
            if (testData.length === 15000 * 5) {
                Saver.saveToFile(testData, 'ecg_data.txt');
            }
        }
    }

    setDemoShow = (show) => {
        this.isDemoShow = show;
    }

    setMinDelay = (minDelay) => {
        this.minDelay = minDelay;
    }

    setPrintChannel = (channel, timeLong) => {
        this.printChannel = channel;
        this.calculateBaselineChannel = channel;
        this.timeLong = timeLong;
        if (channel) {
            this.channelDataBaseline = 0
            this.calculateBaselinePointCount = 0;
            isCalculateChannelPrintDataBaseline = true;
        }
        channelPrintDataBuffer.length = 0;
    }

    calculateBaseLine = (channel, callback) => {
        this.calculateBaselineChannel = channel;
        this.calculateBaselineCallback = callback;
        if (channel) {
            this.channelDataBaseline = 0
            this.calculateBaselinePointCount = 0;
            isCalculateChannelPrintDataBaseline = true;
        }
    }

    setCanCacheData = (can) => {
        if (can !== this.canCacheData) {
            data = {}
            dataBuffer = {}
        }
        this.canCacheData = can
    }

    //code&msg: 2&2
    sendMsgToAppClient = (channelId, code) => {
        console.log(`sendMsgToAppClient()called: channelId = ${channelId},code = ${code},isConnect = ${isConnect}`);
        try {
            if (this.client && isConnect) {
                this.client.publish(channelId + "-web-message", `${code}`, 2, false);
            }
        } catch (e) {
            console.log('sendMsgToAppClient()called error:', e);
        }
    }

    onMessageArrived = (message) => {

        if (this.isDemoShow) {
            return;
        }

        // console.log(message)//'14-153-S1E08033RB-89'
        // console.debug('mqtt log',this.client.getTraceLog());
        // console.log(message.payloadString)
        // console.log(message.retained)   // extra的状态信息时，retained可能是true，心电数据点都是false

        // 重复发送的相同消息，若相同消息已经接收，则必然对应index已经被绘制过。
        // duplicate = true的消息只有在qos>=1的时候才有可能出现
        // 如果出现，应该整包被忽略掉，不再加入待绘图缓冲区。
        if (message.duplicate) {
            console.log('疑似重复消息，message.duplicate', message);
        }
        // console.log('qos',message.qos);
        // console.log(message.destinationName, message.payloadString);

        let destinationName = message.destinationName;
        if (destinationName.includes(topic_new_channel)) {
            const payloadString = message.payloadString;
            console.log(`onMessageArrived()called: topic_new_channel.payloadString = ${payloadString}`)
            if (payloadString) {
                if (this.refreshChannle) {
                    let msg = payloadString.split('@');
                    if (msg.length === 3) {
                        this.refreshChannle(1, msg[0], msg[1], msg[2]);
                    } else if (msg.length === 6) {
                        this.refreshChannle(1, msg[0], msg[1], msg[2], msg[3], msg[4], msg[5]);
                    } else if (msg.length === 2 && msg[1] === 'close') {
                        this.refreshChannle(2, msg[0]);
                    } else {
                        this.refreshChannle(1, msg[0]);
                    }
                }
            } else {
                this.refreshChannle && this.refreshChannle(1);
            }
        } else if (destinationName.includes("extra-res")) { // 处理状态信息publish
            this.parseExtraMessage(message);
        } else {  // 处理心电数据信息publish
            if (canCacheData[destinationName]) {
                this.parseDataMessage(message);
            }
        }
    }
}

export {
    MQTTClient,
    channelPrintDataBuffer,
    isCalculateChannelPrintDataBaseline,
    dataBuffer,
    data,
    enableDraw,
    canCacheData,
    extra
} ;
