import {channelPrintDataBuffer} from "./MQTT";

/**
 * @name: ChartFactory
 * @author: Seeker
 * @date: 2021-12-02 09:21
 * @description：ChartFactory
 * @update: 2021-12-02 09:21
 */

const getPixelRatio = () => {
    const pr = window ? window.devicePixelRatio : 1;
    return Math.ceil(Math.max(pr, 1));
};

const DefaultParam = {
    rowSeconds: 6,
    rowCount:5,
    yGridCount:8,
    rowSpace:5,
    gridWidth:25,
    lineWidth:1,
    borderColor:'#062e1c',
    gridColor:'#062e1c',
    pointColor:'#D2D2D2',
    ecgLineColor:'#00FF00',
};

class ChartFactory {

    constructor(param) {
        this.printParam = param;
        this.pixelRatio = getPixelRatio();
        this.chartSize = this.caculateSize();
    }

    getDuration = () =>{
        return this.printParam.rowCount * this.printParam.rowSeconds
    }

    ctrlScreenY = (height,point,bottom)=>{
        const Y_MV = this.printParam.yGridCount / 4;
        const offset = (point + Y_MV)*(height / (Y_MV*2));
        return height - offset + bottom;
    };

    caculateSize = ()=>{
        const rowGridCount = 5 * this.printParam.rowSeconds;
        const width = rowGridCount * this.printParam.gridWidth + this.printParam.lineWidth;
        const singleHeight = this.printParam.yGridCount * this.printParam.gridWidth + this.printParam.lineWidth;
        const totalHeight = singleHeight * this.printParam.rowCount + (this.printParam.rowCount-1)*this.printParam.rowSpace;
        return {
            width,
            height: totalHeight,
            singleHeight
        }
    };

    setCanvas = (canvas)=>{
        const {width,height} = this.chartSize;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        canvas.width = this.pixelRatio * width;
        canvas.height = this.pixelRatio * height;
        this.context = canvas.getContext('2d');
        this.context.scale(this.pixelRatio,this.pixelRatio);
        this.context.lineCap = 'square';
        return this.context;
    };

    drawBorder = (context,xGridCount,yGridCount,gridWidth,left,top,right,bottom,lineWidth,color)=>{
        const halfLineWidth = lineWidth / 2;
        context.save();
        context.beginPath();
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.moveTo(left + halfLineWidth, top+halfLineWidth);
        context.lineTo(left + halfLineWidth, bottom-halfLineWidth);
        context.moveTo(left + halfLineWidth + gridWidth*xGridCount, top+halfLineWidth);
        context.lineTo(left + halfLineWidth+ gridWidth*xGridCount, bottom-halfLineWidth);
        context.moveTo(left, top+halfLineWidth);
        context.lineTo(right, top+halfLineWidth);
        context.moveTo(left, top+halfLineWidth+gridWidth*yGridCount);
        context.lineTo(right, top+halfLineWidth+gridWidth*yGridCount);
        context.stroke();
        context.restore();
    };

    drawGrid = (context,xGridCount,yGridCount,gridWidth,left,top,right,bottom,lineWidth,color)=>{
        const halfLineWidth = lineWidth / 2;
        context.save();
        context.beginPath();
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        for (let i = 1;i < xGridCount;i++){
            let x = left + gridWidth * i;
            if (i === 0 || i === xGridCount) {
                x += halfLineWidth;
            }
            context.moveTo(x, top+halfLineWidth);
            context.lineTo(x, bottom-halfLineWidth);
        }

        for (let i = 1;i<yGridCount;i++){
            let y = top + gridWidth * i;
            if (i === 0 || i === yGridCount) {
                y += halfLineWidth;
            }
            context.moveTo(left, y);
            context.lineTo(right, y);
        }
        context.stroke();
        context.restore();
    };



    drawBackGrid = (context,drawInnerPoint)=>{
        const {width,singleHeight} = this.chartSize;
        const {rowCount,rowSpace,rowSeconds,yGridCount,gridWidth,lineWidth,borderColor,gridColor} = this.printParam;
        for (let i = 0;i < rowCount;i++){
            const top = i*(rowSpace+singleHeight);
            const bottom = top + singleHeight;
            this.drawGrid(context,5 * rowSeconds,yGridCount,gridWidth,0,top,width,bottom,lineWidth/2,gridColor);
            this.drawBorder(context,5 * rowSeconds,yGridCount,gridWidth,0,top,width,bottom,lineWidth,borderColor);
            if (drawInnerPoint){
                this.drawTimeDuration(context,`${i === 0?0:(i*rowSeconds+1)}~${(i+1)*rowSeconds}秒`,5,bottom-10);
            }
        }
        if (drawInnerPoint){
            this.drawInnerPoint(context);
        }
    };

    drawTimeDuration = (context,text,x,y)=>{
        context.save();
        context.beginPath();
        context.font = '12px sans-serif';
        context.fillStyle = this.printParam.ecgLineColor;
        context.fillText(text,x,y);
        context.restore();
    };

    drawInnerPoint = (context)=>{
        const {singleHeight} = this.chartSize;
        const {rowSeconds,yGridCount,gridWidth,pointColor} = this.printParam;
        const xCellCount = 5 * rowSeconds * 5;
        const yCellCount = 5 * yGridCount;
        const cellWidth = gridWidth / 5;
        context.save();
        context.beginPath();
        context.fillStyle = pointColor;
        for (let i = 0;i < this.printParam.rowCount;i++){
            const top = i*(this.printParam.rowSpace+singleHeight);
            for (let j = 0;j < xCellCount;j++){
                if (j % 5 !== 0){
                    const x = cellWidth * j;
                    for (let k = 0;k < yCellCount;k++){
                        if (k % 5 !== 0){
                            const y = cellWidth * k + top;
                            context.moveTo(x,y);
                            context.arc(x-0.3,y-0.3,0.6,0,2*Math.PI);
                        }
                    }
                }
            }
        }
        context.fill();
        context.restore();
    };

    drawPrintChartLine = (context,printData)=>{
        const {width,singleHeight} = this.chartSize;
        const {rowCount,rowSpace,rowSeconds} = this.printParam;
        const rowPointCount = rowSeconds * 250;
        for (let i = 0; i< rowCount;i++){
            const top = i*(rowSpace+singleHeight);
            const bottom = top + singleHeight;
            this.drawLine(context,printData[i],rowPointCount,0,top,width,bottom,i)
        }
    };

    drawChartLine = (context,printData,svgData,chartBackGrid,timeOut,addPointCount)=>{
        const {rowCount,rowSeconds} = this.printParam;
        const {width,height,singleHeight} = this.chartSize;
        const rowPointCount = rowSeconds * 250;
        let cacheRowIndex = -1;
        for (let i = 0;i < rowCount;i++){
            if (printData[i].length < rowPointCount){
                cacheRowIndex = i;
                break;
            }
        }
        if (cacheRowIndex !== -1){
            context.clearRect(0,0,width,height);
            context.putImageData(chartBackGrid,0,0);
            const printRowData = printData[cacheRowIndex];
            const temp = channelPrintDataBuffer.length / 250;
            const adjust = (temp >= 1? Math.ceil(temp*10):Math.floor(temp*10))-10;
            const len = Math.min(addPointCount+adjust,channelPrintDataBuffer.length);
            for (let i = 0;i < len;i++){
                const value = channelPrintDataBuffer.shift();
                printRowData.push(value);
                svgData.push(value);
            }
            if (printRowData.length > rowPointCount){
                if (cacheRowIndex + 1 <= this.printParam.rowCount-1){
                    const nextPrintRowData = printData[cacheRowIndex+1];
                    nextPrintRowData.push(printRowData.splice(rowPointCount,printRowData.length));
                }
            }

            for (let i = 0; i< this.printParam.rowCount;i++){
                const top = i*(this.printParam.rowSpace+singleHeight);
                const bottom = top + singleHeight;
                this.drawLine(context,printData[i],rowPointCount,0,top,width,bottom,i)
            }
            return true;
        }
        return false;
    };

    drawLine = (context,data,rowPointCount,left,top,right,bottom,index)=>{
        context.save();
        context.beginPath();
        context.strokeStyle = this.printParam.ecgLineColor;
        context.lineWidth = 1;
        const width = right - left;
        const height = bottom - top;
        let preY = 0;
        let preX = 0;
        const len = data.length;
        context.moveTo(0,(top+bottom) >> 1);
        for (let i = 0;i < len;i++) {
            let point = data[i];
            let x = i / rowPointCount * width;
            let y = this.ctrlScreenY(height, point,top);
            if (y < top){
                if (preY < top){
                    context.moveTo(x,top);
                }else if (preY <= bottom){
                    context.lineTo(x,top);
                }else {
                    context.moveTo(preX, bottom);
                    context.lineTo(x,top);
                }
            }else if (y > bottom){
                if (preY > bottom) {
                    context.moveTo(x, bottom);
                } else if (y >= top) {
                    context.lineTo(x, bottom);
                } else {
                    context.moveTo(preX, top);
                    context.lineTo(x, bottom);
                }
            }else {
                context.lineTo(x,y);
            }
            preX = x;
            preY = y;
        }
        context.stroke();
        context.restore();
        if (len < rowPointCount && len > 0){
            context.save();
            context.beginPath();
            context.fillStyle = this.printParam.ecgLineColor;
            let x = (len-1) / rowPointCount * width;
            let y = this.ctrlScreenY(height, data[len-1],top);
            let radio = 4;
            context.arc(x,y,radio,0,2*Math.PI);
            context.fill(); //
            context.restore();
        }
    };

}

export {DefaultParam}
export default ChartFactory;

