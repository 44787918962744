import React, {useState} from "react";
import {Select, Switch, Button, Form} from 'antd';
import audioManagerImpl from "../../../audioManager";
import mobileAlarmEvent, {emergencyResidentCode} from "./MobileAlarmEvent";
import MobileConstantSetting from "./MobileConstantSetting";
import MobileFormItem from "./MobileFormItem";

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * @name: MobileAbnormalEventSetting
 * @author: Seeker
 * @date: 2022-03-10 14:48
 * @description：MobileAbnormalEventSetting
 * @update: 2022-03-10 14:48
 */

const MobileAbnormalEventSetting = (props) => {

    const sizeType = MobileConstantSetting.sizeType(props.clientWidth);
    // const labelFontSize = MobileConstantSetting.labelFontSize(props.clientWidth);
    const [abnForm] = Form.useForm();
    const [selectedEventItems, setSelectedEventItems] = useState({
        emergency: props.emergencyAlarmEvent || [],
        nonEmergency: props.nonEmergencyAlarmEvent || [],
    });
    const emergencyFilteredOptions = mobileAlarmEvent.filter(o => (selectedEventItems.emergency.findIndex(value => value === o.value) < 0));
    const nonEmergencyFilteredOptions = mobileAlarmEvent.filter(o => (selectedEventItems.nonEmergency.findIndex(value => value === o.value) < 0
        && emergencyResidentCode.findIndex(value => value === o.value) < 0));

    const setSelected = (nonEmergency, emergency) => {
        abnForm.setFieldsValue({nonEmergencyAlarmEvent: nonEmergency, emergencyAlarmEvent: emergency})
        setSelectedEventItems({nonEmergency: nonEmergency, emergency: emergency})
    };

    return (
        <>
            <Form
                form={abnForm}
                name={'abnEventSettingForm'}
                initialValues={{...props}}>

                <div style={{fontSize: 18, color: '#FF4D4F', marginBottom: 15}}>紧急异常事件:</div>

                <MobileFormItem
                    label={'异常提示音'}
                    item={<FormItem
                        name={'emergencySoundState'}
                        valuePropName={'checked'}>
                        <Switch size={sizeType} disabled checkedChildren="开" unCheckedChildren="关"/>
                    </FormItem>}/>

                <MobileFormItem
                    label={'文字提示时长'}
                    item={<FormItem
                        name={'emergencyTextShowTimeLong'}
                    >
                        <Select className={'setting-select-size'} size={sizeType} disabled>
                            <Option className={'setting-select-size'} key={'zero'} value={0}>一直显示</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'提示音音效'}
                    item={<FormItem
                        name={'emergencySound'}
                    >
                        <Select className={'setting-select-size'} size={sizeType} disabled={true}>
                            <Option className={'setting-select-size'} key={'zero'} value={0}>滴滴滴声</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'提示音间隔'}
                    item={<FormItem
                        name={'emergencySoundInterval'}
                    >
                        <Select className={'setting-select-size'} size={sizeType} disabled>
                            <Option className={'setting-select-size'} key={'zero'} value={0}>无间隔</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'异常提示事件'}
                    item={<FormItem
                        name={'emergencyAlarmEvent'}>
                        <Select
                            tagRender={MobileConstantSetting.tagRender}
                            size={sizeType}
                            showSearch={false}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            mode="multiple"
                            placeholder='请选择异常提示事件'
                            value={selectedEventItems.emergency}
                            onDeselect={(value) => {
                                const nonEmergency = selectedEventItems.nonEmergency;
                                const emergency = selectedEventItems.emergency.filter(o => o !== value);
                                setSelected(nonEmergency, emergency);
                            }}
                            onSelect={(value) => {
                                const nonEmergency = selectedEventItems.nonEmergency.filter(o => o !== value);
                                const emergency = [...selectedEventItems.emergency, value];
                                setSelected(nonEmergency, emergency);
                            }}
                        >
                            {emergencyFilteredOptions.map(item => (
                                <Select.Option key={item.key} value={item.value}>
                                    {item.title}
                                </Select.Option>
                            ))}
                        </Select>

                    </FormItem>}/>

                <div style={{fontSize: 18, color: '#FF8000', marginBottom: 15, marginTop: 50}}>非紧急异常事件:</div>


                <MobileFormItem
                    label={'异常提示音'}
                    item={<FormItem
                        name={'nonEmergencySoundState'}
                        valuePropName={'checked'}
                    >
                        <Switch size={sizeType} checkedChildren="开" unCheckedChildren="关"/>
                    </FormItem>}/>

                <MobileFormItem
                    label={'文字提示时长'}
                    item={<FormItem
                        name={'nonEmergencyTextShowTimeLong'}
                    >
                        <Select className={'setting-select-size'} size={sizeType}>
                            <Option className={'setting-select-size'} key={'15'} value={15}>15秒</Option>
                            <Option className={'setting-select-size'} key={'30'} value={30}>30秒</Option>
                            <Option className={'setting-select-size'} key={'60'} value={60}>1分钟</Option>
                            <Option className={'setting-select-size'} key={'120'} value={120}>2分钟</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'提示音音效'}
                    item={<FormItem
                        name={'nonEmergencySound'}
                    >
                        <Select className={'setting-select-size'} size={sizeType}
                                onBlur={() => {
                                    audioManagerImpl.testPlay(undefined, false);
                                }}
                                onSelect={(value, option) => {
                                    audioManagerImpl.testPlay(value, true);
                                }}>
                            <Option className={'setting-select-size'} key={'zero'} value={0}>滴滴滴声</Option>
                            <Option className={'setting-select-size'} key={'minute1'} value={1}>Indian
                                Summer</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'提示音间隔'}
                    item={<FormItem
                        name={'nonEmergencySoundInterval'}
                    >
                        <Select className={'setting-select-size'} size={sizeType}>
                            <Option className={'setting-select-size'} key={'zero'} value={0}>无间隔</Option>
                            <Option className={'setting-select-size'} key={'minute1'} value={60}>1分钟</Option>
                            <Option className={'setting-select-size'} key={'minute5'} value={300}>5分钟</Option>
                        </Select>
                    </FormItem>}/>

                <MobileFormItem
                    label={'异常提示事件'}
                    item={<FormItem
                        name={'nonEmergencyAlarmEvent'}>
                        <Select
                            tagRender={MobileConstantSetting.tagRender}
                            size={sizeType}
                            allowClear={true}
                            showSearch={false}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            mode="multiple"
                            placeholder='请选择异常提示事件'
                            value={selectedEventItems.nonEmergency}
                            onDeselect={(value) => {
                                const nonEmergency = selectedEventItems.nonEmergency.filter(o => o !== value);
                                const emergency = selectedEventItems.emergency;
                                setSelected(nonEmergency, emergency);
                            }}
                            onSelect={(value) => {
                                const nonEmergency = [...selectedEventItems.nonEmergency, value];
                                const emergency = selectedEventItems.emergency.filter(o => o !== value);
                                setSelected(nonEmergency, emergency);
                            }}
                        >
                            {nonEmergencyFilteredOptions.map(item => (
                                <Select.Option key={item.key} value={item.value}>
                                    {item.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>}/>


                <FormItem {...MobileConstantSetting.tailFormItemLayout} style={{marginTop: '15%'}}>
                    <Button style={{width: '20%', marginLeft: '15%'}} onClick={props.hideChildrenDrawer}>取消</Button>
                    <Button style={{width: '20%', marginLeft: '30%'}} type='primary' onClick={() => {
                        abnForm.submit();
                        props.hideChildrenDrawer();
                    }}>确定</Button>
                </FormItem>
            </Form>
        </>
    )
};


export default MobileAbnormalEventSetting
