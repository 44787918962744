/**
 * @name: AlarmEvent
 * @author: Seeker
 * @date: 2021-11-12 15:34
 * @description：AlarmEvent
 * @update: 2021-11-12 15:34
 */
import {AlarmEventCode} from "../../../compat/AbnormalCompat";

const residentEvent = [
    {
        title:'室速',
        key:`${AlarmEventCode.VTachycardia}`,
        value:AlarmEventCode.VTachycardia,
        disableCheckbox:true
    },
    {
        title:'室颤',
        key:`${AlarmEventCode.VFIB}`,
        value:AlarmEventCode.VFIB,
        disableCheckbox:true
    },
    {
        title:'长间歇',
        key:`${AlarmEventCode.R_RlongPulse}`,
        value:AlarmEventCode.R_RlongPulse,
        disableCheckbox:true
    },
];

const optionalEvent = [
    {
        title:'房速',
        key:`${AlarmEventCode.APCTachycardia}`,
        value:AlarmEventCode.APCTachycardia,
        disabled:false
    },
    {
        title:'房颤',
        key:`${AlarmEventCode.AFIB}`,
        value:AlarmEventCode.AFIB,
        disabled:false
    },
    {
        title:'房性联律',
        key:`${AlarmEventCode.APCgeminy}`,
        value:AlarmEventCode.APCgeminy,
        disabled:false
    },
    {
        title:'房性成对',
        key:`${AlarmEventCode.APCcouple}`,
        value:AlarmEventCode.APCcouple,
        disabled:false
    },
    {
        title:'室性联律',
        key:`${AlarmEventCode.Vgeminy}`,
        value:AlarmEventCode.Vgeminy,
        disabled:false
    },
    {
        title:'室性成对',
        key:`${AlarmEventCode.Vcouple}`,
        value:AlarmEventCode.Vcouple,
        disabled:false
    },
    {
        title:'加速性室性心律',
        key:`${AlarmEventCode.AQVTachycardia}`,
        value:AlarmEventCode.AQVTachycardia,
        disabled:false
    },
    {
        title:'心动过速',
        key:`${AlarmEventCode.Tachycardia}`,
        value:AlarmEventCode.Tachycardia,
        disableCheckbox:true
    },
    {
        title:'心动过缓',
        key:`${AlarmEventCode.Bradycardia}`,
        value:AlarmEventCode.Bradycardia,
        disableCheckbox:true
    },
];

const mobileAlarmEvent =  [
    ...optionalEvent,
    ...residentEvent
];

const residentCode = [
    AlarmEventCode.VTachycardia,
    AlarmEventCode.VFIB,
    AlarmEventCode.R_RlongPulse,
    AlarmEventCode.Tachycardia,
    AlarmEventCode.Bradycardia
];

// 紧急事件，必选
const emergencyResidentCode = [
    AlarmEventCode.VTachycardia,
    AlarmEventCode.VFIB,
    AlarmEventCode.R_RlongPulse,
];

// 非紧急事件，默认
const nonEmergencyResidentCode = [
    AlarmEventCode.Tachycardia,
    AlarmEventCode.Bradycardia,
];

const closeable = (value)=>{
    return value !== AlarmEventCode.VTachycardia
        && value !== AlarmEventCode.VFIB
        && value !== AlarmEventCode.R_RlongPulse
        // && value !== AlarmEventCode.Tachycardia
        // && value !== AlarmEventCode.Bradycardia
};

const getTitle = (value) =>{
    const event = mobileAlarmEvent.find(event => event.value === value);
    if (event){
        return event.title;
    }
    return value;
};

export {residentEvent,optionalEvent,residentCode,closeable,getTitle,emergencyResidentCode,nonEmergencyResidentCode}
export default mobileAlarmEvent;
