/**
 * @name: is-object
 * @author: Seeker
 * @date: 2021-05-26 08:48
 * @description：is-object
 * @update: 2021-05-26 08:48
 */
export default (value) => {
    /**
     * isObject({}) => true
     * isObject([1, 2, 3]) => flase
     * isObject(Function) => flase
     * isObject(null) => false
     */
    const type = typeof value;
    return value !== null && type === 'object';
};