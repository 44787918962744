/**
 * @name: modalFactory
 * @author: Seeker
 * @date: 2021-11-22 16:10
 * @description：modalFactory
 * @update: 2021-11-22 16:10
 */
import {Modal} from 'antd';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import React from "react";
import ChannelPrinter, {LONG_TEST_TIME_LONG} from "../../main/RealTimeMonitor/modules/v2/ChannelPrinter";
import {canCacheData, dataBuffer, enableDraw, extra,data} from "../../main/Common/MQTT";
import Saver from "../../main/util/Saver";
import ChannelSetting from "../../main/RealTimeMonitor/ChannelSetting";
import TimeLong from "../../main/Common/TimeLong";
import VenueSetting from "../../main/modal/VenueSetting";
import ViewMqttConnectState from "../../main/modal/ViewMqttConnectState";

const {confirm} = Modal;

const ModalFactory = {
    closeChannel: (channel, toDelete) => {
        const {user_name, dept_name, bed_number, mach_sn, mqtt_channel, rec_id,hospital_number,is_pacemaker} = channel;
        // if (user_name && dept_name && bed_number && mach_sn) {
        //
        // }
        confirm({
            icon: <ExclamationCircleOutlined/>,
            title: <Text type={'warning'}>确认关闭当前患者遥测通道？</Text>,
            content: <>
                <Text>姓名：{user_name}</Text><br/>
                <Text>科室：{dept_name}</Text><br/>
                {hospital_number?<><Text>住院号：{hospital_number}</Text><br/></>:null}
                <Text>床位号：{bed_number}</Text><br/>
                {is_pacemaker?<><Text>起搏器：植入</Text><br/></>:null}
                <Text>设备：{mach_sn}</Text><br/>
            </>,
            style:{border:'2px solid #484F5F',borderRadius:'5px',padding:0},
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            maskClosable: true,
            onOk() {
                toDelete(rec_id, mqtt_channel);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },

    printChannel: (printChannel,width,clientHeight,darkMode,hideFun,venueSetting) => (<Modal
        visible={printChannel.visible}
        style={{
            top:printChannel.timeLong === LONG_TEST_TIME_LONG ?(clientHeight-250)/2:(clientHeight-850)/2,
        }}
        bodyStyle={{
            padding:0,
        }}
        width={TimeLong.modalWidth(printChannel.timeLong)}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}>
        <ChannelPrinter
            channel={printChannel.channel}
            timeLong={printChannel.timeLong}
            hideFun={hideFun}
            width={600}
            height={850}
            darkMode={darkMode}
            venueSetting={venueSetting}
        />
    </Modal>),

    channelInfo:(sendMsgToAppClient,channelItem)=> {
        const {user_name,dept_name,mqtt_channel,company_name} = channelItem;
        if (!mqtt_channel){
            return;
        }
        const databuffer = dataBuffer[mqtt_channel]?dataBuffer[mqtt_channel]:{};
        const version = databuffer?databuffer.version:-1;
        const dataBufferDataLength = databuffer && databuffer.data?databuffer.data.length:-1;
        const dataLength = data[mqtt_channel]?data[mqtt_channel].length:-1;
        const enableDraw1 = enableDraw[mqtt_channel]?'true':'false';
        const canCacheData1 = canCacheData[mqtt_channel]?'true':'false';
        const extraInfo = extra[mqtt_channel];
        let extraInfos = '';
        if (extraInfo){
            for (let key in extraInfo){
                extraInfos += `extraInfo[${key}]: ${extraInfo[key]}\n`
            }
        }
        confirm({
            icon:<ExclamationCircleOutlined/>,
            title: <Text type={'warning'}>当前患者遥测通道绘制信息</Text>,
            content: <>
                <Text style={{cursor:'pointer'}} title={'通知APP客户端,点击一次开始记录，再次点击结束记录'} onClick={(e)=>{
                    if (sendMsgToAppClient){
                        sendMsgToAppClient(mqtt_channel,1);
                    }
                }}>患者姓名：{user_name}</Text><br/>
                <Text >医院信息：{company_name} - {dept_name}</Text><br/>
                <Text >通道号：{mqtt_channel}</Text><br/>
                <Text >数据格式版本：{version}</Text><br/>
                <Text style={{cursor:'pointer'}} title={'点击可保存当前缓存的数据'} onClick={(e)=>{
                    Saver.saveToFile(data[mqtt_channel],'renderData.txt');
                }}>绘图数据[dataLength]: {dataLength}</Text><br/>
                <Text>缓冲数据[dataBufferDataLength]: {dataBufferDataLength}</Text><br/>
                <Text>enableDraw1: {enableDraw1}</Text><br/>
                <Text>canCacheData1: {canCacheData1}</Text><br/>
                <Text style={{whiteSpace:'pre'}}>{extraInfos}</Text><br/>
            </>,
            okText: '确认',
            cancelText: '取消',
            maskClosable:true,
            onOk() {
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },

    channelSetting:(gainChannel,hideFun,globalHrRangerSetting,globalEmergencySoundInterval)=>(<Modal
        title={<Text>{gainChannel.channel?`${gainChannel.channel.user_name}的`:""}通道设置</Text>}
        visible={gainChannel.visible}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={null}>
        <ChannelSetting
            channel={gainChannel.channel}
            hideFun={hideFun}
            globalHrRangerSetting={globalHrRangerSetting}
            globalEmergencySoundInterval={globalEmergencySoundInterval}
        />
    </Modal>),

    venueModal:(hideFun,visible) => (
        <Modal
            title={<Text>会场展览设置</Text>}
            visible={visible}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
            footer={null}
        >
            <VenueSetting
                hideFun={hideFun}
            />
        </Modal>
    ),

    viewMqttConnectStateModal:(hideFun,visible) => (
        <Modal
            title={<Text>遥测连接性评估</Text>}
            visible={visible}
            closable={false}
            destroyOnClose={true}
            maskClosable={false}
            footer={null}
        >
            <ViewMqttConnectState
                hideFun={hideFun}
            />
        </Modal>
    ),

};

export default ModalFactory
