import {
    FETCH_USER_RECORD_LIST_STARTED,
    FETCH_USER_RECORD_LIST_SUCCESS,
    FETCH_USER_RECORD_LIST_FAILURE,
} from "./actionTypes";
import qs from 'qs';
import axios from '../../myAxios';

export const fetchUserRecordListStarted = () =>({
    type:FETCH_USER_RECORD_LIST_STARTED
});

export const fetchUserRecordListSuccess = (result,page,total,totalCount) =>({
    type:FETCH_USER_RECORD_LIST_SUCCESS,
    payload:{
        result,
        total,
        page,
        totalCount
    }
})

export const fetchUserRecordListFailure = (err)=>({
    type:FETCH_USER_RECORD_LIST_FAILURE,
    error:err
});

export const fetchUserRecordList = (deptId,page,totalCount,sort,recId) =>{
    return(dispatch)=>{
        dispatch(fetchUserRecordListStarted());

        let parmas = {
            page,
            rows:totalCount,
            ...sort,
        };
        if(deptId.length){
            parmas = {...parmas,'filter.dept_id':deptId.join(',')};
        }
        let requestPromise;
        if (recId){
            requestPromise = axios.post('/cust/rt/stopRtRec.action',qs.stringify({keyId: recId})).then(res => {
                if(res.data.actionErrors.length){
                    console.log(`fetchUserRecordList.stopRtRec()request error:`,res.data.actionErrors[0]);
                    throw new Error(`${res.data.actionErrors[0]}`)
                }else{
                    return axios.post('/cust/rt/rtUserRecordListJson.action',qs.stringify(parmas));
                }
            })
        }else {
            requestPromise = axios.post('/cust/rt/rtUserRecordListJson.action',qs.stringify(parmas));
        }
        return requestPromise.then((res)=>{
            if(res.data.actionErrors.length){
                console.log(`fetchUserRecordList.rtUserRecordListJson()request error:`,res.data.actionErrors[0])
                throw new Error(`${res.data.actionErrors[0]}`)
            }else{
                dispatch(fetchUserRecordListSuccess(res.data.rows,page,res.data.total,totalCount));
            }
        }).catch((err)=>{
            console.log(`fetchUserRecordList.fetchUserRecordList()request exception:`,err)
            dispatch(fetchUserRecordListFailure(err));
        });
    }
}
