import React, {Component} from 'react';
import '../size.css'
import EcgChart from "../../../Common/EcgChart";
import {dataBuffer, enableDraw, extra} from "../../../Common/MQTT";
import audioManagerImpl from "../../../audioManager";
import MobileViewAdapt3 from "./MobileViewAdapt3";
import ContentObj from "../ContentObj";
import AbnormalCompat from "../../../compat/AbnormalCompat";
import BPMParseCompat from "../../../compat/BPMParseCompat";
import ModalFactory from "../../../../useContextMenu/modal/modalFactory";
import MobileChannelTitle from "./MobileChannelTitle";
import MobileBPM from "./MobileBPM";
import settingStorage from "../../../util/setting-storage";

class MonitorItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version:0,
            avgBeat: null,
            abnormalEvent: null,
            isLeadOff:false,
            extraObj: null,
            error: false,
        };
        this.typeRecord = {
            'V': 0,
            'S': 0,
            'Pause': 0,
            'Slow': 0,
            'High': 0,
            'audio': 0,
            'flicker':0,
            'emergency':0,
            'nonEmergency':0
        };
    }

    componentDidMount() {
        this.props.bindRef(this);
        this.sendRRLongPause(this.props.channelSetting.rrLongPause);
    }

    componentWillUnmount() {
        this.props.unBindRef(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(this.state) !== JSON.stringify(nextState)
            || (this.props !== nextProps && JSON.stringify(this.props) !== JSON.stringify(nextProps)) ;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.sendRRLongPause(nextProps.channelSetting.rrLongPause);
    }

    sendRRLongPause = (rrLongPause) =>{
        const {sendMsgToAppClient,data} = this.props;
        const hasChannelSettingKey = settingStorage.hasChannelSettingKey(data.mqtt_channel);
        console.log(`sendRRLongPause()called: mqtt_channel = ${data.mqtt_channel}，hasChannelSettingKey = ${hasChannelSettingKey}`)
        if (hasChannelSettingKey){
            sendMsgToAppClient && sendMsgToAppClient(data.mqtt_channel,`2&${rrLongPause}`);
        }
    };

    getExtra = () => {
        this.setState(()=>{
            const extraObj = extra[this.props.data.mqtt_channel];
            const drawing = enableDraw[this.props.data.mqtt_channel];
            if (extraObj){
                const {finish,offline,bleState} = extraObj;
                if (finish === 0 || offline === 1 || offline === undefined || bleState === 0 || bleState === undefined){
                    if (drawing){
                        return {
                            extraObj,
                            error:true,
                        };
                    }
                    return {
                        extraObj,
                        error:true,
                        avgBeat: undefined
                    };
                }
            }
            return {extraObj,error: false};
        })
    }

    // onBpmCallback_v0 = (bpm)=>{
    //     if (!bpm){
    //         return null;
    //     }
    //     let avgBeat = null;
    //     if (bpm.hr !== -2){
    //         avgBeat = bpm;
    //         if (bpm.hr === -1 && this.state.avgBeat){
    //             avgBeat.hr = this.state.avgBeat.hr;
    //         }
    //     }
    //     let abnormalEvent = null;
    //     if (AbnormalCompat.is_v0(avgBeat,this.props.hrRanger)){
    //         abnormalEvent = {...avgBeat}
    //     }
    //     return abnormalEvent?{avgBeat,abnormalEvent}:{avgBeat}
    // };
    //
    // onBpmCallback_v1 = (bpm,mobileAlarmEvent)=>{
    //     let avgBeat = this.state.avgBeat;
    //     if (bpm){
    //         if (bpm.hr !== -2){
    //             avgBeat = bpm;
    //             if (bpm.hr === -1 && this.state.avgBeat){
    //                 avgBeat.hr = this.state.avgBeat.hr;
    //             }
    //         }else {
    //             avgBeat = null;
    //         }
    //     }
    //     const abnormalEvent = AbnormalCompat.is_v1(mobileAlarmEvent,avgBeat,this.props.hrRanger,this.props.mobileAlarmEvent);
    //     if (abnormalEvent){
    //         return {avgBeat,abnormalEvent};
    //     }
    //     if (this.state.abnormalEvent){
    //         if (new Date().getTime() - this.state.abnormalEvent.time > 60*1000){
    //             return {avgBeat,abnormalEvent: undefined};
    //         }
    //     }
    //     return {avgBeat};
    // };

    onBpmCallback = (version,bpm,occurAlarmEvent) => {

        if (AbnormalCompat.isLeadOff(occurAlarmEvent)){
            this.setState({
                isLeadOff:true,
                avgBeat: null,
                extraObj: extra[this.props.data.mqtt_channel],
            });
        }else {
            let result;
            const {emergencyAlarmEvent,nonEmergencyAlarmEvent,channelSetting,nonEmergencyTextShowTimeLong} = this.props;
            const {abnormalEvent: stateAbnormalEvent,avgBeat:stateAvgBeat} = this.state;
            const isPreAbnEventEmergency = AbnormalCompat.isEmergencyEvent(stateAbnormalEvent,emergencyAlarmEvent);
            const selectedAlarmEvent = [...emergencyAlarmEvent,...nonEmergencyAlarmEvent];
            if (version === 0){
                result = BPMParseCompat.v0(bpm,stateAvgBeat,channelSetting.hrRanger);
            }else if (version === 1){
                result = BPMParseCompat.v1(bpm,occurAlarmEvent,stateAvgBeat,stateAbnormalEvent,channelSetting.hrRanger,selectedAlarmEvent,nonEmergencyTextShowTimeLong);
            }else if (version === 2){
                result = BPMParseCompat.v2(bpm,occurAlarmEvent,stateAvgBeat,stateAbnormalEvent,channelSetting.hrRanger,selectedAlarmEvent,nonEmergencyTextShowTimeLong);
            }
            if (result){
                if (result.hasOwnProperty('abnormalEvent') && result.abnormalEvent){
                    result.abnormalEvent.showTime = new Date().getTime();
                    const isNowAbnEventEmergency = AbnormalCompat.isEmergencyEvent(result.abnormalEvent,emergencyAlarmEvent);
                    const isNowAbnEventNonEmergency = AbnormalCompat.isEmergencyEvent(result.abnormalEvent,nonEmergencyAlarmEvent);
                    if (isNowAbnEventEmergency){
                        this.setState(()=>{
                            return {
                                ...result,
                                extraObj: extra[this.props.data.mqtt_channel],
                                version,
                                isLeadOff:false
                            };
                        },()=>{
                            this.playWarnAudio(0);
                        })
                    }else if (isPreAbnEventEmergency){
                        result.abnormalEvent = stateAbnormalEvent;
                        this.setState(()=>{
                            return {
                                ...result,
                                extraObj: extra[this.props.data.mqtt_channel],
                                version,
                                isLeadOff:false
                            };
                        })
                    }else if (isNowAbnEventNonEmergency){
                        this.setState(()=>{
                            return {
                                ...result,
                                extraObj: extra[this.props.data.mqtt_channel],
                                version,
                                isLeadOff:false
                            };
                        },()=>{
                            this.playWarnAudio(1);
                        })
                    }else {
                        this.setState(()=>{
                            return {
                                ...result,
                                extraObj: extra[this.props.data.mqtt_channel],
                                version,
                                isLeadOff:false
                            };
                        })
                    }
                }else {
                    // 紧急事件不可主动消除，只能人工点击消除
                    if (isPreAbnEventEmergency){
                        result.abnormalEvent = stateAbnormalEvent;
                    }
                    this.setState(()=>{
                        return {
                            ...result,
                            extraObj: extra[this.props.data.mqtt_channel],
                            version,
                            isLeadOff:false
                        };
                    })
                }
            }
        }
    }
    //
    // onBpmCallback_demo = (pointCount)=>{
    //     let avgBeat = {};
    //     const temp = demoDataBuffer[this.props.data.mqtt_channel];
    //     if (temp && temp.length) {
    //         for (let i = 0; i < pointCount; i++) {
    //             const ti = temp[i];
    //             if (ti) {
    //                 if (ti.type) {
    //                     avgBeat = {
    //                         type: ti.typeDes
    //                     };
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //     this.setState(()=>{
    //         const extraObj = {
    //             offline: 0,
    //             bleState: 1,
    //             mobileBattery: 100,
    //             bleBattery: 100
    //         };
    //         const beat = {hr: 70, edr: 25, ...avgBeat};
    //         let abnormalEvent = null;
    //         if (AbnormalCompat.is_v0(beat,this.props.hrRanger)){
    //             abnormalEvent = {...beat,date: getDate(1),time: new Date().getTime()}
    //         }
    //         const data = {
    //             extraObj,
    //             avgBeat: beat,
    //         };
    //         if (abnormalEvent){
    //             return {
    //                 ...data,
    //                 abnormalEvent
    //             };
    //         }
    //         return data;
    //     },()=>{
    //         this.playWarnAudio();
    //     });
    // };

    bindRef = (ref) => {
        this.child = ref
    };

    unBindRef = (ref) => {
        this.child = null
    };

    refresh = (pointCount) => {
        if (this.props.demoShow) {
            // this.onBpmCallback_demo(pointCount);
            this.setState({...BPMParseCompat.demo(pointCount)},()=>{
                this.playWarnAudio();
            })
        } else {
            this.getExtra();
        }
        if (this.child) {
            this.child.refresh(pointCount);
        }
    };

    needPlayWarn = (code,soundInterval,key) => {
        // if (code === 0){
        //     return true;
        // }
        const duration = soundInterval * 1000;
        const now = new Date().getTime();
        if (now - this.typeRecord[key] > duration){
            this.typeRecord[key] = now;
            return true;
        }
        return false;
    };

    playWarnAudio = (code) => {
        if (code === 0){
            if (this.needPlayWarn(0,this.props.channelSetting.soundInterval,'emergency')){
                audioManagerImpl.play(0);
            }
        }else {
            if (this.needPlayWarn(1,this.props.nonEmergencySoundInterval,'nonEmergency')) {
                audioManagerImpl.play(1);
            }
        }
    };

    onNameClick = ()=>{
        const {sendMsgToAppClient,data} = this.props;
        ModalFactory.channelInfo(sendMsgToAppClient,data);
    };

    clearEmergencyAlarmEvent = () =>{
        this.setState({
            abnormalEvent: undefined
        })
    };

    render() {
        let {clientWidth,clientHeight,grid,nameAndBedLarger,darkModule,bpmLarger,minDelay,venueSetting,
            edrState,nameComplete,channelSetting,emergencyAlarmEvent,nonEmergencyAlarmEvent,bpmTextFontSize,nameTextFontSize,bedTextFontSize,departmentShow,bedShowLeft,batteryShowRule,bedNumLength} = this.props;
        const {version,abnormalEvent,extraObj,avgBeat} = this.state;
        const item = this.props.data;
        /*
        * div的高度计算:(body的高度 - 5个item的间距(25) - footer的高度(22) - header的高度(44))/5行
        * */
        let cardHeight = Math.floor((clientHeight - 25 - 25 - 44) / grid[0]);
        if (this.props.mobileScreen) {
            cardHeight = 200;
        }
        let cardWidth = Math.floor((clientWidth - 5 *(grid[1]-1)) / grid[1]);
        const bpmWidth = MobileViewAdapt3.getBpmContainerWidth(clientWidth,bpmLarger,edrState,bpmTextFontSize,bedShowLeft);
        const bedWidth = MobileViewAdapt3.getBedContainerWidth(bedTextFontSize,bedNumLength);
        let leftWidth = bedShowLeft?bpmWidth+bedWidth:bpmWidth;
        let cardTitleHeight = MobileViewAdapt3.getChannelCardHeadHeight(clientWidth,nameAndBedLarger);
        // let domTitle = document.getElementById('card_title_container');
        // if (domTitle){
        //     cardTitleHeight = domTitle.clientHeight;
        // }
        // cardTitleHeight = cardTitleHeight * 2;
        let chartHeight = cardHeight - cardTitleHeight;
        // const leadOff = AbnormalCompat.isLeadOff(abnormalEvent);
        let abnInfo = AbnormalCompat.getAbnTypeDes(version,abnormalEvent,channelSetting.hrRanger,[...emergencyAlarmEvent,...nonEmergencyAlarmEvent]);
        let abnInfoBackColor = AbnormalCompat.abnInfoBackColor(abnormalEvent,emergencyAlarmEvent);
        // 异常信息文字闪烁
        let flicker = AbnormalCompat.needFlicker_v0(abnormalEvent,emergencyAlarmEvent,minDelay);
        // 偶发异常：手机端其实连接正常，有数据传输，但是由于遗嘱消息发送的问题，导致接收到了下线的消息，现在加上对心电数据的判断，若是心电数据存在，则强制判断为上线状态
        let needForceOnline = false;
        const channelData = dataBuffer[item.mqtt_channel];
        if (channelData && channelData.data && channelData.data.length > 0){
            needForceOnline = true;
        }
        const contentObj = ContentObj.update(item,nameAndBedLarger,darkModule,extraObj,needForceOnline,nameComplete,venueSetting);
        const style = MobileViewAdapt3.getChannelCardStyle(darkModule,cardHeight,venueSetting);
        // const bpm = MobileViewAdapt3.getChannelBodyBpm(darkModule,bpmLarger,edrState,avgBeat,chartHeight-5,bpmTextFontSize,contentObj,bedTextFontSize,bpmWidth,bedWidth,bedShowLeft,channelSetting.hrRanger);
        return (
            <div style={style}>
                <MobileChannelTitle
                    clientWidth={clientWidth}
                    nameAndBedLarger={nameAndBedLarger}
                    grid={grid}
                    contentObj={contentObj}
                    darkModule={darkModule}
                    venueSetting={venueSetting}
                    item={item}
                    abnInfo={abnInfo}
                    abnInfoBackColor={abnInfoBackColor}
                    flicker={flicker}
                    onNameClick={this.onNameClick}
                    nameTextFontSize={nameTextFontSize}
                    departmentShow={departmentShow}
                    bedShowLeft={bedShowLeft}
                    bedTextFontSize={bedTextFontSize}
                    batteryShowRule={batteryShowRule}
                    clearEmergencyAlarmEvent={AbnormalCompat.isEmergencyEvent(abnormalEvent,emergencyAlarmEvent)?this.clearEmergencyAlarmEvent:undefined}
                    avgBeat={avgBeat}
                    hrRanger={channelSetting.hrRanger}
                />
                <div style={{height: chartHeight,display:'flex',flexDirection:'row'}}>
                    {item.mqtt_channel ?
                        <EcgChart
                            id={item.key}
                            onBpmCallback={this.onBpmCallback}
                            bindRef={this.bindRef}
                            unBindRef={this.unBindRef}
                            width={cardWidth}
                            height={chartHeight}
                            leftWidth={0}
                            error={this.state.error}
                            mqttChannel={item.mqtt_channel}
                            isPacemaker={item.is_pacemaker}
                            {...this.props}
                            leadOff={this.state.isLeadOff}/> :
                        <div className={'channel-data-empty-size'}
                             style={{textAlign:'center',margin:'auto',color:'#FFFFFF'}}>暂无数据</div>}
                </div>
            </div>
        )
    }

}

function withTrigger(Component){
    return function WrapperMonitorItem(props){
        const {useContextTrigger,data} = props;
        const [bindTrigger] = useContextTrigger({
            collect: () => data
        });
        if(data && data.mqtt_channel){
            return <div {...bindTrigger}>
                <Component {...props}/>
            </div>
        }
        return <Component {...props}/>
    }
}

export default withTrigger(MonitorItem);
